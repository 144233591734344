<h2
  mat-dialog-title
  class="disclaimer__title"
>{{ 'disclaimer.title' | translate }}</h2>

<mat-dialog-content class="disclaimer__content">
  <p>{{ 'disclaimer.text' | translate }}</p>
  <p>{{ 'disclaimer.hint' | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    color="primary"
    cdkFocusInitial
    [mat-dialog-close]="true"
  >{{ 'disclaimer.confirm' | translate }}</button>
</mat-dialog-actions>
