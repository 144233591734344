import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { createFolder } from '@core/store/actions/file/file.action';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store,
  ) {}

  public createFolder(name: string): void {
    this.store.dispatch(createFolder({ name }));
  }

  public openSmallDialog<T>(component: T, afterClosed: (...args) => void, data: { [key: string]: any } = {}, config: MatDialogConfig = {}): void {
    this.openDialog(component, afterClosed, data, '350px', config);
  }

  public openMediumDialog<T>(component: T, afterClosed: (...args) => void, data: { [key: string]: any } = {}, config: MatDialogConfig = {}): void {
    this.openDialog(component, afterClosed, data, '500px', config);
  }

  private openDialog<T>(component: T, afterClosed: (...args) => void, data: { [key: string]: any } = {}, width: string, config: MatDialogConfig = {}): void {
    const dialogRef: MatDialogRef<T> = this.dialog.open(component as ComponentType<T>, {
      width,
      maxWidth: '100vw',
      maxHeight: '90vh',
      ...config,
      data: { ...data },
    });

    dialogRef.afterClosed().subscribe(afterClosed);
  }
}
