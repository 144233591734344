import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppIdentity } from '@evc/platform';
import { BtnActionEnum, Menu, MenuItemType } from '@evc/web-components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { resetBreadcrumbState } from '@core/store/actions/breadcrumb/breadcrumb.action';
import {
  clearSearch,
  loadData,
  setCurrentFolder,
  setSearch,
  setSelectedItems,
} from '@core/store/actions/file/file.action';
import { ConfigService } from '../config/config.service';
import { I18nService } from '../i18n/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationConfigService {
  public appIdentification: AppIdentity = {
    appTitle$: this.i18nService.translateService.stream('pages.drive'),
    appLogo: '/assets/apps/drive.svg#drive',
    appVersion$: this.i18nService.translateService.stream(
      'appGeneralInfo.version',
      { version: this.configService.version },
    ),
    copyRightText$: this.i18nService.translateService.stream('appGeneralInfo.copyright'),
  };

  public appSettingMenu: MenuItemType[] = [
    {
      text: 'change language',
      key: 'change-language',
      action: {
        type: BtnActionEnum.CALLBACK,
        callbackOrUrl: () => this.i18nService.changeLanguage('fr'),
      },
    },
  ];

  public leftBarConfig: Menu[] = [
    {
      items:[
        {
          text: 'Drive',
          key: 'pages.drive',
          icon: 'folders',
          selected: false,
          action: {
            type: BtnActionEnum.CALLBACK,
            callbackOrUrl: () => this.navigateToPage('/drive'),
          },
        },
        {
          text: 'Device data',
          key: 'pages.devicesData',
          selected: false,
          isDisabled: true,
          action: {
            type: BtnActionEnum.CALLBACK,
            callbackOrUrl: () => this.navigateToPage('/devices-data'),
          },
        },
        {
          text: 'Archives',
          key: 'pages.archives',
          icon: 'box-archive',
          selected: false,
          isDisabled: true,
          action: {
            type: BtnActionEnum.CALLBACK,
            callbackOrUrl: () => this.navigateToPage('/archives'),
          },
        },
        {
          text: 'Trash',
          key: 'pages.trash',
          icon: 'trash-can-list',
          selected: false,
          action: {
            type: BtnActionEnum.CALLBACK,
            callbackOrUrl: () => this.navigateToPage('/trash'),
          },
        },
      ],
    },
  ];

  constructor(
    private readonly store: Store,
    private readonly i18nService: I18nService,
    private readonly router: Router,
    private readonly configService: ConfigService,
  ) {}

  public getAppTitle(): Observable<string> {
    return this.i18nService.translateService.stream('pages.drive');
  }

  public getAppVersion(): Observable<string> {
    return this.i18nService.translateService.stream('appGeneralInfo.version');
  }

  public getCopyRightText(): Observable<string> {
    return this.i18nService.translateService.stream('appGeneralInfo.copyright');
  }

  public appSearch: (event: any) => void = event => this.search(event);

  public updateSelectedTab(route: string): void {
    this.leftBarConfig.forEach(section => {
      section.items.forEach(item => {
        const baseText: string = item.key.split('.')[1].toLowerCase();
        item.selected = route.includes(baseText);
      });
    });
  }

  public search(search: string): void {
    this.store.dispatch(setSearch({ search }));
    this.store.dispatch(loadData({ search }));
  }

  public navigateToPage(page: string): void {
    this.store.dispatch(clearSearch());
    this.router.navigate([page]).then(() => {
      this.store.dispatch(setCurrentFolder({ folder: null }));
      this.store.dispatch(setSelectedItems({ items: [] }));
      this.store.dispatch(resetBreadcrumbState());
    });
  }
}
