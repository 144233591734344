import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppIdentity } from '@evc/platform';
import { Menu, MenuItemType, ModalService } from '@evc/web-components';
import { select, Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, tap } from 'rxjs';

import { environment } from '@env';
import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { logout } from '@core/store/actions/auth/auth.action';
import { loadPersistantCellar, setData } from '@core/store/actions/cellar/cellar.action';
import { deleteAllTrash } from '@core/store/actions/file/file.action';
import { uploadFiles } from '@core/store/actions/upload/upload.action';
import {
  getApps,
  getIsAdmin,
  getProfile,
  getProfileEmail,
  getProfileFullName,
  getProfileInitials,
  getProfileLoading,
  getRouterState,
  getTenant,
  isListEmpty,
  triggerWhenNotExists,
} from '@core/store/selectors';
import { isUploading } from '@core/store/selectors';
import { ApplicationInsightsService, DialogService, I18nService } from '@shared/services';
import { NavigationConfigService } from '@shared/services/navigation-config/navigation-config.service';
import { DisclaimerComponent } from './layout';

@Component({
  selector: 'ndt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public uploading$: Observable<boolean> = this.store.pipe(select(isUploading));
  public profile$: Observable<Profile> = this.store.pipe(select(getProfile));
  public applications$: Observable<Application[]> = this.store.pipe(select(getApps));
  public tenant$: Observable<any> = this.store.pipe(select(getTenant));
  public name$: Observable<string> = this.store.pipe(select(getProfileFullName));
  public initials$: Observable<string> = this.store.pipe(select(getProfileInitials));
  public email$: Observable<string> = this.store.pipe(select(getProfileEmail));
  public loading$: Observable<boolean> = this.store.pipe(select(getProfileLoading));
  public emptyList$: Observable<boolean> = this.store.pipe(select(isListEmpty));
  public isUserAdmin$: Observable<boolean> = this.store.pipe(select(getIsAdmin));

  public emptyProfile$: Observable<boolean> = this.store.pipe(
    select(getProfile),
    map(p => !p),
  );

  public routerLoading$: Observable<boolean> = this.store.pipe(
    select(getRouterState),
    map(routerState => !routerState),
  );

  public isSpinnerVisible$: Observable<boolean> = combineLatest([this.routerLoading$, this.uploading$]).pipe(map(([routerLoading, uploading]) => routerLoading || uploading));

  public appSettingMenu: MenuItemType[];
  public leftBarConfig: Menu[];
  public appHelpMenu: MenuItemType[];
  public appIdentification: AppIdentity;
  public isLeftBarOpen: boolean = true;
  public isAddMenuOpen: boolean = false;
  public appSearch: (event: any) => void;

  public stagingDisclaimer$: any = this.store.pipe(
    filter(() => !!environment.staging),
    select(triggerWhenNotExists('stagingDisclaimer')),
    filter(value => !!value),
    tap(() => this.openStagingDisclaimer()),
  );

  constructor(
    private readonly store: Store,
    private readonly i18nService: I18nService,
    private readonly dialogService: DialogService,
    private readonly applicationInsightsService: ApplicationInsightsService,
    private readonly navigationService: NavigationConfigService,
    @Inject(ModalService) private readonly modalService: ModalService,
    private readonly router: Router,
  ) {
    this.applicationInsightsService.initAppInsights();
  }

  public ngOnInit(): void {
    this.i18nService.init();
    this.store.dispatch(loadPersistantCellar());
    this.initNavigation();
  }

  public handleLeftBarToggle(isLeftBarOpen: boolean): void {
    this.isLeftBarOpen = isLeftBarOpen;
  }

  public uploadFiles({ files, list }: { files: File[]; list: FileList }): void {
    this.store.dispatch(uploadFiles({ files, list }));
    this.hideAddMenu();
  }

  public isTrashMenu(): boolean {
    return this.router.url.includes('trash');
  }

  public ctaButtonClicked(): void {
    if (this.isTrashMenu()) {
      this.modalService.showCurrentModal('trash');
    } else {
      this.isAddMenuOpen = true;
    }
  }

  public hideAddMenu(): void {
    this.isAddMenuOpen = false;
  }

  public deleteAllTrash(): void {
    this.hideModal();
    this.store.dispatch(deleteAllTrash());
  }

  public logout(): void {
    this.store.dispatch(logout());
  }

  public getShownModal(modalName: 'about' | 'trash'): boolean {
    return this.modalService.currentShownModal === modalName;
  }

  public hideModal(): void {
    this.modalService.closeCurrentModal();
  }

  private initNavigation(): void {
    this.appSettingMenu = this.navigationService.appSettingMenu;
    this.leftBarConfig = this.navigationService.leftBarConfig;
    this.appIdentification = this.navigationService.appIdentification;
    this.appSearch = this.navigationService.appSearch;
  }

  private openStagingDisclaimer(): void {
    this.dialogService.openMediumDialog(DisclaimerComponent, (read: boolean) =>
      this.store.dispatch(
        setData({
          data: { stagingDisclaimer: { persistant: !!read, validity: 'day' } },
          persistant: !!read,
        }),
      ),
    );
  }
}
