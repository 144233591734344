<div class="file-list">
  <div class="file-list__progress">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="file-list__table">
    <div *ngIf="isListEmpty; else table" class="file-list__empty-list">
      <ng-content select="[empty-list]"></ng-content>
    </div>
  </div>
</div>

<ng-template #table>
  <mat-table
    matSort
    [dataSource]="dataSource"
    (matSortChange)="onSortChanged($event)"
    (scroll)="onScroll($event)"
  >
    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          disableRipple
          [checked]="isAllItemsSelected()"
          [indeterminate]="isSomeItemsSelected()"
          (change)="onSelectAll($event)"
          color="primary"
        ></mat-checkbox>
      </mat-header-cell>

      <mat-cell *matCellDef="let element">
        <mat-checkbox
          disableRipple
          [class.visible]="isSomeItemsSelected()"
          [checked]="isItemSelected(element)"
          (change)="onRowSelect(element, $event)"
          (click)="$event.stopPropagation()"
          (dblclick)="$event.stopPropagation()"
          color="primary"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [sortActionDescription]="'list.headers.name.sort' | translate">
        {{ 'list.headers.name.label' | translate }}
      </mat-header-cell>

      <mat-cell *matCellDef="let element">
        <div
          class="file-list__name-container"
          evcTooltipPlacement="bottom-start"
          evcTooltipDelay="300"
          [evcTooltipHover]="element.name"
          [ngClass]="{ 'file-list--disabled': element.isLocked }"
        >
          <span class="icon-container">
            <mat-progress-spinner
              *ngIf="hasProgress(element)"
              mode="determinate"
              diameter="28"
              [color]="element.downloadError ? 'warn' : 'accent'"
              [value]="element.progress">
            </mat-progress-spinner>

            <mat-icon
              *ngIf="hasProgress(element); else icon"
              class="material-icons-outlined"
              [color]="element.downloadError ? 'warn' : undefined">
              file_download
            </mat-icon>

            <ng-template #icon>
              <evc-svg-icon
                *ngIf="isFolder(element)"
                class="file-list__file-icon file-list__file-icon--file"
                [ngClass]="{ 'file-list--disabled': element.isLocked }"
                icon="folder-solid"
                size="md"
               />
              <evc-svg-icon
                *ngIf="isFile(element)"
                class="file-list__file-icon file-list__file-icon--{{getFileIcon(element)}}"
                [ngClass]="{ 'file-list--disabled': element.isLocked }"
                [icon]="getFileIcon(element)"
                size="md"
               />
            </ng-template>
          </span>

          <span class="ellipsis">{{ element.name }}</span>

          <evc-status-indicator *ngIf="element.isLocked" [text]="'list.menu.inUse' | translate" [status]="'warn'"></evc-status-indicator>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Size Column -->
    <ng-container matColumnDef="size">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [sortActionDescription]="'list.headers.size.sort' | translate">
        {{ 'list.headers.size.label' | translate }}
      </mat-header-cell>

      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ 'file-list--disabled': element.isLocked }">
        {{
          element.latestVersion?.sizeInBytes
            ? (element.latestVersion.sizeInBytes | filesize)
            : '-'
        }}
      </mat-cell>
    </ng-container>

    <!-- Updated Column -->
    <ng-container matColumnDef="updatedAt">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [sortActionDescription]="'list.headers.updatedAt.sort' | translate">
        {{ 'list.headers.updatedAt.label' | translate }}
      </mat-header-cell>

      <mat-cell
        *matCellDef="let element"
        [ngClass]="{ 'file-list--disabled': element.isLocked }">
        {{ element.updatedAt | dateFormat }}
      </mat-cell>
    </ng-container>

    <!-- Parent Column -->
    <ng-container matColumnDef="parent">
      <mat-header-cell *matHeaderCellDef>
        {{ 'list.headers.parent.label' | translate }}
      </mat-header-cell>

      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="goToParent(element)">
          <mat-icon>drive_file_move</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <!-- Parent Name Column -->
    <ng-container matColumnDef="parentName">
      <mat-header-cell *matHeaderCellDef>
        {{ 'list.headers.parentName.label' | translate }}
      </mat-header-cell>

      <mat-cell *matCellDef="let element">
        <span class="ellipsis">
          {{ element.parentNameUponDeletion || '-' }}
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        'dnd-file': isFile(row),
        'dnd-folder': isFolder(row),
        selected: isItemSelected(row)
      }"
      (dblclick)="onRowDoubleClick(row)"
      (click)="onRowClick(row, $event)"
      (ctrl-click)="onRowCtrlClick(row)"
      (contextmenu)="onRowRightClick(row, $event)"></mat-row>
  </mat-table>
</ng-template>

<button class="file-list__trigger-button" [matMenuTriggerFor]="contextualMenu"></button>

<mat-menu #contextualMenu="matMenu" [hasBackdrop]="false">
  <ndt-contextual-menu
    #contextualMenuInstance
    [items]="selected"
    [isLockable]="areLockable(selected)"
    [isUnlockable]="areUnlockable(selected)"
    [applications]="applications"
    [areDeletedItems]="areDeletedItems"
    customClass="contextual-menu-file-list"
    (itemRenamed)="onItemRenamed($event)"
    (itemsDeleted)="onItemsDeleted($event)"
    (itemsCopied)="onItemsCopied($event)"
    (fileOpened)="onFileOpened($event)"
    (downloadItems)="onDownloadItems($event)"
    (moveItemsTo)="onMoveItems($event)"
    (itemsRestored)="onItemsRestored($event)"
    (itemsPermanentlyDeleted)="onItemsPermanentlyDeleted($event)"
    (lockItems)="onLockItems($event)"
    (unlockItems)="onUnlockItems($event)"></ndt-contextual-menu>
</mat-menu>
