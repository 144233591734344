// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: { [key: string]: any } = {
  production: false,
  staging: false,
  hmr: false,
  i18n: {
    locales: ['fr-CA', 'en-US'],
    languages: ['fr', 'en'],
    fallback: 'fr-CA',
    defaultLocale: 'en-US',
    storageKey: 'ndt_user_lang',
    header: 'X-Lang',
  },
  api: {
    baseUrl: 'https://drive-api.olympus-ims-dev.com/api',
    origin: 'https://drive.olympus-ims-dev.com',
    osc: 'https://app.olympus-ims-dev.com',
  },
  file: {
    ndeType: 'application/data-nde',
    osetType: 'application/data-oset',
    odfType: 'application/data-odf',
    opdType: 'application/data-opd',
    odatType: 'application/data-odat',
  },
  appInsightsKey: '09a22597-af26-46eb-95d0-4cd01061fe07',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
