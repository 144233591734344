import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';
import { UploadActions } from '@core/store/actions';

export interface CurrentUploadState {
  file: File;
  uploadId: string,
  progress: number;
  speed: number;
  remaining: number;
  uploaded: number;
  size: number;
  started: boolean;
  complete: boolean;
  failed: boolean;
  cancelled: boolean;
  error: string;
}

export interface UploadState {
  files: CurrentUploadState[];
  loading: boolean;
  complete: boolean;
  started: boolean;
  alert: number;
}

export const initialCurrentUploadState: CurrentUploadState = {
  file: null,
  uploadId: null,
  progress: 0,
  speed: 0,
  remaining: 0,
  uploaded: 0,
  size: 0,
  started: false,
  complete: false,
  failed: false,
  cancelled: false,
  error: null,
};

export const initialUploadState: UploadState = {
  files: [],
  loading: false,
  complete: false,
  started: false,
  alert: 0,
};

const reducer: ActionReducer<UploadState> = createReducer(
  initialUploadState,
  on(UploadActions.uploadFiles, (state: UploadState, { files }: { files: File[] }) => ({
    ...state,
    loading: true,
    started: true,
    files: files.map(f => ({ ...initialCurrentUploadState, file: f })),
  })),

  on(UploadActions.uploadFileStarted, (state: UploadState, { name, uploadId }: { name: string, uploadId: string }) => ({
    ...state,
    files: state.files.map(f => f.file.name === name ? { ...f, started: true, uploadId } : f),
  })),

  on(UploadActions.uploadFileProgress, (state: UploadState, { name, progress, speed, remaining, uploadId, uploaded, size }: { name: string, progress: number, speed: number, remaining: number, uploadId: string, uploaded: number, size: number }) => ({
    ...state,
    files: state.files.map(c => c.file.name === name ? { ...c, progress, speed, remaining, uploadId, uploaded, size } : c),
  })),

  on(UploadActions.uploadFileSuccess, (state: UploadState, { file }: { file: FileItem }) => ({
    ...state,
    files: state.files.map(f => f.file.name === file.name ? { ...f, complete: true } : f),
    alert: state.alert + 1,
  })),

  on(UploadActions.uploadFileFail, (state: UploadState, { name, error }: { name: string, error: string }) => ({
    ...state,
    loading: false,
    files: state.files.map(f => f.file.name === name ? { ...f, started: false, failed: true, error } : f),
    alert: state.alert + 1,
  })),

  on(UploadActions.uploadFilesFail, (state: UploadState) => ({ ...state, loading: false })),

  on(UploadActions.uploadFilesAllComplete, (state: UploadState) => ({ ...state, loading: false, complete: true })),

  on(UploadActions.clearFile, (state: UploadState) => ({ ...state, loading: true })),

  on(UploadActions.clearFileSuccess, (state: UploadState, { name }: { name: string }) => ({
    ...state,
    files: state.files.map(f => f.file.name === name ? { ...f, started: false, cancelled: true } : f),
    alert: state.alert + 1,
  })),

  on(UploadActions.clearFileFail, (state: UploadState, { name, error }: { name: string, error: string }) => ({
    ...state,
    files: state.files.map(f => f.file.name === name ? { ...f, started: false, failed: true, error } : f),
    alert: state.alert + 1,
  })),

  on(UploadActions.clearAlert, (state: UploadState) => ({ ...state, alert: initialUploadState.alert })),
  on(UploadActions.resetUpload, () => ({ ...initialUploadState })),
);

export function uploadReducer(state: UploadState | undefined, action: Action): UploadState {
  return reducer(state, action);
}
