import { ToastType } from './toast.enum';

export class Toast {
  constructor(
    public message: string,
    public type: ToastType,
    public data?: { [key: string]: any },
    public duration?: number,
    public action?: string,
    public actionCallback?: (...args) => void,
  ) {}
}

export type ExtraParams = { duration?: number, action?: string, actionCallback?: (...args) => void };
