import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Params } from '@angular/router';
import {
  NgxFileUploadOptions,
  NgxFileUploadService,
  NgxFileUploadState,
} from '@exalif/ngx-file-upload';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { filter, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';

import { environment } from '@env';
import { BackendError } from '@core/models/error/backend-error.type';
import { UploadErrorEnum } from '@core/models/error/upload-error.enum';
import { FileItem as NdtFile } from '@core/models/file-item.type';
import { UploadService } from '@core/services/upload/upload.service';
import { DefaultActions, ToastActions, UploadActions } from '@core/store/actions';
import { getRouterParams } from '@core/store/selectors';
import { UploadListComponent } from '@shared/components';
import { ConfigService } from '@shared/services';

const {
  file: { ...extensions },
} = environment;

@Injectable()
export class UploadEffects {
  public uploadFile$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(UploadActions.uploadFiles),
      withLatestFrom(this.store.pipe(select(getRouterParams))),
      switchMap(([{ list }, params]: [{ list: FileList }, Params]) => {
        let uploadStarted: boolean = false;
        let uploadedFilesCount: number = 0;

        return this.uploadsProgress.pipe(
          tap((uploadState: NgxFileUploadState) => {
            if (!uploadState && !uploadStarted) {
              this.ngxUploadService.handleFileList(list, {
                directoryId: params.id,
              });

              uploadStarted = true;
              this.bottomSheet.open(UploadListComponent, {
                hasBackdrop: false,
                closeOnNavigation: false,
                autoFocus: 'first-heading',
                panelClass: 'upload-list',
              });
            }
          }),
          filter((uploadState: NgxFileUploadState) => !!uploadState),
          map((uploadState: NgxFileUploadState) => {
            const { progress, status, remaining, name, uploadId } = uploadState;
            const uploadComplete: boolean =
              progress === 100 && status === 'complete' && remaining === 0;
            const uploadFail: boolean = status === 'error';
            const uploadCancelled: boolean = status === 'cancelled';
            const uploadAdded: boolean = status === 'added';
            const uploading: boolean = status === 'uploading';

            if (uploadAdded) {
              return UploadActions.uploadFileStarted({ name, uploadId });
            } else if (uploadComplete) {
              const { chunkCount } = uploadState;
              uploadedFilesCount++;

              return UploadActions.uploadFileComplete({ name, fileId: uploadId, chunkCount });
            } else if (uploadFail) {
              const {
                responseStatus,
                response: { errorType },
              } = uploadState;

              const error: string = errorType
                ? `upload.failed.${errorType}`
                : 'upload.failed.global';
              uploadedFilesCount++;

              if ([UploadErrorEnum.FileAlreadyExists].includes(errorType)) {
                return UploadActions.uploadFileFail({
                  name,
                  error,
                  params: { name, status: responseStatus },
                });
              }

              return UploadActions.clearFile({
                name,
                fileId: uploadId,
                error,
                params: { name, status: responseStatus },
              });
            } else if (uploadCancelled) {
              uploadedFilesCount++;

              return UploadActions.clearFileSuccess({ name });
            } else if (uploading && typeof progress === 'number') {
              const { speed, uploaded, size } = uploadState;

              return UploadActions.uploadFileProgress({
                name,
                progress,
                speed,
                remaining,
                uploaded,
                size,
                uploadId,
              });
            }

            return DefaultActions.noAction();
          }),
          tap(() => {
            if (uploadedFilesCount === list.length) {
              this.store.dispatch(UploadActions.uploadFilesAllComplete());
            }
          }),
          catchError(() => of(UploadActions.uploadFilesFail({ error: 'upload.failed.global' }))),
        );
      }),
    ),
  );

  public uploadFileComplete$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(UploadActions.uploadFileComplete),
      switchMap(
        ({ name, fileId, chunkCount }: { name: string; fileId: string; chunkCount: number }) =>
          this.uploadService.completeUpload(fileId, chunkCount).pipe(
            map(({ fileItem }: { fileItem: NdtFile }) =>
              UploadActions.uploadFileSuccess({ file: fileItem }),
            ),
            catchError(({ errorType }: BackendError) => {
              const error: string = errorType
                ? `upload.failed.${errorType}`
                : 'upload.failed.global';

              return of(UploadActions.uploadFileFail({ error, name }));
            }),
          ),
      ),
    ),
  );

  public clearFile$: any = createEffect((): any =>
    this.actions$.pipe(
      ofType(UploadActions.clearFile),
      switchMap(({ name, fileId, error }: { name: string; fileId: string; error?: string }) =>
        this.uploadService.deleteUpload(fileId, error).pipe(
          map(() =>
            error
              ? UploadActions.uploadFileFail({ name, error })
              : UploadActions.clearFileSuccess({ name }),
          ),
          catchError(({ errorType }: BackendError) => {
            const parsedError: string = errorType
              ? `upload.failed.${errorType}`
              : 'upload.failed.global';

            return of(UploadActions.clearFileFail({ error: parsedError, name }));
          }),
        ),
      ),
    ),
  );

  public uploadFilesFail$: any = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadActions.uploadFilesFail),
      switchMap(({ error }: { error: string }) => [ToastActions.addToastError({ message: error })]),
    ),
  );

  private options: NgxFileUploadOptions;
  private uploadsProgress: Observable<NgxFileUploadState>;

  constructor(
    private readonly actions$: Actions,
    private readonly uploadService: UploadService,
    private ngxUploadService: NgxFileUploadService,
    private readonly configService: ConfigService,
    private readonly cookieService: CookieService,
    private readonly store: Store,
    private readonly bottomSheet: MatBottomSheet,
  ) {
    this.initUploadEffect();
  }

  private async initUploadEffect(): Promise<void> {
    const bearer: string = this.cookieService.get(this.configService.tokenKey);

    this.options = {
      concurrency: 1,
      endpoint: `${this.uploadService.uploadPrefix}`,
      token: bearer,
      autoUpload: true,
      withCredentials: true,
      useDataFromPostResponseBody: true,
      useBackendUploadId: true,
      useUploadIdAsUrlPath: true,
      breakRetryErrorCodes: [400, 404, 418, 500],
      chuckSuccessCodes: [200, 300],
      chunkSize: 1024 * 1024 * 4,
      metadata: (file: File): { fileName: string; contentType: string; sizeInBytes: number } => ({
        fileName: file.name,
        contentType: this.getMimetype(file),
        sizeInBytes: file.size,
      }),
    };

    this.uploadsProgress = this.ngxUploadService
      .init(this.options)
      .pipe(startWith(null as NgxFileUploadState));
  }

  private getMimetype(file: File): string {
    const knownMimetypes: string[] = Object.values(extensions);
    const extension: string = file.name.split('.').pop();

    const isStream: boolean = file.type === 'application/octet-stream';
    const mimeType: string = knownMimetypes.find(e =>
      extension.toLowerCase().startsWith(e.split('data-').pop()),
    );
    const hasKnownExtension: boolean = !!mimeType;

    const hasNoMimetype: boolean = !file.type && hasKnownExtension;
    const hasWrongMimetype: boolean = isStream && hasKnownExtension;

    if ((hasNoMimetype || hasWrongMimetype) && hasKnownExtension) {
      return mimeType;
    }

    return file.type;
  }
}
