import { RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

import { getFileManagerState, NdtFileManagerState, RouterStateUrl } from '@core/store/reducers';

export const getRouterState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState): RouterReducerState<RouterStateUrl> => !!state && state.router,
);

export const getRouterStateUrl: any = createSelector(
  getRouterState,
  (routerState: RouterReducerState<RouterStateUrl>) => routerState && routerState.state,
);

export const getRouterUrl: any = createSelector(
  getRouterStateUrl,
  (state: RouterStateUrl) => state && state.url,
);

export const getRouterParams: any = createSelector(
  getRouterStateUrl,
  (routerStateUrl: RouterStateUrl) => routerStateUrl && routerStateUrl.params,
);

export const getRouterQueryParams: any = createSelector(
  getRouterStateUrl,
  (routerStateUrl: RouterStateUrl) => routerStateUrl && routerStateUrl.queryParams,
);

export const getRouterData: any = createSelector(
  getRouterStateUrl,
  (routerStateUrl: RouterStateUrl) => routerStateUrl && routerStateUrl.data,
);

export const getRouterUrlArray: any = createSelector(
  getRouterUrl,
  (url: string) => url && url.split('/').slice(1),
);
