<div class="file">
  <div class="file__header">
    <p class="file__name"><span>{{ current.file.name }}</span></p>

    <div class="file__actions">
      <evc-button
        *ngIf="cancellable"
        type="icon"
        size="sm"
        variant="semi"
        leftIcon="xmark"
        [title]="'upload.actions.cancel' | translate"
        (click)="cancelFile(current.uploadId)"
      ></evc-button>
    </div>
  </div>

  <evc-loading-bar *ngIf="pending" mode="indeterminate"></evc-loading-bar>
  <evc-loading-bar *ngIf="progress" [progressValue]="current.progress | floor"></evc-loading-bar>
  <evc-loading-bar *ngIf="current.failed" [progressValue]="100" state="error"></evc-loading-bar>
  <evc-loading-bar *ngIf="cancelled" [progressValue]="current.progress | floor" state="error"></evc-loading-bar>

  <p class="file__hint">
    <ng-container *ngIf="progress && current.speed && !current.complete">
      <span *ngIf="current.remaining >= 0">{{ current.remaining | remaining }}</span>

      <ng-container *ngIf="current.remaining >= 0 && current.speed >= 0">&nbsp;-&nbsp;</ng-container>

      <span *ngIf="current.speed >= 0">{{ current.speed | filesize }}/s</span>
    </ng-container>

    <span *ngIf="pending">
      {{ 'upload.hint.pending' | translate }}
    </span>

    <span *ngIf="current.complete">
      {{ 'upload.hint.complete' | translate }}
    </span>

    <span *ngIf="current.failed">
      {{ (current.error || 'upload.hint.failed') | translate }}
    </span>

    <span *ngIf="cancelled">
      {{ 'upload.hint.cancelled' | translate }}
    </span>

    <span class="file__progress" *ngIf="current.uploaded >= 0 && current.size >= 0">
      {{ current.uploaded | filesize }}/{{ current.size | filesize }}
    </span>
  </p>
</div>
