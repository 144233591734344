import { inject, NgModule } from '@angular/core';
import { CanMatchFn, Router, RouterModule, Routes } from '@angular/router';
import { Observable } from 'rxjs';

import { Datatype } from '@core/models/datatype.enum';
import { navigationResolver } from './app.resolver';
import { ArchiveModule } from './modules/archive/archive.module';
import { DeviceDataModule } from './modules/device-data/device-data.module';
import { DriveModule } from './modules/drive/drive.module';
import { TrashModule } from './modules/trash/trash.module';

const comingSoonMatch: CanMatchFn = (): Observable<boolean> | Promise<boolean> | boolean =>
  inject(Router).navigate(['/drive']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'drive',
    resolve: { navigation: navigationResolver },
    pathMatch: 'full',
  },
  {
    path: 'drive',
    data: { id: Datatype.DRIVE, base: 'pages.drive' },
    loadChildren: (): Promise<Routes | typeof DriveModule> =>
      import('./modules/drive/drive.module').then(x => x.DriveModule),
    resolve: { navigation: navigationResolver },
  },
  {
    path: 'devices-data',
    data: { id: Datatype.DEVICE_DATA, base: 'pages.devicesData' },
    loadChildren: (): Promise<Routes | typeof DeviceDataModule> =>
      import('./modules/device-data/device-data.module').then(x => x.DeviceDataModule),
    canMatch: [comingSoonMatch],
    resolve: { navigation: navigationResolver },
  },
  {
    path: 'trash',
    data: { id: Datatype.TRASH, base: 'pages.trash', locked: true },
    loadChildren: (): Promise<Routes | typeof TrashModule> =>
      import('./modules/trash/trash.module').then(x => x.TrashModule),
    resolve: { navigation: navigationResolver },
  },
  {
    path: 'archives',
    data: { id: Datatype.ARCHIVE, base: 'pages.archives' },
    loadChildren: (): Promise<Routes | typeof ArchiveModule> =>
      import('./modules/archive/archive.module').then(x => x.ArchiveModule),
    canMatch: [comingSoonMatch],
    resolve: { navigation: navigationResolver },
  },
  {
    path: '**',
    redirectTo: 'drive',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
