import { AuthEffects } from './auth/auth.effect';
import { BreadcrumbEffects } from './breadcrumb/breadcrumb.effect';
import { BrowseTargetEffects } from './browse-target/browse-target.effect';
import { CellarEffects } from './cellar/cellar.effect';
import { ClipboardEffects } from './clipboard/clipboard.effect';
import { FileEffects } from './file/file.effect';
import { StorageEffects } from './storage/storage.effect';
import { ToastEffects } from './toast/toast.effect';
import { UploadEffects } from './upload/upload.effect';
import { UserEffects } from './user/user.effect';

export const ndtFileManagerStoreEffects: any[] = [
  AuthEffects,
  ToastEffects,
  FileEffects,
  UserEffects,
  ClipboardEffects,
  BreadcrumbEffects,
  UploadEffects,
  BrowseTargetEffects,
  CellarEffects,
  StorageEffects,
];

export * from './auth/auth.effect';
export * from './toast/toast.effect';
export * from './file/file.effect';
export * from './user/user.effect';
export * from './clipboard/clipboard.effect';
export * from './breadcrumb/breadcrumb.effect';
export * from './upload/upload.effect';
export * from './browse-target/browse-target.effect';
export * from './cellar/cellar.effect';
export * from './storage/storage.effect';
