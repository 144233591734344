import { ApplicationInsightsService } from './application-insights/application-insights.service';
import { ApplicationsService } from './applications/applications.service';
import { ConfigService } from './config/config.service';
import { DialogService } from './dialog/dialog.service';
import { FileTypeService } from './file-type/file-type.service';
import { I18nService } from './i18n/i18n.service';
import { PanelService } from './panel/panel.service';
import { ProfileService } from './profile/profile.service';
import { ToastService } from './toast/toast.service';

export const sharedServices: any[] = [
  ConfigService,
  I18nService,
  ToastService,
  PanelService,
  DialogService,
  ApplicationsService,
  ApplicationInsightsService,
  ProfileService,
  FileTypeService,
];

export * from './config/config.service';
export * from './i18n/i18n.service';
export * from './toast/toast.service';
export * from './panel/panel.service';
export * from './dialog/dialog.service';
export * from './applications/applications.service';
export * from './application-insights/application-insights.service';
export * from './profile/profile.service';
export * from './file-type/file-type.service';
