import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { CellarData } from '@core/models/cellar-data.type';
import { CellarActions } from '@core/store/actions';

export interface CellarState {
  [key: string]: CellarData;
}

export const initialCellarState: CellarState = {};

const reducer: ActionReducer<CellarState> = createReducer(
  initialCellarState,
  on(CellarActions.setData, (state: CellarState, { data }: { data: any }) => ({ ...state, ...data })),
  on(CellarActions.removeData, (state: CellarState, { key }: { key: string }) => {
    const updatedState: CellarState = { ...state };
    delete updatedState[key];

    return { ...updatedState };
  }),
  on(CellarActions.resetCellar, () => ({ ...initialCellarState })),
);

export function cellarReducer(state: CellarState | undefined, action: Action): CellarState {
  return reducer(state, action);
}
