import { createAction, props } from '@ngrx/store';

import { Application } from '@core/models/application.type';
import { DataSort } from '@core/models/data-sort.type';
import { BackendError } from '@core/models/error/backend-error.type';
import { FileItem } from '@core/models/file-item.type';
import { Metadata } from '@core/models/nde-item-metadata.type';

export const LOAD_DATA: string = '[File] Load data';
export const LOAD_DATA_SUCCESS: string = '[File] Load data success';
export const LOAD_DATA_FAIL: string = '[File] Load data fail';

export const LOAD_LIST: string = '[File] Load list';
export const LOAD_FROM_SEARCH: string = '[File] Load from search';
export const SET_SEARCH: string = '[File] Set search';
export const CLEAR_SEARCH: string = '[File] Clear search';

export const LOAD_FOLDER: string = '[File] Load folder';
export const LOAD_FOLDER_SUCCESS: string = '[File] Load folder success';
export const LOAD_FOLDER_FAIL: string = '[File] Load folder fail';

export const SET_SELECTED_ITEMS: string = '[File] Set selected items';
export const SET_CURRENT_FOLDER: string = '[File] Set current folder';
export const SET_NEW_ID_TO_FIND: string = '[File] Set new ID to find';

export const CREATE_FOLDER: string = '[File] Create folder';
export const CREATE_FOLDER_SUCCESS: string = '[File] Create folder success';
export const CREATE_FOLDER_FAIL: string = '[File] Create folder fail';

export const RENAME_ITEM: string = '[File] Rename item';
export const RENAME_ITEM_SUCCESS: string = '[File] Rename item success';
export const RENAME_ITEM_FAIL: string = '[File] Rename item fail';

export const DELETE_ITEMS: string = '[File] Delete items';
export const DELETE_ITEMS_SUCCESS: string = '[File] Delete items success';
export const DELETE_ITEMS_FAIL: string = '[File] Delete items fail';

export const DELETE_ALL_TRASH: string = '[File] Delete all trash';
export const DELETE_ALL_TRASH_SUCCESS: string = '[File] Delete all trash success';
export const DELETE_ALL_TRASH_FAIL: string = '[File] Delete all trash fail';

export const RESTORE_ITEMS: string = '[File] Restore items';
export const RESTORE_ITEMS_SUCCESS: string = '[File] Restore items success';
export const RESTORE_ITEMS_FAIL: string = '[File] Restore items fail';

export const OPEN_FILE: string = '[File] Open file';
export const OPEN_IN_APP: string = '[File] Open in app';
export const OPEN_IN_APP_FAIL: string = '[File] Open in app fail';

export const DOWNLOAD_ITEMS: string = '[File] Download items';
export const DOWNLOAD_ITEM_PROGRESS: string = '[File] Download item progress';
export const DOWNLOAD_ITEM_SUCCESS: string = '[File] Download item success';
export const DOWNLOAD_ITEM_FAIL: string = '[File] Download item fail';

export const DOWNLOAD_ITEMS_BY_URL: string = '[File] Download items by url';
export const DOWNLOAD_ITEMS_BY_URL_SUCCESS: string = '[File] Download items by url success';
export const DOWNLOAD_ITEMS_BY_URL_FAIL: string = '[File] Download items by url fail';

export const COPY_ITEMS: string = '[File] Copy items';
export const COPY_ITEMS_SUCCESS: string = '[File] Copy items success';
export const COPY_ITEMS_FAIL: string = '[File] Copy items fail';

export const MOVE_ITEMS: string = '[File] Move items';
export const MOVE_ITEMS_SUCCESS: string = '[File] Move items success';
export const MOVE_ITEMS_FAIL: string = '[File] Move items fail';

export const LOCK_ITEMS: string = '[File] Lock items';
export const LOCK_ITEMS_SUCCESS: string = '[File] Lock items success';
export const LOCK_ITEMS_FAIL: string = '[File] Lock items fail';

export const UNLOCK_ITEMS: string = '[File] Unlock items';
export const UNLOCK_ITEMS_SUCCESS: string = '[File] Unlock items success';
export const UNLOCK_ITEMS_FAIL: string = '[File] Unlock items fail';

export const LOAD_ITEM_METADATA: string = '[File] Load item metadata';
export const LOAD_ITEM_METADATA_SUCCESS: string = '[File] Load item metadata success';
export const LOAD_ITEM_METADATA_FAIL: string = '[File] Load item metadata fail';
export const CLEAR_METADATA: string = '[File] clear metadata';

export const RESET_FILE_STATE: string = '[File] Reset state';

export const loadData: any = createAction(
  LOAD_DATA,
  props<{ page: number, sort: DataSort, search: string }>(),
);

export const loadDataSuccess: any = createAction(
  LOAD_DATA_SUCCESS,
  props<{ data: FileItem[], page: number, count: number, sort: DataSort, idToFind?: string }>(),
);

export const loadDataFail: any = createAction(
  LOAD_DATA_FAIL,
  props<{ error: BackendError }>(),
);

export const loadList: any = createAction(
  LOAD_LIST,
  props<{ page: number, sort: DataSort }>(),
);

export const loadFromSearch: any = createAction(
  LOAD_FROM_SEARCH,
  props<{ search: string, page: number, sort: DataSort }>(),
);

export const setSearch: any = createAction(
  SET_SEARCH,
  props<{ search: string }>(),
);

export const clearSearch: any = createAction(
  CLEAR_SEARCH,
);

export const loadFolder: any = createAction(
  LOAD_FOLDER,
  props<{ id: string }>(),
);

export const loadFolderSuccess: any = createAction(
  LOAD_FOLDER_SUCCESS,
  props<{ folder: FileItem }>(),
);

export const loadFolderFail: any = createAction(
  LOAD_FOLDER_FAIL,
  props<{ error: BackendError }>(),
);

export const setSelectedItems: any = createAction(
  SET_SELECTED_ITEMS,
  props<{ items: FileItem[] }>(),
);

export const setCurrentFolder: any = createAction(
  SET_CURRENT_FOLDER,
  props<{ folder: FileItem }>(),
);

export const setNewIdToFind: any = createAction(
  SET_NEW_ID_TO_FIND,
  props<{ idToFind: string }>(),
);

export const createFolder: any = createAction(
  CREATE_FOLDER,
  props<{ name: string }>(),
);

export const createFolderSuccess: any = createAction(
  CREATE_FOLDER_SUCCESS,
  props<{ folder: FileItem }>(),
);

export const createFolderFail: any = createAction(
  CREATE_FOLDER_FAIL,
  props<{ error: BackendError }>(),
);

export const renameItem: any = createAction(
  RENAME_ITEM,
  props<{ item: FileItem }>(),
);

export const renameItemSuccess: any = createAction(
  RENAME_ITEM_SUCCESS,
  props<{ item: FileItem }>(),
);

export const renameItemFail: any = createAction(
  RENAME_ITEM_FAIL,
  props<{ error: BackendError }>(),
);

export const deleteItems: any = createAction(
  DELETE_ITEMS,
  props<{ items: FileItem[] }>(),
);

export const deleteItemsSuccess: any = createAction(
  DELETE_ITEMS_SUCCESS,
  props<{ items: FileItem[] }>(),
);

export const deleteItemsFail: any = createAction(
  DELETE_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const deleteAllTrash: any = createAction(
  DELETE_ALL_TRASH,
);

export const deleteAllTrashSuccess: any = createAction(
  DELETE_ALL_TRASH_SUCCESS,
  props<{ totalDeletedFiles: number, totalDeletedDirectories: number }>(),
);

export const deleteAllTrashFail: any = createAction(
  DELETE_ALL_TRASH_FAIL,
  props<{ error: BackendError }>(),
);

export const restoreItems: any = createAction(
  RESTORE_ITEMS,
  props<{ items: FileItem[], undo?: boolean }>(),
);

export const restoreItemsSuccess: any = createAction(
  RESTORE_ITEMS_SUCCESS,
  props<{ items: FileItem[], undo?: boolean, toNotify?: FileItem[], location?: string }>(),
);

export const restoreItemsFail: any = createAction(
  RESTORE_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const openFile: any = createAction(
  OPEN_FILE,
  props<{ file: FileItem, app: Application }>(),
);

export const openInApp: any = createAction(
  OPEN_IN_APP,
  props<{ file: FileItem, app: Application }>(),
);

export const openInAppFail: any = createAction(
  OPEN_IN_APP_FAIL,
  props<{ error: BackendError }>(),
);

export const downloadItems: any = createAction(
  DOWNLOAD_ITEMS,
  props<{ items: FileItem[] }>(),
);

export const downloadItemProgress: any = createAction(
  DOWNLOAD_ITEM_PROGRESS,
  props<{ item: FileItem, percentage: number }>(),
);

export const downloadItemSuccess: any = createAction(
  DOWNLOAD_ITEM_SUCCESS,
  props<{ item: FileItem }>(),
);

export const downloadItemFail: any = createAction(
  DOWNLOAD_ITEM_FAIL,
  props<{ error: string, item?: FileItem, params?: { [key: string]: string } }>(),
);

export const downloadItemsByUrl: any = createAction(
  DOWNLOAD_ITEMS_BY_URL,
  props<{ items: FileItem[] }>(),
);

export const downloadItemsByUrlSuccess: any = createAction(
  DOWNLOAD_ITEMS_BY_URL_SUCCESS,
  props<{ items: FileItem[] }>(),
);

export const downloadItemsByUrlFail: any = createAction(
  DOWNLOAD_ITEMS_BY_URL_FAIL,
  props<{ error: BackendError }>(),
);

export const copyItems: any = createAction(
  COPY_ITEMS,
  props<{ items: FileItem[], parentId?: string }>(),
);

export const copyItemsSuccess: any = createAction(
  COPY_ITEMS_SUCCESS,
  props<{ items: FileItem[], parentId: string }>(),
);

export const copyItemsFail: any = createAction(
  COPY_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const moveItems: any = createAction(
  MOVE_ITEMS,
  props<{ items: FileItem[], parentId?: string }>(),
);

export const moveItemsSuccess: any = createAction(
  MOVE_ITEMS_SUCCESS,
  props<{ items: FileItem[] }>(),
);

export const moveItemsFail: any = createAction(
  MOVE_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const resetFileState: any = createAction(
  RESET_FILE_STATE,
);

export const lockItems: any = createAction(
  LOCK_ITEMS,
  props<{ items: FileItem[] }>(),
);

export const lockItemsSuccess: any = createAction(
  LOCK_ITEMS_SUCCESS,
  props<{ items: FileItem[] }>(),
);

export const lockItemsFail: any = createAction(
  LOCK_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const unlockItems: any = createAction(
  UNLOCK_ITEMS,
  props<{ items: FileItem[] }>(),
);

export const unlockItemsSuccess: any = createAction(
  UNLOCK_ITEMS_SUCCESS,
  props<{ items: FileItem[] }>(),
);

export const unlockItemsFail: any = createAction(
  UNLOCK_ITEMS_FAIL,
  props<{ error: BackendError }>(),
);

export const loadItemMetadata: any = createAction(
  LOAD_ITEM_METADATA,
  props<{ item: FileItem }>(),
);

export const loadItemMetadataSuccess: any = createAction(
  LOAD_ITEM_METADATA_SUCCESS,
  props<{ metadata: Metadata }>(),
);

export const loadItemMetadataFail: any = createAction(
  LOAD_ITEM_METADATA_FAIL,
  props<{ error: BackendError, id: string }>(),
);

export const clearMetadata: any = createAction(
  CLEAR_METADATA,
);
