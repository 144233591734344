<div
  class="contextual-menu"
  [ngClass]="customClass"
>
  <!-- global -->
  <ng-container *ngIf="isGlobal && !areDeletedItems">

    <evc-dropdown-menu
      class="contextual-menu__dropdown"
      [menuConfig]="addMenuConfig"
    ></evc-dropdown-menu>

    <input type="file" #upload hidden multiple (change)="onFilesSelected()"/>

  </ng-container>

  <!-- items -->
  <ng-container *ngIf="items.length && !areDeletedItems">
    <!-- only one file -->
    <ng-container *ngIf="isOneFileSelected(items)">
      <button
        mat-menu-item
        [matMenuTriggerFor]="applications"
      >
        <mat-icon>open_with_outlined</mat-icon>
        <span>{{ 'list.menu.openWith' | translate }}</span>
      </button>

      <mat-divider></mat-divider>
    </ng-container>

    <button
      *ngIf="isLockable && areOnlyFiles(items)"
      mat-menu-item
      (click)="onLock(items)"
    >
      <mat-icon>lock</mat-icon>
      <span>{{ 'list.menu.lock' | translate }}</span>
    </button>

    <button
      *ngIf="isUnlockable && areOnlyFiles(items)"
      mat-menu-item
      (click)="onUnlock(items)"
    >
      <mat-icon>lock_open</mat-icon>
      <span>{{ 'list.menu.unlock' | translate }}</span>
    </button>

    <button
      *ngIf="!hideDelete && areOnlyFiles(items)"
      mat-menu-item
      [disabled]="!isLockable"
      (click)="onCopy(items)"
    >
      <mat-icon>content_copy_outlined</mat-icon>
      <span>{{ 'list.menu.copy' | translate }}</span>
    </button>

    <button
      *ngIf="!hideDelete"
      mat-menu-item
      [disabled]="!isLockable"
      (click)="onMoveTo(items)"
    >
      <mat-icon>drive_file_move_outlined</mat-icon>
      <span>{{ 'list.menu.moveTo' | translate }}</span>
    </button>

    <!-- only one -->
    <button
      mat-menu-item
      *ngIf="isOneSelected(items)"
      [disabled]="!isLockable"
      (click)="onRename(items[0])"
    >
      <mat-icon>drive_file_rename_outline_outlined</mat-icon>
      <span>{{ 'list.menu.rename' | translate }}</span>
    </button>

    <!-- TODO: remove condition here when no dev mode-->
    <mat-divider *ngIf="isDevMode || areOnlyFiles(items)"></mat-divider>

    <!-- only files -->
    <ng-container *ngIf="isDevMode && areOnlyFiles(items)">
      <button
        mat-menu-item
        [disabled]="comingSoon"
        class="coming-soon"
      >
        <mat-icon class="material-icons-outlined">send_to_mobile_outlined</mat-icon>
        <span>{{ 'list.menu.sendToDevice' | translate }}</span>
        <mat-icon
          *ngIf="comingSoon"
          class="material-icons-outlined"
        >campaign</mat-icon>
      </button>

      <mat-divider></mat-divider>
    </ng-container>

    <!-- TEMP: only files -->
    <button
      mat-menu-item
      *ngIf="areOnlyFiles(items)"
      (click)="onDownloadItems(items)"
    >
      <mat-icon>file_download_outlined</mat-icon>
      <span>{{ 'list.menu.download' | translate }}</span>
    </button>

    <button
      *ngIf="isDevMode"
      mat-menu-item
      [disabled]="comingSoon"
      class="coming-soon"
    >
      <mat-icon class="material-icons-outlined">archive_outlined</mat-icon>
      <span>{{ 'list.menu.archive' | translate }}</span>
      <mat-icon
        *ngIf="comingSoon"
        class="material-icons-outlined"
      >campaign</mat-icon>
    </button>

    <!-- only one -->
    <button
      mat-menu-item
      *ngIf="isDevMode && isOneSelected(items)"
      [disabled]="comingSoon"
      class="coming-soon"
    >
      <mat-icon class="material-icons-outlined">person_add_alt_outlined</mat-icon>
      <span>{{ 'list.menu.share' | translate }}</span>
      <mat-icon
        *ngIf="comingSoon"
        class="material-icons-outlined"
      >campaign</mat-icon>
    </button>

    <ng-container *ngIf="!hideDelete">
      <mat-divider></mat-divider>

      <button
        mat-menu-item
        [disabled]="!isLockable"
        (click)="onDelete(items)"
      >
        <mat-icon class="material-icons-outlined">delete_outlined</mat-icon>
        <span>{{ 'list.menu.delete' | translate }}</span>
      </button>
    </ng-container>
  </ng-container>

  <!-- deleted items -->
  <ng-container *ngIf="items.length && areDeletedItems">
    <button
      mat-menu-item
      (click)="onRestore(items)"
    >
      <mat-icon class="material-icons-outlined">restore_page</mat-icon>
      <span>{{ 'list.menu.restore' | translate }}</span>
    </button>

    <button
      mat-menu-item
      *ngIf="isDevMode"
      class="coming-soon"
      [disabled]="comingSoon"
      (click)="onPermanentlyDelete(items)"
    >
      <mat-icon class="material-icons-outlined">delete_forever</mat-icon>
      <span>{{ 'list.menu.permanentlyDelete' | translate }}</span>
      <mat-icon
        *ngIf="comingSoon"
        class="material-icons-outlined"
      >campaign</mat-icon>
    </button>
  </ng-container>
</div>

<mat-menu #applications="matMenu">
  <ng-container *ngFor="let app of availableApplications; trackBy:trackById">
    <button
      mat-menu-item
      (click)="onOpen(items, app)"
    >{{ app.name | translate }}</button>
  </ng-container>

  <mat-divider></mat-divider>

  <button
    mat-menu-item
    (click)="openLink('marketplace/store')"
  >
    <mat-icon>add</mat-icon>
    <span>{{ 'list.menu.moreApps' | translate }}</span>
  </button>
</mat-menu>
