<div class="actions-bar">
  <ng-container *ngIf="items.length">
    <ng-container *ngIf="!isTrash; else trashActions">
      <ndt-action-icon
        *ngIf="!anyItemLocked()"
        name="{{ 'list.menu.copy' | translate }}"
        icon="copy"
        (click)="onCopy(items)">
      </ndt-action-icon>

      <ndt-action-icon
        *ngIf="!anyItemLocked()"
        name="{{ 'list.menu.moveTo' | translate }}"
        icon="file-export"
        (click)="onMoveTo(items)"
      ></ndt-action-icon>

      <ndt-action-icon
        *ngIf="!anyFolderSelected()"
        name="{{ 'list.menu.download' | translate }}"
        icon="arrow-down-to-bracket"
        (click)="onDownload(items)"
      ></ndt-action-icon>

      <ndt-action-icon
        *ngIf="!anyItemLocked()"
        name="{{ 'list.menu.share' | translate }}"
        icon="share-nodes"
        (click)="onShare(items)"
      ></ndt-action-icon>

      <ndt-action-icon
        *ngIf="!anyItemLocked()"
        name="{{ 'list.menu.delete' | translate }}"
        icon="trash-can"
        (click)="onDelete(items)"
      ></ndt-action-icon>
    </ng-container>

    <ng-template #trashActions>
      <ndt-action-icon 
        name="{{ 'list.menu.restore' | translate }}"
        icon="trash-can-undo"
        (click)="onRestore(items)"
      ></ndt-action-icon>

      <ndt-action-icon
        *ngIf="isAdmin"
        name="{{ 'list.menu.permanentlyDelete' | translate }}"
        icon="trash-can"
        (click)="onPermanentlyDelete(items)"
      ></ndt-action-icon>
    </ng-template>
  </ng-container>
</div>