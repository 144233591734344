import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { FileItem } from '@core/models/file-item.type';
import { I18nService } from '@shared/services';

@Component({
  selector: 'ndt-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.scss'],
})
export class FileFormComponent implements OnDestroy {
  public name: string;
  public initialFocused: boolean = false;
  public touched: boolean = false;

  private subscription: Subscription = new Subscription();

  public get file(): FileItem {
    return this.data.file;
  }

  public get title(): string {
    return this.file ? 'file.rename' : 'file.new';
  }

  public get action(): string {
    return this.file ? 'file.save' : 'file.create';
  }

  constructor(
    private readonly i18nService: I18nService,
    private readonly dialogRef: MatDialogRef<FileFormComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { file: FileItem },
  ) {
    this.subscription.add(
      this.i18nService.getCurrentLang().subscribe(() => {
        if (this.touched) {
          return;
        }

        this.name = this.file?.name || this.i18nService.translateService.instant('file.defaultName');
      }),
    );
  }

  @HostListener('document:keyup.enter', ['$event'])
  public onSubmit(): void {
    this.dialogRef.close(this.name);
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public onInput(): void {
    this.touched = true;
  }

  public selectText(input: HTMLInputElement): void {
    input.select();
    this.initialFocused = true;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
