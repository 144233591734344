<div class="upload-list__header">
  <div class="upload-list__title">
    <span *ngIf="(isUploadDone$ | async); else uploadingTitle">{{ 'upload.titleDone' | translate }}</span>
    <ng-template #uploadingTitle><span>{{ 'upload.title' | translate }}</span></ng-template>
  </div>

  <div class="upload-list__actions">
    <evc-button
      *ngIf="!minified"
      type="icon"
      size="sm"
      variant="semi"
      [title]="'upload.actions.minify' | translate"
      (click)="toggleMinified({ clearAlert: true })"
    >
      <evc-svg-icon size="md" icon="angle-down"></evc-svg-icon>
    </evc-button>

    <evc-button
      *ngIf="minified"
      type="icon"
      size="sm"
      variant="semi"
      [title]="'upload.actions.expand' | translate"
      (click)="toggleMinified()"
    >
      <evc-svg-icon size="md" icon="angle-up"></evc-svg-icon>
    </evc-button>

    <evc-button
      *ngIf="(isUploadDone$ | async)"
      type="icon"
      size="sm"
      variant="semi"
      [disabled]="(isUploading$ | async)"
      [title]="'upload.actions.dismissAll' | translate"
      (click)="closePanel()"
    >
      <evc-svg-icon size="md" icon="xmark"></evc-svg-icon>
    </evc-button>
  </div>
</div>

<div class="upload-list__list" [ngClass]="{ 'upload-list__list--minified': minified }">
  <ng-container *ngFor="let current of (files$ | async); trackBy:trackByName">
    <ndt-upload-file
      class="upload-list__file"
      [current]="current"
      (cancel)="cancelFile($event)"
    ></ndt-upload-file>
  </ng-container>

  <div class="upload-list__list-gradient" [ngClass]="{ 'upload-list__list-gradient--minified': minified }"></div>
</div>
