import { createAction, props } from '@ngrx/store';

import { Storage } from '@core/models/storage.type';

export const LOAD_STORAGE: string = '[Storage] Load storage';
export const LOAD_STORAGE_SUCCESS: string = '[Storage] Load storage success';
export const LOAD_STORAGE_FAIL: string = '[Storage] Load storage fail';

export const RESET_STORAGE_STATE: string = '[Storage] Reset state';

export const loadStorage: any = createAction(
  LOAD_STORAGE,
);

export const loadStorageSuccess: any = createAction(
  LOAD_STORAGE_SUCCESS,
  props<Storage>(),
);

export const loadStorageFail: any = createAction(
  LOAD_STORAGE_FAIL,
  props<{ error: string }>(),
);

export const resetStorageState: any = createAction(
  RESET_STORAGE_STATE,
);
