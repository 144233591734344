import { createAction } from '@ngrx/store';

export const INITIALIZE_LOGIN: string = '[Auth] Initialize login';

export const LOGIN: string = '[Auth] Login';
export const LOGOUT: string = '[Auth] Logout';

export const initializeLogin: any = createAction(
  INITIALIZE_LOGIN,
);

export const login: any = createAction(
  LOGIN,
);

export const logout: any = createAction(
  LOGOUT,
);
