import { ActionIconComponent } from './actions-bar/action-icon/action-icon.component';
import { ActionsBarComponent } from './actions-bar/actions-bar.component';
import { AvailableAppsComponent } from './available-apps/available-apps.component';
import { BannerComponent } from './banner/banner.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContextualMenuComponent } from './contextual-menu/contextual-menu.component';
import { FileFormComponent } from './file-form/file-form.component';
import { FileListComponent } from './file-list/file-list.component';
import { NoItemComponent } from './no-item/no-item.component';
import { SearchComponent } from './search/search.component';
import { StorageCapacityComponent } from './storage-capacity/storage-capacity.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UploadListComponent } from './upload-list/upload-list.component';

export const sharedComponents: any[] = [
  FileListComponent,
  StorageCapacityComponent,
  ContextualMenuComponent,
  FileFormComponent,
  ConfirmDialogComponent,
  AvailableAppsComponent,
  SearchComponent,
  UploadFileComponent,
  UploadListComponent,
  BannerComponent,
  ActionsBarComponent,
  ActionIconComponent,
  NoItemComponent,
];

export * from './file-list/file-list.component';
export * from './storage-capacity/storage-capacity.component';
export * from './contextual-menu/contextual-menu.component';
export * from './file-form/file-form.component';
export * from './confirm-dialog/confirm-dialog.component';
export * from './available-apps/available-apps.component';
export * from './search/search.component';
export * from './upload-file/upload-file.component';
export * from './upload-list/upload-list.component';
export * from './banner/banner.component';
export * from './actions-bar/actions-bar.component';
export * from './actions-bar/action-icon/action-icon.component';
export * from './no-item/no-item.component';
