import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { Breakpoint } from '@core/models/breakpoint.enum';
import { PanelService } from '@shared/services';

@Component({
  selector: 'ndt-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnDestroy {
  private readonly _mobileQueryListener: () => void;

  public rightPanelVisibility$: Observable<boolean> = this.panelService.rightPanelVisibility$;
  public mobileQuery: MediaQueryList;
  public isClosed: boolean;

  constructor(
    private readonly panelService: PanelService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly media: MediaMatcher,
  ) {
    this.mobileQuery = this.media.matchMedia(`(max-width: ${Breakpoint.medium})`);
    this._mobileQueryListener = (): void => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.isClosed = this.mobileQuery.matches;
  }

  public closeRightVisibility(): void {
    this.panelService.toggleRightPanelVisibility(false);
  }

  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }
}
