import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Moment } from 'moment';
import moment from 'moment';
import { Observable, Subscription, tap } from 'rxjs';

import { FileItem } from '@core/models/file-item.type';
import { Metadata } from '@core/models/nde-item-metadata.type';
import { loadItemMetadata } from '@core/store/actions/file/file.action';
import { getSelectedItemMetadata, getSelectedItemMetadataLoading, getSelectedItems } from '@core/store/selectors';
import { I18nService, PanelService } from '@shared/services';

const DATE_LONG_FORMAT: string = 'YYYY-MM-DDTHH:mm:ssZ';

@Component({
  selector: 'ndt-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent implements OnDestroy {
  @ViewChild('itemName', { static: false }) public itemNameElement: ElementRef<HTMLElement>;

  public selectedItemMetaData$: Observable<Metadata> = this.store.pipe(select(getSelectedItemMetadata));
  public loading$: Observable<boolean> = this.store.pipe(select(getSelectedItemMetadataLoading));
  public currentItem: FileItem;
  public itemData: { name: string, value: string | Moment }[];

  public selectedItems$: Observable<FileItem[]> = this.store.pipe(
    select(getSelectedItems),
    tap((items: FileItem[]) => {
      if (items?.length === 1 && this.currentItem?.id !== items[0].id) {
        this.currentItem = items[0];
        this.store.dispatch(loadItemMetadata({ item: items[0] }));
      }
    }),
  );

  private subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly i18nService: I18nService,
    private readonly panelService: PanelService,
  ) {
    this.subscription.add(
      this.selectedItemMetaData$.subscribe((metadata) => {
        this.mergeItemData(metadata);
      }),
    );
  }

  public handleCloseRightPanel(): void {
    this.panelService.toggleRightPanelVisibility(false);
  }

  public getItemIcon(item: string): string {
    switch (item) {
      case 'directory':
        return 'folder';
      case 'file':
        return 'description';
      default:
        return 'insert_drive_file';
    }
  }

  public isMetadataEmpty(metadata: Metadata): boolean {
    return !metadata || Object.keys(metadata).length === 0;
  }

  public mergeItemData(metadata: Metadata): void {
    if (this.isMetadataEmpty(metadata)) {
      this.itemData = [
        { name: 'createdAt', value: this.parseDate(this.currentItem?.createdAt) },
        { name: 'updatedAt', value: this.parseDate(this.currentItem?.updatedAt) },
      ];
    } else {
      this.itemData = Object.keys(metadata).map((key) => ({ name: key, value: metadata[key] }));
    }

    if (this.currentItem?.deletedAt) {
      this.itemData.push({ name: 'deletedAt', value: this.parseDate(this.currentItem.deletedAt) });
    }

    this.itemData.forEach((item) => {
      if (!item.value) {
        item.value = ' - ';
      }
    });
  }

  public trackByName(index: number, item: { name: string }): string {
    return `${item.name}-${index}`;
  }

  public getDataName(name: string): string {
    return this.i18nService.translateService.instant(`itemData.${name}`);
  }

  public isTooltipVisible(element: HTMLElement): boolean {
    if (element) {
      return element.offsetWidth < element.scrollWidth;
    }

    return false;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private parseDate(date: string | Moment): string {
    if (!date) {
      return ' - ';
    }

    return moment(date).format(DATE_LONG_FORMAT);
  }
}
