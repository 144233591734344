import { createSelector } from '@ngrx/store';

import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { Tenant } from '@core/models/tenant.type';
import { AppsState, getFileManagerState, NdtFileManagerState, ProfileState, TenantState, UserState } from '@core/store/reducers';

/**
 * General User State selector
 */
export const getUserState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.user,
);

export const getProfileState: any = createSelector(
  getUserState,
  (state: UserState) => !!state && state.profile,
);

export const getProfileLoading: any = createSelector(
  getProfileState,
  (state: ProfileState) => !!state && state.loading,
);

export const getProfileLoaded: any = createSelector(
  getProfileState,
  (state: ProfileState) => !!state && state.loaded,
);

export const getProfileError: any = createSelector(
  getProfileState,
  (state: ProfileState) => !!state && state.error,
);

export const getIsAdmin: any = createSelector(
  getProfileState,
  (state: ProfileState) => !!state && state.isAdmin,
);

export const getProfile: any = createSelector(
  getProfileState,
  (state: ProfileState) => !!state && state.profile,
);

export const getProfileFullName: any = createSelector(
  getProfile,
  (profile: Profile) => !!profile && (profile.displayName || `${profile.givenName} ${profile.surname}`),
);

export const getProfileInitials: any = createSelector(
  getProfile,
  (profile: Profile) => !!profile ? `${profile.givenName.charAt(0) + profile.surname.charAt(0)}` : '',
);

export const getProfileEmail: any = createSelector(
  getProfile,
  (profile: Profile) => !!profile && profile.email,
);

export const getAppsState: any = createSelector(
  getUserState,
  (state: UserState) => !!state && state.apps,
);

export const getApps: any = createSelector(
  getAppsState,
  (state: AppsState) => (!!state && state.applications) || [],
);

export const getWebApps: any = createSelector(
  getApps,
  (apps: Application[]) => (!!apps && apps.filter(app => app.hasWebApplication)) || [],
);

export const getAppsLoading: any = createSelector(
  getAppsState,
  (state: AppsState) => !!state && state.loading,
);

export const getAppsLoaded: any = createSelector(
  getAppsState,
  (state: AppsState) => !!state && state.loaded,
);

export const getAppsError: any = createSelector(
  getAppsState,
  (state: AppsState) => !!state && state.error,
);

export const getTenantState: any = createSelector(
  getUserState,
  (state: UserState) => !!state && state.tenant,
);

export const getTenant: any = createSelector(
  getTenantState,
  (state: TenantState) => !!state && state.tenant,
);

export const getTenantLoading: any = createSelector(
  getTenantState,
  (state: TenantState) => !!state && state.loading,
);

export const getTenantLoaded: any = createSelector(
  getTenantState,
  (state: TenantState) => !!state && state.loaded,
);

export const getTenantError: any = createSelector(
  getTenantState,
  (state: TenantState) => !!state && state.error,
);

export const getTenantName: any = createSelector(
  getTenant,
  (tenant: Tenant) => (!!tenant && tenant.name) || '',
);
