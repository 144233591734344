import { createSelector } from '@ngrx/store';

import { getFileManagerState, NdtFileManagerState, UploadState } from '@core/store/reducers';

/**
 * General Upload State selector
 */
export const getUploadState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.upload,
);

export const getUploadAlerts: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.alert,
);

export const hasUploadError: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.files.some(s => !!s.error),
);

export const isUploading: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.loading,
);

export const isUploadComplete: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.complete,
);

export const isUploadDone: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.files.every(s => s.cancelled || s.failed || s.complete),
);

export const getUploadFiles: any = createSelector(
  getUploadState,
  (state: UploadState) => !!state && state.files,
);
