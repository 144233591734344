<div class="storage-capacity" [title]="loading ? '' : 'storage.used' | translate:{ percentage }">
  <div class="storage-capacity__loading" *ngIf="loading">
    <!--TODO loading skeletor-->
    &nbsp;
  </div>

  <div class="storage-capacity__percentage" *ngIf="!loading && displayRatio">
    {{ used | filesize }} / {{ total | filesize }}
  </div>

  <mat-progress-bar
    class="storage-capacity__gauge"
    color="primary"
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="percentage">
  </mat-progress-bar>
</div>
