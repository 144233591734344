import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';

import { environment } from '@env';
import { CustomRouterStateSerializer } from '@core/custom-state.serializer';
import { httpInterceptors } from '@core/interceptors';
import { initializeLogin } from '@core/store/actions/auth/auth.action';
import { ndtFileManagerStoreEffects } from '@core/store/effects';
import { NdtFileManagerState, ndtFileManagerStoreReducers } from '@core/store/reducers';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { layoutComponents } from './layout';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const FEATURE_REDUCER_TOKEN: InjectionToken<ActionReducerMap<NdtFileManagerState>> =
  new InjectionToken<ActionReducerMap<NdtFileManagerState>>('File Manager Reducers');
export function getReducers(): ActionReducerMap<NdtFileManagerState> {
  return ndtFileManagerStoreReducers;
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [stateSetter] : [];

@NgModule({
  declarations: [AppComponent, ...layoutComponents],
  imports: [
    // Angular modules
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Internal modules
    AppRoutingModule,
    SharedModule.forRoot(),

    // External modules
    CookieModule.withOptions(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Store modules
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    StoreModule.forFeature('ndtFileManager', FEATURE_REDUCER_TOKEN),

    EffectsModule.forRoot([]),
    EffectsModule.forFeature(ndtFileManagerStoreEffects),
    environment.production ? [] : StoreDevtoolsModule.instrument(),

    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      stateKey: 'router',
    }),
  ],
  providers: [
    ...httpInterceptors,
    { provide: FEATURE_REDUCER_TOKEN, useFactory: getReducers },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => (): void => store.dispatch(initializeLogin()),
      deps: [Store],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

const mutateObjectProperty = (
  prop: any,
  newValue: any,
  object: any,
  ignoreValues: any[] = [],
): any =>
  JSON.parse(JSON.stringify(object), (key: string, value: any) =>
    key === prop && !ignoreValues.includes(value) ? newValue : value,
  );

export function stateSetter(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (action.type === 'SET_ROOT_STATE') {
      return mutateObjectProperty('lastLoaded', Date.now(), { ...action.payload }, [0, null]);
    }

    return reducer(state, action);
  };
}
