import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { UserActions } from '@core/store/actions';

export interface ProfileState {
  loading: boolean;
  loaded: boolean;
  profile: Profile;
  error: string;
  isAdmin: boolean;
}

export interface AppsState {
  loading: boolean;
  loaded: boolean;
  applications: Application[];
  error: string;
}

export interface TenantState {
  loading: boolean;
  loaded: boolean;
  tenant: any;
  error: string;
}

export interface UserState {
  profile: ProfileState;
  apps: AppsState;
  tenant: TenantState;
}

export const initialUserState: UserState = {
  profile: {
    loading: false,
    loaded: false,
    profile: null,
    error: null,
    isAdmin: false,
  },
  apps: {
    loading: false,
    loaded: false,
    applications: [],
    error: null,
  },
  tenant: {
    loading: false,
    loaded: false,
    tenant: null,
    error: null,
  },
};

const reducer: ActionReducer<UserState> = createReducer(
  initialUserState,
  on(UserActions.loadProfile, (state: UserState) => ({ ...state, profile: { ...state.profile, loading: true } })),
  on(UserActions.loadProfileSuccess, (state: UserState, { profile }: { profile: Profile }) => ({ ...state, profile: { ...state.profile, profile, loading: false, loaded: true } })),
  on(UserActions.loadProfileFail, (state: UserState, { error }) => ({ ...state, profile: { ...state.profile, loading: false, loaded: false, error } })),

  on(UserActions.loadApplications, (state: UserState) => ({ ...state, apps: { ...state.apps, loading: true } })),
  on(UserActions.loadApplicationsSuccess, (state: UserState, { applications }: { applications: Application[] }) => ({ ...state, apps: { ...state.apps, applications, loading: false, loaded: true } })),
  on(UserActions.loadApplicationsFail, (state: UserState, { error }) => ({ ...state, apps: { ...state.apps, loading: false, loaded: false, error } })),

  on(UserActions.loadTenant, (state: UserState) => ({ ...state, tenant: { ...state.tenant, loading: true } })),
  on(UserActions.loadTenantSuccess, (state: UserState, { tenant }: { tenant: any }) => ({ ...state, tenant: { ...state.tenant, tenant, loading: false, loaded: true } })),
  on(UserActions.loadTenantFail, (state: UserState, { error }) => ({ ...state, tenant: { ...state.tenant, loading: false, loaded: false, error } })),

  on(UserActions.setRole, (state: UserState, { isAdmin }: { isAdmin: boolean }) => ({ ...state, profile: { ...state.profile, isAdmin } })),

  on(UserActions.resetUserState, () => ({ ...initialUserState })),
);

export function userReducer(state: UserState | undefined, action: Action): UserState {
  return reducer(state, action);
}
