import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { select, Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { FileItem } from '@core/models/file-item.type';
import { clearNavigation, loadFolders, navigateToItem } from '@core/store/actions/browse-target/browse-target.action';
import { areFoldersLoading, getCurrentFolder, getFolders, getRouterData, getTargetBreadcrumb } from '@core/store/selectors';

@Component({
  selector: 'ndt-browse-target',
  templateUrl: './browse-target.component.html',
  styleUrls: ['./browse-target.component.scss'],
})
export class BrowseTargetComponent {
  public areFoldersLoading$: Observable<boolean> = this.store.pipe(select(areFoldersLoading));
  public breadcrumb$: Observable<FileItem[]> = this.store.pipe(select(getTargetBreadcrumb));

  public parentFolder$: Observable<FileItem> = this.store.pipe(
    select(getCurrentFolder),
    tap((item: FileItem) => {
      this.parentFolderId = item?.id;
    }),
  );

  public folders$: Observable<FileItem[]> = this.store.pipe(
    select(getFolders),
    map((items: FileItem[]) => items.filter(i => !this.itemsId.includes(i.id))),
  );

  public base$: Observable<any> = this.store.pipe(
    select(getRouterData),
    map((data: { base: string }) => data?.base),
    tap((name: string) => {
      this.base = name ? { name, id: '' } : null;
      this.selected = this.selected ?? this.base;
    }),
  );

  public base: Partial<FileItem>;
  public selected: Partial<FileItem>;
  public parentFolderId: string;

  public get action(): string {
    return this.data.action;
  }

  public get items(): FileItem[] {
    return this.data.items;
  }

  public get current(): string {
    if (!this.items?.length) {
      return '';
    }

    return this.items.length > 1
      ? 'browseTarget.xElements'
      : this.items[0].name;
  }

  public get itemsId(): string[] {
    return this.items.map(i => i.id);
  }

  public get isMoveDisabled(): boolean {
    const isMove: boolean = this.action.endsWith('move');
    const isAlreadyInFolder: boolean = this.selected?.id === this.parentFolderId;
    const isAlreadyInRoot: boolean = this.selected && !this.selected.id && !this.parentFolderId;

    return isMove && (!this.selected || isAlreadyInFolder || isAlreadyInRoot);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { action: string, items: FileItem[] },
    private readonly store: Store,
  ) {
    this.store.dispatch(loadFolders({ parent: null }));
  }

  public goTo(item: Partial<FileItem>): void {
    this.store.dispatch(navigateToItem({ folder: item }));
    this.store.dispatch(loadFolders({ parent: item }));
    this.setSelected(item);
  }

  public goToBase(): void {
    this.store.dispatch(clearNavigation());
    this.store.dispatch(loadFolders({ parent: null }));
    this.setSelected(this.base);
  }

  public onSelected(event: MatSelectionListChange): void {
    this.setSelected(event.options[0].value);
  }

  public setSelected(folder: Partial<FileItem>): void {
    this.selected = folder;
  }

  public trackById(idx: number, item: { id: string }): string {
    return item.id;
  }
}
