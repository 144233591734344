import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { Storage } from '@core/models/storage.type';
import { StorageActions } from '@core/store/actions';

export interface StorageState {
  used: number;
  total: number;
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialStorageState: StorageState = {
  used: 0,
  total: 0,
  loading: false,
  loaded: false,
  error: null,
};

const reducer: ActionReducer<StorageState> = createReducer(
  initialStorageState,
  on(StorageActions.loadStorage, (state: StorageState) => ({ ...state, loading: true })),
  on(StorageActions.loadStorageSuccess, (state: StorageState, { total, used }: Storage) => ({ ...state, loading: false, loaded: true, total, used })),
  on(StorageActions.loadStorageFail, (state: StorageState, { error }: { error: string }) => ({ ...state, loading: false, loaded: false, error })),

  on(StorageActions.resetStorageState, () => ({ ...initialStorageState })),
);

export function storageReducer(state: StorageState | undefined, action: Action): StorageState {
  return reducer(state, action);
}
