import { DateFormatPipe } from './date-format/date-format.pipe';
import { FileSizePipe } from './filesize/filesize.pipe';
import { FloorPipe } from './floor/floor.pipe';
import { RemainingTimePipe } from './remaining-time/remaining-time.pipe';

export const sharedPipes: any[] = [
  FileSizePipe,
  DateFormatPipe,
  RemainingTimePipe,
  FloorPipe,
];

export * from './filesize/filesize.pipe';
export * from './date-format/date-format.pipe';
export * from './remaining-time/remaining-time.pipe';
export * from './floor/floor.pipe';
