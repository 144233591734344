<ng-container *ngIf="base$ | async"></ng-container>

<div class="browse-target">
  <h2 matDialogTitle class="browse-target__title">{{ action | translate }} "{{ current | translate:{ x: items.length } }}"</h2>

  <mat-dialog-content class="mat-typography browse-target__content">
    <p *ngIf="(parentFolder$ | async) as parent" class="browse-target__parent">
      {{ 'browseTarget.currentParent' | translate }}
      <span class="browse-target__folder-name">{{ parent.name }}</span>
    </p>

    <mat-progress-bar
      *ngIf="(areFoldersLoading$ | async); else divider"
      class="browse-target__progress"
      mode="indeterminate"
    ></mat-progress-bar>

    <ng-template #divider>
      <mat-divider class="browse-target__divider"></mat-divider>
    </ng-template>

    <evc-breadcrumb
      size="xs"
      class="browse-target__breadcrumb"
      [items]="breadcrumb$ | async"
      [base]="base?.name | translate"
      (baseSelected)="goToBase()"
      (itemSelected)="goTo($event)"
    ></evc-breadcrumb>

    <div class="browse-target__list">
      <mat-selection-list [multiple]="false" (selectionChange)="onSelected($event)">
        <mat-list-option
          *ngFor="let folder of (folders$ | async); trackBy:trackById"
          [value]="folder"
          (dblclick)="goTo(folder)"
        >
          <mat-icon matListItemIcon>folder</mat-icon>
          <div matListItemTitle>{{ folder.name }}</div>
        </mat-list-option>
      </mat-selection-list>
    </div>

    <p class="browse-target__selected">
      <ng-container *ngIf="!selected">{{ 'browseTarget.target.empty' | translate }}</ng-container>
      <ng-container *ngIf="selected">
        {{ 'browseTarget.target.label' | translate }}
        <span class="browse-target__folder-name">{{ selected.name | translate }}</span>
      </ng-container>
    </p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'browseTarget.actions.cancel' | translate }}</button>
    <button
      mat-button
      color="primary"
      [disabled]="isMoveDisabled"
      [mat-dialog-close]="{current: items, selected}"
      cdkFocusInitial
    >{{ action | translate }}</button>
  </mat-dialog-actions>
</div>
