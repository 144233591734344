import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DataEnum } from '@core/models/data.enum';
import { FileItem } from '@core/models/file-item.type';

@Component({
  selector: 'ndt-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss'],
})
export class ActionsBarComponent {
  @Output() public readonly itemsCopied: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsMovedTo: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsDownloaded: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsShared: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsRestored: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsPermanentlyDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

  @Input() public items: FileItem[] = [];
  @Input() public isTrash: boolean = false;
  @Input() public isAdmin: boolean = false;

  public onCopy(items: FileItem[]): void {
    this.itemsCopied.emit(items);
  }

  public onMoveTo(items: FileItem[]): void {
    this.itemsMovedTo.emit(items);
  }

  public onDownload(items: FileItem[]): void {
    this.itemsDownloaded.emit(items);
  }

  public onShare(items: FileItem[]): void {
    this.itemsShared.emit(items);
  }

  public onDelete(items: FileItem[]): void {
    this.itemsDeleted.emit(items);
  }

  public onRestore(items: FileItem[]): void {
    this.itemsRestored.emit(items);
  }

  public onPermanentlyDelete(items: FileItem[]): void {
    this.itemsPermanentlyDeleted.emit(items);
  }

  public anyFolderSelected(): boolean {
    return this.items.some(item => item.type === DataEnum.FOLDER);
  }

  public anyItemLocked(): boolean {
    return this.items.some(item => item.isLocked);
  }
}
