import { createSelector } from '@ngrx/store';

import { BreadcrumbState, getFileManagerState, NdtFileManagerState } from '@core/store/reducers';

/**
 * General Breadcrumb State selector
 */
export const getBreadcrumbState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.breadcrumb,
);

export const getBreadcrumb: any = createSelector(
  getBreadcrumbState,
  (state: BreadcrumbState) => !!state && state.value,
);

export const isBreadcrumbLoading: any = createSelector(
  getBreadcrumbState,
  (state: BreadcrumbState) => !!state && state.loading,
);
