import { createSelector } from '@ngrx/store';

import { BrowseTargetState, getFileManagerState, NdtFileManagerState } from '@core/store/reducers';

/**
 * General BrowseTarget State selector
 */
export const getBrowseTargetState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.browseTarget,
);

export const getTargetBreadcrumb: any = createSelector(
  getBrowseTargetState,
  (state: BrowseTargetState) => !!state && state.breadcrumb,
);

export const getFolders: any = createSelector(
  getBrowseTargetState,
  (state: BrowseTargetState) => !!state && state.folders,
);

export const areFoldersLoading: any = createSelector(
  getBrowseTargetState,
  (state: BrowseTargetState) => !!state && state.loading,
);

export const areFoldersLoaded: any = createSelector(
  getBrowseTargetState,
  (state: BrowseTargetState) => !!state && state.loaded,
);
