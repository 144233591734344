import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Moment } from 'moment';
import { concat, delay, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FileItem } from '@core/models/file-item.type';
import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method.enum';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class TransferService {
  private readonly transferPrefix: string = `${this.configService.apiUrl}/transfer`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public downloadUrl(id: string): Observable<{ fileId: string, downloadUrl: string, startsOn: Moment, expiresOn: Moment }> {
    return this.httpService.perform(HttpMethod.get, `${this.transferPrefix}/download-url/${id}`);
  }

  public downloadItems(items: FileItem[]): Observable<{ item: FileItem, percentage: number, done: boolean }> {
    return concat(...items.map((item: FileItem) =>
      this.httpService.perform(HttpMethod.get, `${this.transferPrefix}/download/${item.id}`, null, null, { responseType: 'blob', reportProgress: true, observe: 'events' }).pipe(
        map(({ type, ...response }) => {
          if (type === HttpEventType.DownloadProgress) {
            const percentage: number = Math.round((response.loaded * 100) / response.total);

            return { item, percentage, done: false };
          } else if (type === HttpEventType.Response) {
            const blob: Blob = response.body;
            saveAs(blob, item.name);

            return { item, percentage: 100, done: true };
          }
        }),
        catchError((error: string) => throwError(() => ({ error, item }))),
      ),
    ));
  }

  public downloadItemsByUrl(items: FileItem[]): Observable<void> {
    return concat(...items.map((item: FileItem, index: number) => this.downloadUrl(item.id).pipe(
      delay(index ? 500 : 0),
      map((response: { fileId: string, downloadUrl: string, startsOn: Moment, expiresOn: Moment }) => {
        this.downloadOnBrowser(response.downloadUrl, item.name);
      }),
    )));
  }

  private downloadOnBrowser(url: string, name: string): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = encodeURIComponent(name);
    link.click();
  }
}
