import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type ConfirmData = {
  title: string,
  content: string,
  buttons: { cancel: string, confirm: string },
};

@Component({
  selector: 'ndt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public get data(): ConfirmData & any {
    return this.dialogData;
  }

  public get title(): string {
    return this.data.title;
  }

  public get content(): string {
    return this.data.content;
  }

  public get buttons(): { cancel: string, confirm: string } {
    return this.data.buttons;
  }

  public get cancel(): string {
    return this.buttons?.cancel || 'toast.cancel';
  }

  public get confirm(): string {
    return this.buttons?.confirm || 'toast.ok';
  }

  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: ConfirmData,
  ) { }

  public onCancelClick(): void {
    this.dialogRef.close();
  }
}
