import { Injectable } from '@angular/core';

import { environment } from '@env';
import { ApplicationEnum } from '@core/models/application.enum';
import { Application } from '@core/models/application.type';
import { DataEnum } from '@core/models/data.enum';
import { FileItem } from '@core/models/file-item.type';

const { api: { osc } } = environment;

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService {
  private oscPrefix: string = osc;

  public areOnlyFiles(items: FileItem[]): boolean {
    return !!items.length && items.every(i => i.type === DataEnum.FILE);
  }

  public isOneSelected(items: FileItem[]): boolean {
    return items.length === 1;
  }

  public isOneFileSelected(items: FileItem[]): boolean {
    return this.isOneSelected(items) && items[0].type === DataEnum.FILE;
  }

  public isLicenseExpired(app: Application): boolean {
    return !app.exclude && !app.userHasActiveValidLicense;
  }

  public getAppsForFileSelected(items: FileItem[], applications: Application[]): Application[] {
    if (!items || !this.isOneSelected(items) || !this.areOnlyFiles(items)) {
      return [];
    }

    return [
      ...this.getApplicationsByMimetype(applications, items[0].latestVersion.contentType),
      { code: ApplicationEnum.ZIP, name: 'list.menu.zip', icon: 'file_download_outlined', exclude: true } as Application,
    ];
  }

  public openLink(link: string): void {
    if (!link) {
      return;
    }

    window.open(`${this.oscPrefix}/${link}`, '_blank');
  }

  private getApplicationsByMimetype(applications: Application[], mimetype: string): Application[] {
    return applications.filter(app => app.supportedFileMimeTypes?.includes(mimetype));
  }
}
