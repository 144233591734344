import { Component, Input } from '@angular/core';

@Component({
  selector: 'ndt-storage-capacity',
  templateUrl: './storage-capacity.component.html',
  styleUrls: ['./storage-capacity.component.scss'],
})
export class StorageCapacityComponent {
  @Input() public loading: boolean;
  @Input() public total: number;
  @Input() public used: number;

  public get displayRatio(): boolean {
    return !isNaN(this.used) && !isNaN(this.total);
  }

  public get percentage(): number {
    return (this.used * 100) / this.total;
  }
}
