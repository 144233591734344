import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { Tenant } from '@core/models/tenant.type';
import { UserService } from '@core/services/user/user.service';
import { ToastActions, UserActions } from '@core/store/actions';
import { ProfileService } from '@shared/services';

@Injectable()
export class UserEffects {
  public loadProfile$: any = createEffect((): any => this.actions$.pipe(
    ofType(UserActions.loadProfile),
    switchMap(() =>
      this.userService.loadProfile().pipe(
        map((profile: Profile) => UserActions.loadProfileSuccess({ profile })),
        catchError((error: string) => of(UserActions.loadProfileFail({ error }))),
      ),
    ),
  ));

  public loadProfileSuccess$: any = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadProfileSuccess),
    switchMap(() => [
      UserActions.loadApplications(),
      UserActions.loadTenant(),
      UserActions.setRole({ isAdmin: this.profileService.isUserAdmin() }),
    ]),
  ));

  public loadProfileFail$: any = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadProfileFail),
    switchMap(() => [
      ToastActions.addToastError({ message: 'osc.error.profile' }),
    ]),
  ));

  public loadApplications$: any = createEffect((): any => this.actions$.pipe(
    ofType(UserActions.loadApplications),
    switchMap(() =>
      this.userService.loadApplications().pipe(
        map((applications: Application[]) => UserActions.loadApplicationsSuccess({ applications })),
        catchError((error: string) => of(UserActions.loadApplicationsFail({ error }))),
      ),
    ),
  ));

  public loadApplicationsFail$: any = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadApplicationsFail),
    switchMap(() => [
      ToastActions.addToastError({ message: 'osc.error.applications' }),
    ]),
  ));

  public loadTenant$: any = createEffect((): any => this.actions$.pipe(
    ofType(UserActions.loadTenant),
    switchMap(() =>
      this.userService.loadTenant().pipe(
        map((tenant: Tenant) => UserActions.loadTenantSuccess({ tenant })),
        catchError((error: string) => of(UserActions.loadTenantFail({ error }))),
      ),
    ),
  ));

  public loadTenantFail$: any = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadTenantFail),
    switchMap(() => [
      ToastActions.addToastError({ message: 'osc.error.tenant' }),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly userService: UserService,
    private readonly profileService: ProfileService,
  ) { }
}
