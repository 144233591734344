import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';
import { BrowseTargetActions } from '@core/store/actions';

export interface BrowseTargetState {
  breadcrumb: FileItem[];
  folders: FileItem[];
  loading: boolean;
  loaded: boolean;
}

export const initialBrowseTargetState: BrowseTargetState = {
  breadcrumb: [],
  folders: [],
  loading: false,
  loaded: false,
};

const reducer: ActionReducer<BrowseTargetState> = createReducer(
  initialBrowseTargetState,
  on(BrowseTargetActions.navigateToItem, (state: BrowseTargetState, { folder }: { folder: FileItem }) => {
    const folderIndex: number = state.breadcrumb.findIndex((f) => f.id === folder.id);
    const breadcrumb: FileItem[] = folderIndex === -1
      ? [...state.breadcrumb, folder] // S'il n'existe pas dans la liste, on l'ajoute à la fin
      : state.breadcrumb.slice(0, folderIndex + 1); // Sinon, on cut tout ce qu'il y a apres l'index trouvé (on a navigué par le breadcrumb du browseTarget)

    return { ...state, breadcrumb };
  }),
  on(BrowseTargetActions.clearNavigation, (state: BrowseTargetState) => ({ ...state, breadcrumb: [] })),

  on(BrowseTargetActions.loadFolders, (state: BrowseTargetState) => ({ ...state, loading: true })),
  on(BrowseTargetActions.loadFoldersSuccess, (state: BrowseTargetState, { list }: { list: FileItem[] }) => ({ ...state, loading: false, loaded: true, folders: list })),
  on(BrowseTargetActions.loadFoldersFail, (state: BrowseTargetState) => ({ ...state, loading: false, loaded: false })),

  on(BrowseTargetActions.resetBrowseTargetState, () => ({ ...initialBrowseTargetState })),
);

export function browseTargetReducer(state: BrowseTargetState | undefined, action: Action): BrowseTargetState {
  return reducer(state, action);
}
