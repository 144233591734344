import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { filter, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FileItem } from '@core/models/file-item.type';
import { FileService } from '@core/services/file/file.service';
import { BreadcrumbActions } from '@core/store/actions';
import { getRouterParams } from '@core/store/selectors';

@Injectable()
export class BreadcrumbEffects {
  public loadBreadcrumb$: any = createEffect((): any => this.actions$.pipe(
    ofType(BreadcrumbActions.loadBreadcrumb),
    withLatestFrom(this.store.pipe(select(getRouterParams))),
    filter(([, params]: [{ id?: string }, Params]) => !!params.id),
    switchMap(([payload, params]: [{ id?: string }, Params]) =>
      this.fileService.loadFolder(payload.id || params.id).pipe(
        map((folder: FileItem) => BreadcrumbActions.addParent({ folder, last: !folder.parentId })),
        catchError((error: string) => of(BreadcrumbActions.loadBreadcrumbFail({ error }))),
      ),
    ),
    tap(({ folder }: { folder: FileItem }) => {
      if (folder?.parentId) {
        this.store.dispatch(BreadcrumbActions.loadBreadcrumb({ id: folder.parentId }));
      }
    }),
    catchError((error: string) => of(BreadcrumbActions.loadBreadcrumbFail({ error }))),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly fileService: FileService,
    private readonly store: Store,
  ) { }
}
