import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BackendError } from '@core/models/error/backend-error.type';
import { FileItem } from '@core/models/file-item.type';
import { FileService } from '@core/services/file/file.service';
import { BrowseTargetActions, ToastActions } from '@core/store/actions';

@Injectable()
export class BrowseTargetEffects {
  public loadFolders$: any = createEffect((): any => this.actions$.pipe(
    ofType(BrowseTargetActions.loadFolders),
    switchMap(({ parent }: { parent: FileItem }) => this.fileService.loadOnlyDirectory(parent?.id).pipe(
      map(({ items }: { items: FileItem[] }) => BrowseTargetActions.loadFoldersSuccess({ list: items })),
      catchError((error: BackendError) => of(BrowseTargetActions.loadFoldersFail({ error }))),
    )),
  ));

  public loadFoldersFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(BrowseTargetActions.loadFoldersFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'toast.error',
      }),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly fileService: FileService,
  ) { }
}
