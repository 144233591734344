import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@core/services/auth/auth.service';
import { AuthActions, FileActions, UserActions } from '@core/store/actions';

@Injectable()
export class AuthEffects {
  public initializeLogin$: any = createEffect((): any => this.actions$.pipe(
    ofType(AuthActions.initializeLogin),
    switchMap(() =>
      this.authService.isUserLogged().pipe(
        map((isAuthenticated: boolean) => isAuthenticated ? UserActions.loadProfile() : AuthActions.login()),
        catchError(() => of(AuthActions.login())),
      ),
    ),
  ));

  public login$: any = createEffect((): any => this.actions$.pipe(
    ofType(AuthActions.login),
    tap(() => this.authService.authenticate()),
  ), { dispatch: false });

  public logout$: any = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(() => this.authService.logout()),
    switchMap(() => [
      FileActions.resetFileState(),
      UserActions.resetUserState(),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
  ) { }
}
