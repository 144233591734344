import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import moment from 'moment';
import { filter, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { CellarData } from '@core/models/cellar-data.type';
import { CellarActions } from '@core/store/actions';
import { ConfigService } from '@shared/services';

const STORAGE_KEY: string = 'cellar';

@Injectable()
export class CellarEffects {
  public loadPersistant$: any = createEffect((): any => this.actions$.pipe(
    ofType(CellarActions.loadPersistantCellar),
    map(() => {
      const storageCellar: string = this.configService.getValue(STORAGE_KEY);
      const cellar: { [key: string]: { value: CellarData, expires: string | undefined } } = storageCellar ? JSON.parse(storageCellar) : {};

      return Object.keys(cellar).reduce((all: { [key: string]: CellarData }, current: string) => {
        const { value, expires }: { value: CellarData, expires: string | undefined } = cellar[current];
        const isValid: boolean = !expires || (moment() < moment(expires));

        return isValid ? { ...all, [current]: value } : all;
      }, {});
    }),
    switchMap((toSave: { [key: string]: CellarData }) => [CellarActions.setData({ data: { ...toSave } })]),
  ));

  public setData$: any = createEffect((): any => this.actions$.pipe(
    ofType(CellarActions.setData),
    filter(({ persistant }: { data: { [key: string]: CellarData }, persistant?: boolean }) => !!persistant),
    tap(({ data }: { data: { [key: string]: CellarData } }) => Object.keys(data).forEach((key: string) => {
      const value: CellarData = data[key];
      const storedValue: { value: CellarData, expires: string | undefined } = {
        value: data[key],
        expires: value.validity
          ? moment().startOf(value.validity).add(1, value.validity).toISOString()
          : undefined,
      };

      this.configService.addValue(STORAGE_KEY, key, storedValue);
    })),
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly configService: ConfigService,
  ) { }
}
