import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ToastType } from '@core/models/toast/toast.enum';
import { ExtraParams, Toast } from '@core/models/toast/toast.model';
import * as ToastActions from '@core/store/actions/toast/toast.action';
import { ToastService } from '@shared/services';

@Injectable()
export class ToastEffects {
  public addSuccessToast$: any = createEffect(() => this.actions$.pipe(
    ofType(ToastActions.addToastSuccess),
    tap(({ message, params, extra }: { message: string, params?: { [key: string]: any }, extra: ExtraParams }) => this.toastService.show(new Toast(message, ToastType.success, { params, extra }))),
  ), { dispatch: false });

  public addErrorToast$: any = createEffect(() => this.actions$.pipe(
    ofType(ToastActions.addToastError),
    tap(({ message, params, extra }: { message: string, params?: { [key: string]: any }, extra: ExtraParams }) => this.toastService.show(new Toast(message, ToastType.error, { params, extra }))),
  ), { dispatch: false });

  public addWarningToast$: any = createEffect(() => this.actions$.pipe(
    ofType(ToastActions.addToastWarning),
    tap(({ message, params, extra }: { message: string, params?: { [key: string]: any }, extra: ExtraParams }) => this.toastService.show(new Toast(message, ToastType.warning, { params, extra }))),
  ), { dispatch: false });

  public addInfoToast$: any = createEffect(() => this.actions$.pipe(
    ofType(ToastActions.addToastInfo),
    tap(({ message, params, extra }: { message: string, params?: { [key: string]: any }, extra: ExtraParams }) => this.toastService.show(new Toast(message, ToastType.info, { params, extra }))),
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
  ) { }
}
