import { createAction, props } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';

export const ADD_ITEM: string = '[Breadcrumb] Add item';
export const ADD_PARENT: string = '[Breadcrumb] Add parent';

export const LOAD_BREADCRUMB: string = '[Breadcrumb] Load breadcrumb';
export const LOAD_BREADCRUMB_FAIL: string = '[Breadcrumb] Load breadcrumb fail';

export const RESET_BREADCRUMB_STATE: string = '[Breadcrumb] Reset state';

export const addItem: any = createAction(
  ADD_ITEM,
  props<{ folder: FileItem }>(),
);

export const addParent: any = createAction(
  ADD_PARENT,
  props<{ folder: FileItem, last?: boolean }>(),
);

export const loadBreadcrumb: any = createAction(
  LOAD_BREADCRUMB,
  props<{ id?: string }>(),
);

export const loadBreadcrumbFail: any = createAction(
  LOAD_BREADCRUMB_FAIL,
  props<{ error: string }>(),
);

export const resetBreadcrumbState: any = createAction(
  RESET_BREADCRUMB_STATE,
);
