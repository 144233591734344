export enum ApplicationEnum {
  WINDVIEW = 'WindViewApp',
  AEROVIEW = 'AeroViewApp',
  ZIP = 'zip',
  WELDSIGHT = 'WeldSight',
}

export type AvailableApp = {
  key: ApplicationEnum,
  mimeTypes: string[],
  openUrl?: (root: string, url: string, tenantId: string) => string,
  excluded?: boolean,
};
