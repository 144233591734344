<div class="available-apps">
  <h2 mat-dialog-title class="available-apps__title">{{ 'list.apps.title' | translate }}</h2>

  <div mat-dialog-content class="available-apps__content">
    <p>{{ 'list.apps.description' | translate }}</p>

    <div class="available-apps__apps">
      <mat-selection-list
        [multiple]="false"
        [hideSingleSelectionIndicator]="true"
        (selectionChange)="onOpen(selected, $event)">
        <mat-list-option
          *ngFor="let app of availableApplications; trackBy:trackById"
          [value]="app"
          [disabled]="isLicenseExpired(app)"
        >
          <img
            *ngIf="app.launchIconUrl"
            matListItemIcon
            [src]="app.launchIconUrl"
            [alt]="app.name | translate"
          />

          <mat-icon
            *ngIf="app.icon"
            matListItemIcon
            class="material-icons-outlined"
          >{{ app.icon }}</mat-icon>

          <mat-icon
            *ngIf="!app.icon && !app.launchIconUrl"
            matListItemIcon
            class="material-icons-outlined"
          >handyman</mat-icon>

          <div matListItemTitle  class="available-apps__app-container">
            <span class="available-apps__app-title">{{ app.name | translate }}</span>

            <mat-icon
              *ngIf="isLicenseExpired(app)"
              class="material-icons-outlined"
            >report_problem</mat-icon>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div mat-dialog-actions class="available-apps__actions">
    <button
      mat-flat-button
      (click)="openLink('marketplace/store')"
    >
      <mat-icon>add</mat-icon>
      <span>{{ 'list.menu.moreApps' | translate }}</span>
    </button>
  </div>
</div>
