import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { logout } from '@core/store/actions/auth/auth.action';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private excludedUrlsRegex: RegExp[];
  private excludedUrls: string[] | RegExp[] = ['.svg', '.json'];

  constructor(
    private readonly store: Store,
    private readonly cookieService: CookieService,
    private readonly configService: ConfigService,
  ) {
    this.excludedUrlsRegex = this.excludedUrls.map((urlPattern: string | RegExp) => new RegExp(urlPattern, 'i'));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passThrough: boolean = !!this.excludedUrlsRegex.find((regex: RegExp) => regex.test(req.url));

    if (passThrough) {
      return next.handle(req);
    }

    const bearer: string = this.cookieService.get(this.configService.tokenKey);

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${bearer}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      },
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (!this.configService.isLocalMode && error instanceof HttpErrorResponse && error.status === 401) {
          this.store.dispatch(logout());
        }

        return throwError(() => error);
      }),
    );
  }
}
