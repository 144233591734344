import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { DataSort } from '@core/models/data-sort.type';
import { Datatype } from '@core/models/datatype.enum';
import { FileItem } from '@core/models/file-item.type';
import { Metadata } from '@core/models/nde-item-metadata.type';
import { FileActions, UploadActions } from '@core/store/actions';

export interface SelectedItemMetadataState {
  metadata: Metadata,
  loading: boolean,
  error: string,
}

export interface FileState {
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  error: string;
  search: string;
  list: FileItem[];
  currentFolder: FileItem;
  selectedItems: FileItem[];
  page: number;
  sort: DataSort;
  newIdToFind: string;
  selectedItemMetadata: SelectedItemMetadataState;
}

export const initialFileState: FileState = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
  search: null,
  list: [],
  currentFolder: null,
  selectedItems: [],
  page: 0,
  sort: null,
  newIdToFind: null,
  selectedItemMetadata: {
    metadata: null,
    loading: false,
    error: null,
  },
};

const reducer: ActionReducer<FileState> = createReducer(
  initialFileState,
  on(FileActions.loadData, (state: FileState) => ({ ...state, loading: true })),
  on(FileActions.loadDataSuccess, (state: FileState, { data, page, count, sort, idToFind }: { data: FileItem[], page: number, count: number, sort: DataSort, idToFind?: string }) => {
    const foundItem: FileItem = data.find(item => item.id === idToFind);

    return {
      ...state,
      loading: false,
      loaded: true,
      list: page > 0 ? [...state.list, ...data] : data,
      page: !!count ? page : state.page,
      sort,
      selectedItems: idToFind && foundItem ? [foundItem] : [...state.selectedItems],
      newIdToFind: foundItem ? null : idToFind,
    };
  }),
  on(FileActions.loadDataFail, (state: FileState, { error }: { error: string }) => ({ ...state, loading: false, loaded: false, error })),

  on(FileActions.loadFolder, (state: FileState) => ({ ...state, loading: true })),
  on(FileActions.loadFolderSuccess, (state: FileState, { folder }: { folder: FileItem }) => ({ ...state, loading: false, loaded: true, currentFolder: folder, selectedItems: [] })),
  on(FileActions.loadFolderFail, (state: FileState, { error }: { error: string }) => ({ ...state, loading: false, loaded: false, error })),

  on(FileActions.setSelectedItems, (state: FileState, { items }: { items: FileItem[] }) => ({ ...state, selectedItems: items })),
  on(FileActions.setCurrentFolder, (state: FileState, { folder }: { folder: FileItem }) => ({ ...state, currentFolder: folder })),
  on(FileActions.setNewIdToFind, (state: FileState, { idToFind }: { idToFind: string }) => ({ ...state, newIdToFind: idToFind, list: [] })),

  on(FileActions.createFolder, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.createFolderSuccess, (state: FileState) => ({ ...state, saving: false, saved: true })),
  on(FileActions.createFolderFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.renameItem, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.renameItemSuccess, (state: FileState, { item }: { item: FileItem }) => {
    const list: FileItem[] = state.list.map((f) => f.id === item.id ? item : f);
    const selectedItems: FileItem[] = state.selectedItems.map((f) => f.id === item.id ? item : f);
    const currentFolder: FileItem = state.currentFolder?.id === item.id ? item : state.currentFolder;

    return ({ ...state, saving: false, saved: true, list, currentFolder, selectedItems });
  }),
  on(FileActions.renameItemFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.deleteItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.deleteItemsSuccess, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToRemove: string[] = items.map(i => i.id);
    const list: FileItem[] = state.list.filter((f) => !idsToRemove.includes(f.id));
    const selectedItems: FileItem[] = state.selectedItems.filter((f) => !idsToRemove.includes(f.id));

    return ({ ...state, saving: false, saved: true, list, selectedItems });
  }),
  on(FileActions.deleteItemsFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.deleteAllTrash, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.deleteAllTrashSuccess, (state: FileState) => ({ ...state, saving: false, saved: true, list: [], selectedItems: [] })),
  on(FileActions.deleteAllTrashFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.restoreItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.restoreItemsSuccess, (state: FileState, { items, location }: { items: FileItem[], location: string }) => {
    const idsToRestore: string[] = items.map(i => i.id);
    const selectedItems: FileItem[] = state.selectedItems.filter((f) => !idsToRestore.includes(f.id));
    let list: FileItem[] = state.list;

    if (location === Datatype.TRASH) {
      // Remove items from trash list
      list = state.list.filter((f) => !idsToRestore.includes(f.id));
    } else if (state.currentFolder?.id === items[0].parentId || !state.currentFolder?.id && !items[0].parentId) {
      // Currently in original location, add items to list
      list = [...state.list, ...items];
    }

    return ({ ...state, saving: false, saved: true, list, selectedItems });
  }),
  on(FileActions.restoreItemsFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.copyItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.copyItemsSuccess, (state: FileState, { items, parentId }: { items: FileItem[], parentId?: string }) => {
    let list: FileItem[] = [...state.list];

    // if the current folder is the parent id, we update the list to include the newly copied file/files
    if (state.currentFolder?.id === parentId) {
      list = list.concat(items);
    }

    return { ...state, saving: false, saved: true, list };
  }),
  on(FileActions.copyItemsFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.moveItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.moveItemsSuccess, (state: FileState, { items }: { items: FileItem[] }) => {
    let list: FileItem[] = [...state.list];
    let selectedItems: FileItem[] = [...state.selectedItems];

    if (list.find(i => items[0].id === i.id)) {
      // If the first item is on le list: it means that we are in the list where we cut, so we remove items from list
      const idsMoved: string[] = items.map(i => i.id);
      list = list.filter((f) => !idsMoved.includes(f.id));
      selectedItems = selectedItems.filter(i => !idsMoved.includes(i.id));
    } else {
      // Otherwise, we are in the target list to move items, so we add it
      list = list.concat(items);
    }

    return { ...state, saving: false, saved: true, list, selectedItems };
  }),
  on(FileActions.moveItemsFail, (state: FileState, { error }: { error: string }) => ({ ...state, saving: false, saved: false, error })),

  on(FileActions.loadFromSearch, (state: FileState) => ({ ...state, loading: true })),
  on(FileActions.setSearch, (state: FileState, { search }: { search: string }) => ({ ...state, search, selectedItems: [] })),
  on(FileActions.clearSearch, (state: FileState) => ({ ...state, search: null })),

  on(FileActions.downloadItems, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToStart: string[] = items.map(item => item.id);

    return {
      ...state,
      list: state.list.map(originalItem => idsToStart.includes(originalItem.id)
        ? ({ ...originalItem, progress: 0, downloadError: undefined })
        : originalItem),
    };
  }),
  on(FileActions.downloadItemProgress, (state: FileState, { item, percentage }: { item: FileItem, percentage: number }) => ({
    ...state,
    list: state.list.map(originalItem => originalItem.id === item.id
      ? ({ ...originalItem, progress: percentage })
      : originalItem),
  })),
  on(FileActions.downloadItemSuccess, (state: FileState, { item }: { item: FileItem }) => ({
    ...state,
    list: state.list.map(originalItem => originalItem.id === item.id
      ? ({ ...originalItem, progress: undefined })
      : originalItem),
  })),
  on(FileActions.downloadItemFail, (state: FileState, { item }: { item: FileItem }) => ({
    ...state,
    list: state.list.map(originalItem => originalItem.id === item?.id
      ? ({ ...originalItem, downloadError: true })
      : originalItem),
  })),

  on(FileActions.downloadItemsByUrl, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToStart: string[] = items.map(item => item.id);

    return {
      ...state,
      list: state.list.map(originalItem => idsToStart.includes(originalItem.id)
        ? ({ ...originalItem, progress: 0, downloadError: undefined })
        : originalItem),
    };
  }),
  on(FileActions.downloadItemsByUrlSuccess, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToStart: string[] = items.map(item => item.id);

    return {
      ...state,
      list: state.list.map(originalItem => idsToStart.includes(originalItem.id)
        ? ({ ...originalItem, progress: undefined })
        : originalItem),
    };
  }),
  on(FileActions.downloadItemsByUrlFail, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToStart: string[] = items.map(item => item.id);

    return {
      ...state,
      list: state.list.map(originalItem => idsToStart.includes(originalItem.id)
        ? ({ ...originalItem, downloadError: true })
        : originalItem),
    };
  }),

  on(FileActions.lockItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.lockItemsSuccess, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToLock: string[] = items.map(item => item.id);

    return {
      ...state,
      saving: false,
      saved: true,
      list: state.list.map(originalItem => idsToLock.includes(originalItem.id)
        ? ({ ...originalItem, isLocked: true, lockedBy: items[0].lockedBy })
        : originalItem),
    };
  }),
  on(FileActions.lockItemsFail, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToLock: string[] = items.map(item => item.id);

    return {
      ...state,
      saving: false,
      saved: false,
      list: state.list.map(originalItem => idsToLock.includes(originalItem.id)
        ? ({ ...originalItem, isLocked: false })
        : originalItem),
    };
  }),

  on(FileActions.unlockItems, (state: FileState) => ({ ...state, saving: true })),
  on(FileActions.unlockItemsSuccess, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToUnlock: string[] = items.map(item => item.id);

    return {
      ...state,
      saving: false,
      saved: true,
      list: state.list.map(originalItem => idsToUnlock.includes(originalItem.id)
        ? ({ ...originalItem, isLocked: false })
        : originalItem),
    };
  }),
  on(FileActions.unlockItemsFail, (state: FileState, { items }: { items: FileItem[] }) => {
    const idsToUnlock: string[] = items.map(item => item.id);

    return {
      ...state,
      saving: false,
      saved: false,
      list: state.list.map(originalItem => idsToUnlock.includes(originalItem.id)
        ? ({ ...originalItem, isLocked: true })
        : originalItem),
    };
  }),

  on(FileActions.loadItemMetadata, (state: FileState) => ({
    ...state,
    selectedItemMetadata: {
      ...state.selectedItemMetadata,
      loading: true,
      error: null,
    },
  })),
  on(FileActions.loadItemMetadataSuccess, (state: FileState, { metadata }: { metadata: Metadata }) => ({
    ...state,
    selectedItemMetadata: {
      ...state.selectedItemMetadata,
      loading: false,
      metadata,
    },
  })),
  on(FileActions.loadItemMetadataFail, (state: FileState, { error }: { error: string }) => ({
    ...state,
    selectedItemMetadata: {
      ...state.selectedItemMetadata,
      loading: false,
      error,
    },
  })),
  on(FileActions.clearMetadata, (state: FileState) => ({
    ...state,
    selectedItemMetadata: {
      ...state.selectedItemMetadata,
      loading: false,
      metadata: {} as Metadata,
    },
  })),

  on(FileActions.resetFileState, () => ({ ...initialFileState })),

  // Other actions
  on(UploadActions.uploadFileSuccess, (state: FileState, { file }: { file: FileItem }) => {
    const list: FileItem[] = state.list.find(f => f.id === file.id)
      ? state.list.map(f => f.id === file.id ? { ...file } : f)
      : [...state.list, { ...file }];

    return { ...state, list };
  }),
);

export function fileReducer(state: FileState | undefined, action: Action): FileState {
  return reducer(state, action);
}
