import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getDataSearch } from '@core/store/selectors';

@Component({
  selector: 'ndt-no-item',
  templateUrl: './no-item.component.html',
  styleUrls: ['./no-item.component.scss'],
})
export class NoItemComponent {
  @Input() public emptyType: 'trash' | 'drive';

  public dataSearch$: Observable<string> = this.store.pipe(select(getDataSearch));

  constructor(
    private readonly store: Store,
  ) {}
}
