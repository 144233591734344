import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { BtnActionEnum, Menu } from '@evc/web-components';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { Application } from '@core/models/application.type';
import { FileItem } from '@core/models/file-item.type';
import { FileFormComponent } from '@shared/components';
import { ApplicationsService, ConfigService, DialogService } from '@shared/services';

@Component({
  selector: 'ndt-contextual-menu',
  templateUrl: './contextual-menu.component.html',
  styleUrls: ['./contextual-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextualMenuComponent implements OnChanges, OnInit {
  @Output() public readonly filesUpload: EventEmitter<{ files: File[]; list: FileList }> = new EventEmitter<{ files: File[]; list: FileList }>();
  @Output() public readonly folderCreated: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly itemRenamed: EventEmitter<FileItem> = new EventEmitter<FileItem>();
  @Output() public readonly itemsDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsCopied: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly fileOpened: EventEmitter<{ file: FileItem; app: Application }> = new EventEmitter<{ file: FileItem; app: Application }>();
  @Output() public readonly downloadItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly moveItemsTo: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsPermanentlyDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsRestored: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly lockItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly unlockItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

  @Input() public isGlobal: boolean = false;
  @Input() public applications: Application[] = [];
  @Input() public customClass: string = '';
  @Input() public disableUpload: boolean = false;
  @Input() public hideDelete: boolean = false;
  @Input() public items: FileItem[] = [];
  @Input() public areDeletedItems: boolean = false;
  @Input() public isLockable: boolean = false;
  @Input() public isUnlockable: boolean = false;

  @ViewChild(MatMenuTrigger) public menuSelector: MatMenuTrigger;
  @ViewChild('upload') public uploadInput: { nativeElement: HTMLInputElement };

  public comingSoon: boolean = true;
  public availableApplications: Application[] = [];
  public addMenuConfig: Menu = { items: [] };

  public get isDevMode(): boolean {
    return this.configService.isDevMode;
  }

  constructor(
    private readonly dialogService: DialogService,
    private readonly detectorRef: ChangeDetectorRef,
    private readonly applicationsService: ApplicationsService,
    private readonly configService: ConfigService,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.setAddMenuConfig();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.availableApplications = this.getAppsForFileSelected(changes.items.currentValue);
      this.detectorRef.detectChanges();
    }

    if (changes.disableUpload) {
      this.setAddMenuConfig();
    }
  }

  public areOnlyFiles(items: FileItem[]): boolean {
    return this.applicationsService.areOnlyFiles(items);
  }

  public isOneSelected(items: FileItem[]): boolean {
    return this.applicationsService.isOneSelected(items);
  }

  public isOneFileSelected(items: FileItem[]): boolean {
    return this.applicationsService.isOneFileSelected(items);
  }

  public onCreateFolder(): void {
    this.dialogService.openSmallDialog(
      FileFormComponent,
      result => {
        if (result) {
          this.folderCreated.emit(result);
          this.dialogService.createFolder(result);
        }
      },
    );
  }

  public onUploadFile(): void {
    this.uploadInput.nativeElement.click();
  }

  public onFilesSelected(): void {
    const mappedFileList: { list: FileList; files: File[] } = this.getFileList(this.uploadInput.nativeElement.files);
    const files: any[] = mappedFileList.files.map(file => ({
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: moment(file.lastModified).toISOString(),
    }));

    this.filesUpload.emit({ files, list: mappedFileList.list });
    this.uploadInput.nativeElement.value = '';
  }

  public onRename(item: FileItem): void {
    this.dialogService.openSmallDialog(
      FileFormComponent,
      result => result && this.itemRenamed.emit({ ...item, name: result }),
      { file: item },
    );
  }

  public onMoveTo(items: FileItem[]): void {
    this.moveItemsTo.emit(items);
  }

  public onDownloadItems(items: FileItem[]): void {
    this.downloadItems.emit(items);
  }

  public onDelete(items: FileItem[]): void {
    this.itemsDeleted.emit(items);
  }

  public onCopy(items: FileItem[]): void {
    this.itemsCopied.emit(items);
  }

  public onOpen(items: FileItem[], app: Application): void {
    if (this.isOneSelected(items) && this.areOnlyFiles(items)) {
      this.fileOpened.emit({ file: items[0], app });
    }
  }

  public onRestore(items: FileItem[]): void {
    this.itemsRestored.emit(items);
  }

  public onPermanentlyDelete(items: FileItem[]): void {
    this.itemsPermanentlyDeleted.emit(items);
  }

  public onLock(items: FileItem[]): void {
    this.lockItems.emit(items);
  }

  public onUnlock(items: FileItem[]): void {
    this.unlockItems.emit(items);
  }

  public getAppsForFileSelected(items: FileItem[]): Application[] {
    return this.applicationsService.getAppsForFileSelected(items, this.applications);
  }

  public trackById(idx: number, app: Application): string {
    return `${app.code}-${idx}`;
  }

  public openLink(link: string): void {
    return this.applicationsService.openLink(link);
  }

  // Reconstruct FileList to avoid freeze fails
  private getFileList(fileList: FileList): { list: FileList; files: File[] } {
    const files: File[] = [...Array.from(fileList)];
    const similiFileList: any = Object.assign({}, fileList);

    similiFileList.length = files.length;
    similiFileList.files = files;
    similiFileList.item = (index: number): File => similiFileList.files[index];

    return {
      list: similiFileList as FileList,
      files,
    };
  }

  private setAddMenuConfig(): void {
    this.addMenuConfig = {
      items: [
        {
          key: 'list.menu.newFolder',
          text: this.translateService.stream('list.menu.newFolder'),
          icon: 'circle-info',
          action: { type: BtnActionEnum.CALLBACK, callbackOrUrl: (): void => this.onCreateFolder() },
        },
        {
          key: 'list.menu.uploadFile',
          text: this.translateService.stream('list.menu.uploadFile'),
          icon: 'arrow-right-from-bracket',
          isDisabled: this.disableUpload,
          action: { type: BtnActionEnum.CALLBACK, callbackOrUrl: (): void => this.onUploadFile() },
        },
      ],
    };
  }
}
