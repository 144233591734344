import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'dateFormat',
  pure: false,
})
export class DateFormatPipe implements PipeTransform, OnDestroy {
  public value: string = '';
  public lang: string = null;
  public onLangChange: Subscription | undefined;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly translateService: TranslateService,
    private readonly ref: ChangeDetectorRef,
  ) { }

  public updateValue(date: Moment, format: string): void {
    this.value = date.locale(this.lang).format(format || 'lll');
    this.ref.markForCheck();
  }

  public transform(date: Moment | string, format?: string): string {
    if (!date) {
      return '';
    }

    if (!this.lang) {
      this.lang = this.translateService.currentLang;
    }

    const momentDate: Moment = moment(date);
    this.updateValue(momentDate, format);

    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(({ lang }) => {
        this.lang = lang;
        this.updateValue(momentDate, format);
      });
    }

    return this.value;
  }

  public ngOnDestroy(): void {
    this.dispose();
    this.subscription.unsubscribe();
  }

  private dispose(): void {
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }
}
