import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatestWith, Subscription } from 'rxjs';

type unit = 's' | 'm' | 'h';

@Pipe({
  name: 'remaining',
  pure: false,
})
export class RemainingTimePipe implements PipeTransform, OnDestroy {
  private readonly units: unit[] = ['s', 'm', 'h'];

  public value: string = '';
  public onLangChange: Subscription | undefined;

  private subscription: Subscription = new Subscription();

  constructor(
    private readonly translateService: TranslateService,
    private readonly ref: ChangeDetectorRef,
  ) {}

  public updateValue({ unit, value, hasRest, rest, restUnit }: { unit: string, value: string, hasRest: boolean, rest: string, restUnit: string }): void {
    const onTranslation: any = ([resValue, resRest, remaining]: [string, string, string]): void => {
      const translatedValue: string = resValue !== undefined ? resValue : unit;
      const translatedRest: string = resRest !== undefined ? resRest : restUnit;
      const translatedRemaining: string = remaining !== undefined ? remaining : 'remaining';

      this.value = hasRest
        ? `${translatedValue} ${translatedRest} ${translatedRemaining}`
        : `${translatedValue} ${translatedRemaining}`;
      this.ref.markForCheck();
    };

    this.subscription.add(
      this.translateService.get(unit, { value }).pipe(
        combineLatestWith(
          this.translateService.get(hasRest ? restUnit : 'empty', { value: rest }),
          this.translateService.get('unit.remaining'),
        ),
      ).subscribe(onTranslation),
    );
  }

  public transform(seconds: number = 0, translate: boolean = true): string {
    if (isNaN(parseFloat(String(seconds))) || !isFinite(seconds)) {
      return '?';
    }

    let unitIndex: number = 0;

    while (seconds >= 60 && this.units[unitIndex]) {
      seconds /= 60;
      unitIndex++;
    }

    const index: number = Math.min(unitIndex, this.units.length - 1);

    const unit: unit = this.units[index];
    const value: string = seconds.toFixed(0);

    const rest: string = ((seconds % 1) * 60).toFixed(0);
    const hasRest: boolean = rest && rest !== '0';
    const restUnit: unit = this.units[index - 1];

    if (translate) {
      // trigger the first load
      this.updateValue({
        unit: `unit.${unit}`,
        value,
        hasRest,
        rest,
        restUnit: hasRest ? `unit.${restUnit}`: '',
      });

      // subscribe to onLangChange event, in case the language changes
      if (!this.onLangChange) {
        this.onLangChange = this.translateService.onLangChange.subscribe(() => {
          this.updateValue({
            unit: `unit.${unit}`,
            value,
            hasRest,
            rest,
            restUnit: `unit.${restUnit}`,
          });
        });
      }

      return this.value;
    }

    return hasRest ? `${value}${unit} ${rest}${restUnit}` : `${value}${unit}`;
  }

  public ngOnDestroy(): void {
    this.dispose();
    this.subscription.unsubscribe();
  }

  private dispose(): void {
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }
}
