<ng-container *ngIf="stagingDisclaimer$ | async"></ng-container>

<div class="app">
  <evc-platform
    [inputAppIdentification]="appIdentification"
    [inputSearch]="appSearch"
    [inputLeftBar]="leftBarConfig"
    [inputSettingsMenu]="appSettingMenu"
    inputForcedTheme="light"
    [inputUserProfile]="profile$ | async"
    [inputUserOrganizations]="[tenant$ | async]"
    [inputUserApplications]="applications$ | async"
    (logout)="logout()"
    (isLeftbarOpenChange)="handleLeftBarToggle($event)"
    class="app__header"
  >
    <div leftbar-top-content class="app__leftbar-cta-button">
      <ndt-contextual-menu
        class="app__add-menu"
        *ngIf="isAddMenuOpen"
        evcCloseOnClickOutside
        [isGlobal]="true"
        [disableUpload]="uploading$ | async"
        (filesUpload)="uploadFiles($event)"
        (clickedOutside)="hideAddMenu()"
      ></ndt-contextual-menu>

      <evc-button
        type="cta"
        size="xxl"
        [disabled]="isTrashMenu() && (emptyList$ | async)"
        (click)="ctaButtonClicked()"
      >
        <mat-spinner
          *ngIf="isSpinnerVisible$ | async; else defaultContent"
          [diameter]="24"
          color="white"
        ></mat-spinner>

        <ng-template #defaultContent>
          <ng-template
            *ngIf="
              isTrashMenu();
              then deleteButton;
              else addButton
            "></ng-template>
        </ng-template>

        <ng-template #addButton>
          <div class="app__leftbar-cta-content">
            <evc-svg-icon icon="plus" size="md" />
            <span
              class="app__leftbar-cta-text"
              [ngClass]="{ 'app__leftbar-cta-text--collapsed': !isLeftBarOpen }"
            >
              {{ 'list.menu.addFiles' | translate }}
            </span>
          </div>
        </ng-template>

        <ng-template #deleteButton>
          <div class="app__leftbar-cta-content">
            <evc-svg-icon icon="trash-can" size="md" />
            <span
              class="app__leftbar-cta-text"
              [ngClass]="{ 'app__leftbar-cta-text--collapsed': !isLeftBarOpen }"
            >
              {{ 'list.menu.deleteAll' | translate }}
            </span>
          </div>
        </ng-template>
      </evc-button>
    </div>

    <main class="app__container">
      <ndt-container class="app__content">
        <router-outlet></router-outlet>
      </ndt-container>
    </main>
  </evc-platform>

  <ng-container *ngIf="getShownModal('trash')">
    <ng-template *ngIf="isUserAdmin$ | async; then adminTrashModal; else userTrashModal"></ng-template>
  </ng-container>

  <ng-template #adminTrashModal>
    <evc-modal
      minWidth="400px"
      [showReduceButton]="false"
      (closeModal)="hideModal()"
    >
      <span modal-title class="app__delete-title-admin">
        {{ 'trash.deleteAll.admin.title' | translate }}
      </span>

      <span modal-content>
        {{ 'trash.deleteAll.admin.wantPermanentlyDelete' | translate }} <br />
        {{ 'trash.deleteAll.admin.permanentAction' | translate }}
      </span>

      <div modal-actions class="app__delete-actions">
        <evc-button type="error-secondary" (click)="hideModal()">
          {{ 'trash.deleteAll.admin.cancel' | translate }}
        </evc-button>

        <evc-button type="error-primary" (click)="deleteAllTrash()">
          {{ 'trash.deleteAll.admin.delete' | translate }}
        </evc-button>
      </div>
    </evc-modal>
  </ng-template>

  <ng-template #userTrashModal>
    <evc-modal
      minWidth="400px"
      [showReduceButton]="false"
      (closeModal)="hideModal()"
    >
      <span modal-title class="app__delete-title-user">
        {{ 'trash.deleteAll.user.title' | translate }}
      </span>

      <span modal-content>
        {{ 'trash.deleteAll.user.cannotDeletePermanently' | translate }} <br />
        {{ 'trash.deleteAll.user.contactAdmin' | translate }}
      </span>

      <div modal-actions>
        <evc-button (click)="hideModal()">
          {{ 'trash.deleteAll.user.close' | translate }}
        </evc-button>
      </div>
    </evc-modal>
  </ng-template>
</div>
