import { createAction, props } from '@ngrx/store';

import { FileItem as NdtFile } from '@core/models/file-item.type';

export const UPLOAD_FILES: string = '[Upload] Upload Files';
export const UPLOAD_FILE_STARTED: string = '[Upload] Upload File Started';
export const UPLOAD_FILE_PROGRESS: string = '[Upload] Upload File Progress';
export const UPLOAD_FILE_SUCCESS: string = '[Upload] Upload File Success';
export const UPLOAD_FILE_FAIL: string = '[Upload] Upload File Fail';
export const UPLOAD_FILES_FAIL: string = '[Upload] Upload Files Fail';
export const UPLOAD_FILE_COMPLETE: string = '[Upload] Upload File Complete';
export const UPLOAD_FILES_ALL_COMPLETE: string = '[Upload] Upload Files All Complete';
export const CLEAR_FILE: string = '[Upload] Clear File';
export const CLEAR_FILE_SUCCESS: string = '[Upload] Clear File Success';
export const CLEAR_FILE_FAIL: string = '[Upload] Clear File Fail';

export const CLEAR_ALERT: string = '[Upload] Clear Alert';

export const RESET_UPLOAD: string = '[Upload] Reset Upload';

export const uploadFiles: any = createAction(
  UPLOAD_FILES,
  props<{ files: File[], list: FileList }>(),
);

export const uploadFileStarted: any = createAction(
  UPLOAD_FILE_STARTED,
  props<{ name: string, uploadId: string }>(),
);

export const uploadFileProgress: any = createAction(
  UPLOAD_FILE_PROGRESS,
  props<{ name: string, progress: number, uploadId: string, uploaded: number, size: number, speed: number, remaining: number }>(),
);

export const uploadFileSuccess: any = createAction(
  UPLOAD_FILE_SUCCESS,
  props<{ file: NdtFile }>(),
);

export const uploadFileComplete: any = createAction(
  UPLOAD_FILE_COMPLETE,
  props<{ name: string, fileId: string, chunkCount: number }>(),
);

export const uploadFilesAllComplete: any = createAction(
  UPLOAD_FILES_ALL_COMPLETE,
);

export const uploadFileFail: any = createAction(
  UPLOAD_FILE_FAIL,
  props<{ name: string, error: string, params: { [key: string]: string } }>(),
);

export const uploadFilesFail: any = createAction(
  UPLOAD_FILES_FAIL,
  props<{ error: string }>(),
);

export const clearFile: any = createAction(
  CLEAR_FILE,
  props<{ fileId: string, error?: string, params?: { [key: string]: string } }>(),
);

export const clearFileSuccess: any = createAction(
  CLEAR_FILE_SUCCESS,
  props<{ name: string }>(),
);

export const clearFileFail: any = createAction(
  CLEAR_FILE_FAIL,
  props<{ error: string, name: string }>(),
);

export const clearAlert: any = createAction(
  CLEAR_ALERT,
);

export const resetUpload: any = createAction(
  RESET_UPLOAD,
);
