import { createAction, props } from '@ngrx/store';

import { CellarData } from '@core/models/cellar-data.type';

export const LOAD_PERSISTANT: string = '[Cellar] Load persistant';
export const SET_DATA: string = '[Cellar] Set data';
export const REMOVE_DATA: string = '[Cellar] Remove data';
export const RESET: string = '[Cellar] Reset state';

export const loadPersistantCellar: any = createAction(
  LOAD_PERSISTANT,
);

export const setData: any = createAction(
  SET_DATA,
  props<{ data: { [key: string]: CellarData }, persistant?: boolean }>(),
);

export const removeData: any = createAction(
  REMOVE_DATA,
  props<{ key: string }>(),
);

export const resetCellar: any = createAction(
  RESET,
);
