<div class="search">
  <mat-form-field
    appearance="fill"
    class="search__field"
  >
    <mat-icon matPrefix>search</mat-icon>

    <span matSuffix>
      <button *ngIf="currentSearch" mat-icon-button (click)="onClearSearch()"><mat-icon>clear</mat-icon></button>

      <!-- TODO settings: uncomment when ready-->
      <!-- <button mat-icon-button><mat-icon>tune</mat-icon></button>-->
    </span>

    <input
      matInput
      [(ngModel)]="currentSearch"
      [disabled]="loading"
      [placeholder]="'list.search.placeholder' | translate"
      (keyup)="onSearchChanged($event)"
    >
  </mat-form-field>
</div>
