import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Application } from '@core/models/application.type';
import { DataSort } from '@core/models/data-sort.type';
import { FileItem } from '@core/models/file-item.type';
import { Metadata } from '@core/models/nde-item-metadata.type';
import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method.enum';
import { ConfigService } from '@shared/services';

const sortMapping: { [colName: string]: string } = {
  name: 'sortByName',
  updatedAt: 'sortByUpdatedDate',
  size: 'sortBySize',
};

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly filePrefix: string = `${this.configService.apiUrl}/file`;
  private readonly browsePrefix: string = `${this.configService.apiUrl}/browse`;
  private readonly directoryPrefix: string = `${this.configService.apiUrl}/directory`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public load(id: string, parentId: string = '', page: number = 0, sort?: DataSort): Observable<{ page: number, count: number, items: FileItem[] }> {
    let route: string = this.browsePrefix;
    const sortParams: string = this.getSorting(sort);

    if (id !== 'drive') {
      route += `/${id}`;
    }

    return this.httpService.perform(HttpMethod.get, `${route}?directoryId=${parentId}&page=${page}${sortParams}`);
  }

  public loadWithSearch(id: string, search: string, page: number = 0, sort?: DataSort): Observable<{ page: number, count: number, items: FileItem[] }> {
    const sortParams: string = this.getSorting(sort);
    const request: string = `${this.browsePrefix}/search?query=${search}&page=${page}${sortParams}`;

    if (id === 'drive') {
      return this.httpService.perform(HttpMethod.get, request);
    } else if (id === 'trash') {
      return this.httpService.perform(HttpMethod.get, `${request}&searchOnlyTrash=true`);
    }
  }

  public loadFolder(id: string): Observable<FileItem> {
    return this.httpService.perform(HttpMethod.get, `${this.browsePrefix}/item/${id}`);
  }

  public createFolder(folder: Partial<FileItem>): Observable<{ file: FileItem }> {
    return this.httpService.perform(HttpMethod.post, `${this.directoryPrefix}`, { ...folder });
  }

  public renameItem({ id, name }: FileItem): Observable<{ file: FileItem }> {
    return this.httpService.perform(HttpMethod.put, `${this.filePrefix}/rename`, { id, name });
  }

  public deleteItems(items: FileItem[]): Observable<FileItem[]> {
    return forkJoin<FileItem[]>(items.map((item) =>
      this.httpService.perform(HttpMethod.delete, `${this.filePrefix}/${item.id}`)));
  }

  public deleteAllTrash(): Observable<{ totalDeletedFiles: number, totalDeletedDirectories: number }> {
    return this.httpService.perform(HttpMethod.delete, `${this.filePrefix}/trash/empty`);
  }

  public restoreItems(items: FileItem[]): Observable<{ file: FileItem, recoveryDirectoryChangedToRoot: boolean }[]> {
    return forkJoin<{ file: FileItem, recoveryDirectoryChangedToRoot: boolean }[]>(items.map((item) =>
      this.httpService.perform(HttpMethod.put, `${this.filePrefix}/trash/${item.id}`, {})));
  }

  public copyItems(items: FileItem[], parentId: string): Observable<FileItem[]> {
    const itemsCleaned: { sourceFileId: string, destinationParentId: string }[] = items.map((item) => ({ sourceFileId: item.id, destinationParentId: parentId || null }));

    return forkJoin<FileItem[]>(itemsCleaned.map((item) =>
      this.httpService.perform<{ sourceFileId: string, destinationParentId: string }, { file: FileItem }>(HttpMethod.post, `${this.filePrefix}/copy`, { ...item }).pipe(
        map((response: { file: FileItem }) => response.file),
      )));
  }

  public moveItems(items: FileItem[], parentId: string): Observable<FileItem[]> {
    const itemsCleaned: { id: string, destinationParentId: string }[] = items.map((item) => ({ id: item.id, destinationParentId: parentId || null }));

    return forkJoin<FileItem[]>(itemsCleaned.map((item) =>
      this.httpService.perform<{ id: string, destinationParentId: string }, { file: FileItem }>(HttpMethod.put, `${this.filePrefix}/move`, { ...item }).pipe(
        map((response: { file: FileItem }) => response.file),
      )));
  }

  public lockItems(items: FileItem[]): Observable<FileItem[]> {
    const itemsCleaned: { id: string }[] = items.map((item) => ({ id: item.id }));

    return forkJoin<FileItem[]>(itemsCleaned.map((item) =>
      this.httpService.perform<{ id: string }, { file: FileItem }>(HttpMethod.post, `${this.filePrefix}/lock/${item.id}`, { id: item.id }).pipe(
        map((response: { file: FileItem }) => response.file),
      )));
  }

  public unlockItems(items: FileItem[]): Observable<FileItem[]> {
    const itemsCleaned: { id: string }[] = items.map((item) => ({ id: item.id }));

    return forkJoin<FileItem[]>(itemsCleaned.map((item) =>
      this.httpService.perform<{ id: string }, { file: FileItem }>(HttpMethod.post, `${this.filePrefix}/unlock/${item.id}`, { id: item.id }).pipe(
        map((response: { file: FileItem }) => response.file),
      )));
  }

  public loadItemMetadata(id: string): Observable<Metadata> {
    return this.httpService.perform<void, Metadata>(HttpMethod.get, `${this.filePrefix}/ndeMetadata/${id}`);
  }

  public openFile(downloadUrl: string, app: Application, file: FileItem): void {
    const B64filename: string = this.encodeStringInBase64(file.name);

    const link: string = app.openWithUrl
      ? this.replaceInUrl(app.openWithUrl, { downloadUrl, fileid: file.id, B64filename })
      : app.launchUrl;

    window.open(link, '_blank');
  }

  public loadOnlyDirectory(parentId: string = ''): Observable<{ items: FileItem[] }> {
    return this.httpService.perform(HttpMethod.get, `${this.browsePrefix}?directoryId=${parentId}&onlyDirectories=true&count=300`);
  }

  private replaceInUrl(url: string, parameters: { [key: string]: string }): string {
    let replacedUrl: string = url;

    for (const key in parameters) {
      replacedUrl = replacedUrl.replace(`[${key}]`, encodeURIComponent(parameters[key]));
    }

    return replacedUrl;
  }

  private getSorting(sort: DataSort): string {
    if (!sort || !sort?.direction) {
      return '';
    }

    const propertyName: string = sortMapping[sort.active] || sort.active;
    const value: boolean = sort.direction === 'asc'; // true for 'asc', false for 'desc'

    return `&${propertyName}=${value}`;
  }

  private encodeStringInBase64(str: string): string {
    try {
      return Buffer.from(str, 'binary').toString('base64');
    } catch (e) {
      console.error(`Buffer crash: ${e}`);
    }
  }
}
