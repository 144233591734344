import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subscription } from 'rxjs';

import { Profile } from '@core/models/profile.type';
import { getProfile } from '@core/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements OnDestroy {
  private _profile$: Observable<Profile> = this.store.pipe(select(getProfile));
  private _profile: Profile;
  private _subscription: Subscription;

  constructor(
    private readonly store: Store,
  ) {
    this._subscription = this._profile$.subscribe(
      (profile: Profile) => this._profile = profile,
    );
  }

  public isUserAdmin$(): Observable<boolean> {
    return this._profile$.pipe(
      map((profile: Profile) => profile?.role.toLowerCase() === 'admin'),
    );
  }

  public isUserAdmin(): boolean {
    return this._profile?.role.toLowerCase() === 'admin';
  }

  public isCurrentUser(givenId: string): boolean {
    return givenId === this._profile?.id;
  }

  public actionAllowed(givenId: string): boolean {
    return this.isCurrentUser(givenId) || this.isUserAdmin();
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
