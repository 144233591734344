<ng-container *ngIf="baseTitle$ | async"></ng-container>

<div class="menu">
  <div class="menu__info">
    <evc-breadcrumb
      class="menu__breadcrumb"
      [items]="folders$ | async"
      [base]="base?.name | translate"
      [loading]="loading$ | async"
      (baseSelected)="onBaseSelected()"
      (itemSelected)="onFolderSelected($event)"
    ></evc-breadcrumb>

    <evc-button
      type="tertiary"
      size="sm"
      leftIcon="circle-info"
      (click)="onInfoClicked()"
    >
      <span>{{ "list.menu.info" | translate}}</span>
    </evc-button>
  </div>

  <div class="menu__actions">
    <ndt-actions-bar
      [items]="selectedItems$ | async"
      [isAdmin]="isUserAdmin$ | async"
      [isTrash]="isTrash"
      (itemsCopied)="onItemsCopied($event)"
      (itemsMovedTo)="onItemsMovedTo($event)"
      (itemsDownloaded)="onItemsDownloaded($event)"
      (itemsShared)="onItemsShared($event)"
      (itemsDeleted)="onItemsDeleted($event)"
      (itemsRestored)="onItemsRestored($event)"
      (itemsPermanentlyDeleted)="onItemsPermanentlyDeleted($event)"
    ></ndt-actions-bar>
  </div>
</div>
