import { createAction, props } from '@ngrx/store';

export const ADD_ITEMS: string = '[Clipboard] Add items';

export const RESET_CLIPBOARD_STATE: string = '[Clipboard] Reset state';

export const addItems: any = createAction(
  ADD_ITEMS,
  props<{ value: any }>(),
);

export const resetClipboardState: any = createAction(
  RESET_CLIPBOARD_STATE,
  props<{ toDelete?: string[] }>(),
);
