import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { ExtraParams, Toast } from '@core/models/toast/toast.model';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) { }

  public show(toast: Toast): void {
    const extra: ExtraParams = toast.data.extra || {};

    const ref: MatSnackBarRef<TextOnlySnackBar> = this.snackBar.open(
      this.translateService.instant(toast.message || 'errors.general', toast.data.params),
      this.translateService.instant(extra.action || 'toast.close'),
      {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: extra.duration ?? 5000,
        panelClass: toast.type,
        data: toast.data,
      },
    );

    if (extra.actionCallback) {
      ref.onAction().subscribe(() => extra.actionCallback());
    }
  }
}
