import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Storage } from '@core/models/storage.type';
import { StorageService } from '@core/services/storage/storage.service';
import { StorageActions, ToastActions } from '@core/store/actions';

@Injectable()
export class StorageEffects {
  public loadStorage$: any = createEffect((): any => this.actions$.pipe(
    ofType(StorageActions.loadStorage),
    switchMap(() =>
      this.storageService.loadStorage().pipe(
        map((storage: Storage) => StorageActions.loadStorageSuccess({ ...storage })),
        catchError((error: string) => of(StorageActions.loadStorageFail({ error }))),
      ),
    ),
  ));

  public loadStorageFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(StorageActions.loadStorageFail),
    map((action: { error: string }) => action.error),
    switchMap((error: string) => [
      ToastActions.addToastError({ message: error }),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly storageService: StorageService,
  ) { }
}
