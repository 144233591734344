import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';

import { Application } from '@core/models/application.type';
import { FileItem } from '@core/models/file-item.type';
import { ApplicationsService } from '@shared/services';

@Component({
  selector: 'ndt-available-apps',
  templateUrl: './available-apps.component.html',
  styleUrls: ['./available-apps.component.scss'],
})
export class AvailableAppsComponent {
  public availableApplications: Application[] = [];
  public selected: FileItem;

  public get applications(): Application[] {
    return this.data.apps;
  }

  constructor(
    private readonly applicationsService: ApplicationsService,
    private readonly dialogRef: MatDialogRef<AvailableAppsComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { file: FileItem, apps: Application[] },
  ) {
    this.selected = data.file;
    this.availableApplications = this.getAppsForFileSelected(this.selected);
  }

  public areOnlyFiles(item: FileItem): boolean {
    return this.applicationsService.areOnlyFiles([item]);
  }

  public isLicenseExpired(app: Application): boolean {
    return this.applicationsService.isLicenseExpired(app);
  }

  public onOpen(item: FileItem, selected: MatSelectionListChange): void {
    const app: Application = selected.options[0].value;

    if (!this.isLicenseExpired(app) && this.areOnlyFiles(item)) {
      this.dialogRef.close({ file: item, app });
    }
  }

  public getAppsForFileSelected(item: FileItem): Application[] {
    return this.applicationsService.getAppsForFileSelected([item], this.applications);
  }

  public openLink(link: string): void {
    this.applicationsService.openLink(link);
  }

  public trackById(idx: number, app: Application): string {
    return `${app.code}-${idx}`;
  }
}
