import { createAction, props } from '@ngrx/store';

import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { Tenant } from '@core/models/tenant.type';

export const LOAD_PROFILE: string = '[User] Load profile';
export const LOAD_PROFILE_SUCCESS: string = '[User] Load profile success';
export const LOAD_PROFILE_FAIL: string = '[User] Load profile fail';

export const LOAD_APPLICATIONS: string = '[User] Load applications';
export const LOAD_APPLICATIONS_SUCCESS: string = '[User] Load applications success';
export const LOAD_APPLICATIONS_FAIL: string = '[User] Load applications fail';

export const LOAD_TENANT: string = '[User] Load tenant';
export const LOAD_TENANT_SUCCESS: string = '[User] Load tenant success';
export const LOAD_TENANT_FAIL: string = '[User] Load tenant fail';

export const SET_ROLE: string = '[User] Set role';

export const RESET_USER_STATE: string = '[User] Reset state';

export const loadProfile: any = createAction(
  LOAD_PROFILE,
);

export const loadProfileSuccess: any = createAction(
  LOAD_PROFILE_SUCCESS,
  props<{ profile: Profile }>(),
);

export const loadProfileFail: any = createAction(
  LOAD_PROFILE_FAIL,
  props<{ error: string }>(),
);

export const loadApplications: any = createAction(
  LOAD_APPLICATIONS,
);

export const loadApplicationsSuccess: any = createAction(
  LOAD_APPLICATIONS_SUCCESS,
  props<{ applications: Application[] }>(),
);

export const loadApplicationsFail: any = createAction(
  LOAD_APPLICATIONS_FAIL,
  props<{ error: string }>(),
);

export const loadTenant: any = createAction(
  LOAD_TENANT,
);

export const loadTenantSuccess: any = createAction(
  LOAD_TENANT_SUCCESS,
  props<{ tenant: Tenant }>(),
);

export const loadTenantFail: any = createAction(
  LOAD_TENANT_FAIL,
  props<{ error: string }>(),
);

export const setRole: any = createAction(
  SET_ROLE,
  props<{ isAdmin: boolean }>(),
);

export const resetUserState: any = createAction(
  RESET_USER_STATE,
);
