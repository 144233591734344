import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Application } from '@core/models/application.type';
import { DataEnum } from '@core/models/data.enum';
import { FileItem } from '@core/models/file-item.type';
import { ToastType } from '@core/models/toast/toast.enum';
import { Toast } from '@core/models/toast/toast.model';
import { AvailableAppsComponent, ContextualMenuComponent } from '@shared/components';
import { DialogService, FileTypeService, ProfileService, ToastService } from '@shared/services';

@Component({
  selector: 'ndt-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  @Output() public readonly sortChanged: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() public readonly folderNavigate: EventEmitter<FileItem> = new EventEmitter<FileItem>();
  @Output() public readonly itemsSelected: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemRenamed: EventEmitter<FileItem> = new EventEmitter<FileItem>();
  @Output() public readonly itemsDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsCopied: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly fileOpened: EventEmitter<{ file: FileItem; app: Application }> = new EventEmitter<{ file: FileItem; app: Application }>();
  @Output() public readonly itemsToClipboard: EventEmitter<{ items: FileItem[]; action: string }> = new EventEmitter<{ items: FileItem[]; action: string }>();
  @Output() public readonly itemsPasted: EventEmitter<{ items: FileItem[]; parentId: string }> = new EventEmitter<{ items: FileItem[]; parentId: string }>();
  @Output() public readonly navigateToDirectory: EventEmitter<FileItem> = new EventEmitter<FileItem>();
  @Output() public readonly scrolledToBottom: EventEmitter<number> = new EventEmitter<number>();
  @Output() public readonly downloadItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly moveItemsTo: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsRestored: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly itemsPermanentlyDeleted: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly lockItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
  @Output() public readonly unlockItems: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();

  @Input() public loading: boolean;
  @Input() public currentPage: number;
  @Input() public applications: Application[] = [];
  @Input() public itemsClipboard: FileItem[] = [];
  @Input() public hasSearch: boolean = false;
  @Input() public isListEmpty: boolean = true;
  @Input() public areDeletedItems: boolean = false;
  @Input() public defaultSelected: FileItem[] = [];
  @Input() public currentFolder: FileItem;
  @Input() public lastAction: string;

  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(MatMenuTrigger) public menuSelector: MatMenuTrigger;
  @ViewChild('contextualMenuInstance') public contextualMenu: ContextualMenuComponent;

  public displayedColumns: string[] = ['select', 'name', 'size', 'updatedAt'];
  public dataSource: MatTableDataSource<FileItem>;

  private selectedSubject: BehaviorSubject<FileItem[]> = new BehaviorSubject<FileItem[]>([]);
  private timer: any;
  private unlisten: any;
  private previousScroll: number = 0;
  private scrollDebounceTime: number = 0;
  private subscription: Subscription = new Subscription();

  public get selected(): FileItem[] {
    return this.selectedSubject.value;
  }

  @Input() public set items(items: FileItem[]) {
    this.dataSource = new MatTableDataSource(items);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly toastService: ToastService,
    private readonly dialogService: DialogService,
    private readonly profileService: ProfileService,
    private readonly fileTypeService: FileTypeService,
  ) {
    this.clickOutsideListener();
  }

  @HostListener('document:keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent): void {
    if (this.selected.length && (event.ctrlKey || event.metaKey) && event.key === 'c') {
      this.itemsToClipboard.emit({ items: this.selected, action: 'copy' });
    }

    if (this.selected.length && (event.ctrlKey || event.metaKey) && event.key === 'x') {
      this.itemsToClipboard.emit({ items: this.selected, action: 'cut' });
    }

    if (this.itemsClipboard?.length && (event.ctrlKey || event.metaKey) && event.key === 'v') {
      let parentFolderId: string;

      if (this.selected.length === 1 && this.selected[0].type === DataEnum.FOLDER) {
        parentFolderId = this.selected[0].id;

        if (this.itemsClipboard.some(i => i.id === parentFolderId)) {
          return this.toastService.show(new Toast('file.folder.pasteSelected', ToastType.warning, {}));
        }
      } else if (this.lastAction === 'cut') {
        const isSameFolderPaste: boolean = this.itemsClipboard.some(i => !i.parentId && !this.currentFolder || i.parentId === this.currentFolder?.id);

        if (isSameFolderPaste) {
          return this.toastService.show(new Toast('file.folder.pasteSame', ToastType.warning, {}));
        }
      }

      this.itemsPasted.emit({ items: this.itemsClipboard, parentId: parentFolderId });
    }
  }

  @HostListener('document:click', ['$event'])
  public onClickHandler(event: MouseEvent): void {
    if (!this.selected.length) {
      return;
    }

    const firstClass: string = event
      .composedPath()
      .map((x: HTMLElement) => x.className)
      .filter(c => !!c)[0];

    const allowedClasses: string[] = ['file-list', 'left-panel', 'mat-drawer-content mat-sidenav-content container__wrapper'];

    if (!allowedClasses.includes(firstClass)) {
      return;
    }

    this.selectedSubject.next([]);
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasSearch || changes.areDeletedItems) {
      this.displayedColumns = ['select', 'name', 'size', 'updatedAt'];

      if (changes.hasSearch?.currentValue) {
        this.displayedColumns = [...this.displayedColumns, 'parent'];
      }

      if (changes.areDeletedItems?.currentValue) {
        this.displayedColumns = [...this.displayedColumns, 'parentName'];
      }
    }

    if (changes.items) {
      const defaultSelected: FileItem[] = changes.defaultSelected?.currentValue || this.defaultSelected || [];

      const allItems: { [id: string]: FileItem } = changes.items.currentValue.reduce((all, currentValue) => ({ ...all, [currentValue.id]: currentValue }), {});

      const allSelected: { [id: string]: FileItem } = [...this.selected, ...defaultSelected].reduce((all, currentValue) => ({ ...all, [currentValue.id]: currentValue }), {});

      this.selectedSubject.next(Object.values(allSelected).map(i => allItems[i.id] ?? i));
    }

    if (changes.defaultSelected) {
      if (JSON.stringify(changes.defaultSelected.previousValue) !== JSON.stringify(changes.defaultSelected.currentValue)) {
        this.selectedSubject.next(changes.defaultSelected.currentValue);

        if (this.selected && this.selected[0]) {
          const index: number = this.dataSource.data.findIndex(item => item.id === this.selected[0].id);

          setTimeout(() => {
            this.goToItem(index);
          }, 200);
        }
      }
    }
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.selectedSubject.subscribe((selected: FileItem[]) => {
        this.itemsSelected.emit(selected);
      }),
    );
  }

  public isFolder(item: FileItem): boolean {
    return item.type === DataEnum.FOLDER;
  }

  public isFile(item: FileItem): boolean {
    return item.type === DataEnum.FILE;
  }

  public hasProgress(item: FileItem): boolean {
    return !isNaN(item.progress);
  }

  public onRowClick(item: FileItem, event: MouseEvent): void {
    this.menuSelector.closeMenu();

    if (event.ctrlKey || event.metaKey) {
      return;
    }

    this.selectedSubject.next([item]);
  }

  public onRowCtrlClick(item: FileItem): void {
    if (this.isItemSelected(item)) {
      this.selectedSubject.next(this.selected.filter(i => i.id !== item.id));
    } else {
      this.selectedSubject.next([...this.selected, item]);
    }
  }

  public onRowRightClick(item: FileItem, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const { pageX, pageY } = event;
    const menuButton: HTMLButtonElement = (this.menuSelector as any)._element.nativeElement;

    menuButton.style.top = `${pageY}px`;
    menuButton.style.left = `${pageX}px`;

    if (!this.isItemSelected(item)) {
      this.selectedSubject.next([item]);
    }

    this.menuSelector.updatePosition();
    this.menuSelector.openMenu();
    this.contextualMenu.menuSelector.closeMenu();
  }

  public onRowDoubleClick(item: FileItem): void {
    if (item.type === DataEnum.FOLDER) {
      this.folderNavigate.emit(item);

      return;
    }

    this.dialogService.openMediumDialog(AvailableAppsComponent, result => result && this.fileOpened.emit(result), {
      file: item,
      apps: this.applications,
    });
  }

  public onSelectAll(): void {
    this.selectedSubject.next(this.selected.length === this.dataSource.data.length
      ? []
      : [...this.dataSource.data]);
  }

  public onRowSelect(item: FileItem, event: MatCheckboxChange): void {
    this.menuSelector.closeMenu();

    this.selectedSubject.next(event.checked
      ? [...this.selected, item]
      : this.selected.filter(i => i.id !== item.id));
  }

  public onSortChanged(sortState: Sort): void {
    this.selectedSubject.next([]);
    this.sortChanged.emit(sortState);
  }

  public isItemSelected(item: FileItem): boolean {
    return !!this.selected.find(i => i.id === item.id);
  }

  public isAllItemsSelected(): boolean {
    return this.dataSource.data.length > 0 && this.selected.length === this.dataSource.data.length;
  }

  public isSomeItemsSelected(): boolean {
    return this.selected.length > 0 && !this.isAllItemsSelected();
  }

  public areLockable(items: FileItem[]): boolean {
    return items.every(item => !item.isLocked);
  }

  public areUnlockable(items: FileItem[]): boolean {
    return items.every(item => item.isLocked && this.profileService.actionAllowed(item.lockedBy));
  }

  public onItemRenamed(item: FileItem): void {
    this.itemRenamed.emit(item);
  }

  public onItemsDeleted(items: FileItem[]): void {
    this.itemsDeleted.emit(items);
  }

  public onItemsCopied(items: FileItem[]): void {
    this.itemsCopied.emit(items);
  }

  public onFileOpened(event: { file: FileItem; app: Application }): void {
    this.fileOpened.emit(event);
  }

  public onDownloadItems(items: FileItem[]): void {
    this.downloadItems.emit(items);
  }

  public onMoveItems(items: FileItem[]): void {
    this.moveItemsTo.emit(items);
  }

  public onItemsRestored(items: FileItem[]): void {
    this.itemsRestored.emit(items);
  }

  public onItemsPermanentlyDeleted(items: FileItem[]): void {
    this.itemsPermanentlyDeleted.emit(items);
  }

  public onLockItems(items: FileItem[]): void {
    this.lockItems.emit(items);
  }

  public onUnlockItems(items: FileItem[]): void {
    this.unlockItems.emit(items);
  }

  public goToParent(item: FileItem): void {
    this.navigateToDirectory.emit({ id: item.parentId } as FileItem);
  }

  public onScroll(e: Event | any): void {
    const tableViewHeight: number = e.target.offsetHeight; // viewport
    const tableScrollHeight: number = e.target.scrollHeight; // length of all table
    const scrollLocation: number = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 100px of the bottom, add more data
    const buffer: number = 100;
    const limit: number = tableScrollHeight - tableViewHeight - buffer;

    if (this.previousScroll < scrollLocation && scrollLocation > limit && !this.loading) {
      this.debounce(() => {
        this.scrolledToBottom.emit(this.currentPage + 1);
        this.scrollDebounceTime = 400;
      }, this.scrollDebounceTime);
    }

    this.previousScroll = scrollLocation;
  }

  public getFileIcon(element: FileItem): string {
    const type: string = this.fileTypeService.getFileType(element);

    if (!type) {
      return 'file';
    }

    return `file-${type}`;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    if (this.unlisten) {
      this.unlisten();
    }
  }

  private clickOutsideListener(): void {
    this.unlisten = this.renderer.listen('window', 'click', (e: PointerEvent) => {
      const classList: string[] = e
        .composedPath()
        .map((x: HTMLElement) => x.className)
        .filter(c => !!c);

      if (!classList.includes('contextual-menu-file-list')) {
        this.menuSelector.closeMenu();
      }
    });
  }

  private debounce(func: (...args) => void, timeout: number = 0): void {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      func.apply(this, ...arguments);
    }, timeout);
  }

  /* istanbul ignore next */
  private goToItem(index: number): void {
    const wrapper: HTMLElement = document.body.querySelector('.file-list');
    const mainTable: HTMLElement = wrapper.querySelector('mat-table');

    const elements: NodeListOf<HTMLElement> = mainTable.querySelectorAll('mat-row');
    const element: HTMLElement = elements.item(index);

    const wrapperHeight: number = wrapper.clientHeight;
    const wrapperTop: number = wrapper.getBoundingClientRect().top;
    const elementTop: number = element.getBoundingClientRect().top;

    // element is in the visible part
    if (elementTop < wrapperTop + wrapperHeight) {
      return;
    }

    mainTable.scroll({
      top: elementTop + wrapperTop - wrapperHeight / 2,
      behavior: 'smooth',
    });
  }
}
