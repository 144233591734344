import { BrowseTargetComponent } from './browse-target/browse-target.component';
import { ContainerComponent } from './container/container.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { MainPanelComponent } from './main-panel/main-panel.component';
import { MenuComponent } from './menu/menu.component';
import { RightPanelComponent } from './right-panel/right-panel.component';

export const layoutComponents: any[] = [
  MenuComponent,
  ContainerComponent,
  RightPanelComponent,
  BrowseTargetComponent,
  DisclaimerComponent,
  MainPanelComponent,
];

export * from './menu/menu.component';
export * from './container/container.component';
export * from './right-panel/right-panel.component';
export * from './browse-target/browse-target.component';
export * from './disclaimer/disclaimer.component';
export * from './main-panel/main-panel.component';
