import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, iif, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@env';
import { ApplicationEnum } from '@core/models/application.enum';
import { Application } from '@core/models/application.type';
import { DataEnum } from '@core/models/data.enum';
import { DataSort } from '@core/models/data-sort.type';
import { Datatype } from '@core/models/datatype.enum';
import { BackendError } from '@core/models/error/backend-error.type';
import { FileErrorEnum } from '@core/models/error/file-error.enum';
import { FileItem } from '@core/models/file-item.type';
import { Metadata } from '@core/models/nde-item-metadata.type';
import { FileService } from '@core/services/file/file.service';
import { TransferService } from '@core/services/transfer/transfer.service';
import { BreadcrumbActions, DefaultActions, FileActions, ToastActions } from '@core/store/actions';
import {
  getBreadcrumb,
  getCurrentSort,
  getDataSearch,
  getIdToFind,
  getRouterData,
  getRouterParams,
  getRouterUrlArray,
} from '@core/store/selectors';

const {
  file: { ndeType },
} = environment;

@Injectable()
export class FileEffects {
  public loadData$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadData),
    withLatestFrom(
      this.store.pipe(select(getDataSearch)),
      this.store.pipe(select(getCurrentSort)),
    ),
    map(([{ page, sort, search }, currentSearch, currentSort]: [{ page: number, sort: DataSort, search: string }, string, DataSort]) => ({
      search: search ?? currentSearch,
      page,
      sort: sort ?? currentSort,
    })),
    switchMap(({ search, page, sort }) => iif(
      () => !!search,
      [FileActions.loadFromSearch({ page, search, sort })],
      [FileActions.loadList({ page, sort })],
    )),
  ));

  public loadList$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadList),
    withLatestFrom(
      this.store.pipe(select(getRouterData)),
      this.store.pipe(select(getRouterParams)),
      this.store.pipe(select(getIdToFind)),
    ),
    map(([{ page, sort }, data, params, idToFind]: [{ page: number, sort: DataSort }, { [key: string]: any }, Params, string]) => ({
      id: data.id,
      parentId: params.id,
      page,
      sort,
      idToFind,
    })),
    switchMap(({ id, parentId, page, sort, idToFind }: { id: Datatype, parentId: string, search: string, page: number, sort: DataSort, idToFind: string }) =>
      this.fileService.load(id, parentId, page, sort).pipe(
        switchMap(({ page: p, count, items }: { page: number, count: 0, items: FileItem[] }) => iif(
          () => !!idToFind && !items.find(item => item.id === idToFind),
          [
            FileActions.loadDataSuccess({ data: items, page: p, count, sort, idToFind }),
            FileActions.loadData({ page: p + 1, idToFind, search: '' }),
          ],
          [FileActions.loadDataSuccess({ data: items, page: p, count, sort, idToFind })],
        )),
        catchError((error: BackendError) => of(FileActions.loadDataFail({ error }))),
      ),
    ),
  ));

  public loadDataFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadDataFail),
    withLatestFrom(
      this.store.pipe(select(getRouterUrlArray)),
    ),
    map(([action, state]: [{ error: BackendError }, string[]]) => ({ error: action.error, state })),
    tap(({ error: { errorType }, state }: { error: BackendError, state: string[] }) => {
      if (errorType === FileErrorEnum.FileHasBeenRemoved) {
        this.router.navigate([`/${state[0]}`]);
      }
    }),
    switchMap(({ error: { errorType } }: { error: BackendError, state: string[] }) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'toast.error',
      }),
    ]),
  ));

  public loadFromSearch$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadFromSearch),
    withLatestFrom(
      this.store.pipe(select(getRouterData)),
      this.store.pipe(select(getRouterUrlArray)),
    ),
    map(([{ search, page, sort }, data, state]: [{ search: string, page: number, sort: DataSort }, { [key: string]: any }, string[]]) => ({ id: data.id, search, state, page, sort })),
    tap(async ({ state, search }: { search: string, id: string, state: string[] }) => await this.router.navigate([`/${state[0]}`], {
      queryParams: { search },
    })),
    switchMap(({ search, id, page, sort }: { search: string, id: string, state: string[], page: number, sort: DataSort }) =>
      this.fileService.loadWithSearch(id, search, page, sort).pipe(
        map(({ page: p, items, count }: { page: number, count: 0, items: FileItem[] }) => FileActions.loadDataSuccess({ data: items, page: p, count, sort })),
        tap(() => this.store.dispatch(BreadcrumbActions.resetBreadcrumbState())),
        catchError((error: string) => of(FileActions.loadDataFail({ error }))),
      ),
    ),
  ));

  public loadFolder$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadFolder),
    switchMap(({ id }: { id: string }) =>
      this.fileService.loadFolder(id).pipe(
        map((folder: FileItem) => {
          this.store.dispatch(FileActions.setSelectedItems({ items: [folder] }));
          this.store.dispatch(BreadcrumbActions.addItem({ folder }));

          return FileActions.loadFolderSuccess({ folder });
        }),
        catchError((error: BackendError) => of(FileActions.loadFolderFail({ error }))),
      ),
    ),
  ));

  public loadFolderSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadFolderSuccess),
    withLatestFrom(this.store.pipe(select(getBreadcrumb))),
    filter(([, breadcrumb]: [{ folder: FileItem }, FileItem[]]) => !breadcrumb || breadcrumb.length < 2),
    switchMap(([{ folder }]: [{ folder: FileItem }, FileItem[]]) => [BreadcrumbActions.loadBreadcrumb({ id: folder.id })]),
  ));

  public loadFolderFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadFolderFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'toast.error',
      }),
    ]),
  ));

  public createFolder$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.createFolder),
    map((payload: { name: string }) => payload.name),
    withLatestFrom(
      this.store.pipe(select(getRouterParams)),
    ),
    map(([name, params]: [name: string, parans: Params]) => ({
      name,
      parentId: params.id,
    })),
    switchMap((partialFolder: Partial<FileItem>) =>
      this.fileService.createFolder(partialFolder).pipe(
        map(({ file: folder }: { file: FileItem }) => FileActions.createFolderSuccess({ folder })),
        catchError((error: BackendError) => of(FileActions.createFolderFail({ error }))),
      ),
    ),
  ));

  public createFolderSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.createFolderSuccess),
    withLatestFrom(
      this.store.pipe(select(getDataSearch)),
      this.store.pipe(select(getRouterUrlArray)),
    ),
    tap(([, , state]: [unknown, string, string[]]) => {
      // TODO: only drive for now... Think about it when supporting "add folder" in many lists
      if (state[0] !== 'drive') {
        this.router.navigate(['/']);
      }
    }),
    switchMap(([{ folder }, search]: [{ folder: FileItem }, string, string[]]) => iif(
      () => !!search,
      [
        FileActions.setNewIdToFind({ idToFind: folder.id }),
        FileActions.clearSearch(),
      ],
      [
        FileActions.setNewIdToFind({ idToFind: folder.id }),
        FileActions.loadData({ page: 0, search: '' }),
      ])),
  ));

  public createFolderFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.createFolderFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public renameItem$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.renameItem),
    switchMap(({ item }: { item: FileItem }) =>
      this.fileService.renameItem(item).pipe(
        map(({ file: savedItem }: { file: FileItem }) => FileActions.renameItemSuccess({ item: savedItem })),
        catchError((error: BackendError) => of(FileActions.renameItemFail({ error }))),
      ),
    ),
  ));

  public renameItemFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.renameItemFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public deleteItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteItems),
    switchMap(({ items }: { items: FileItem[] }) =>
      this.fileService.deleteItems(items).pipe(
        map(() => FileActions.deleteItemsSuccess({ items })),
        catchError((error: BackendError) => of(FileActions.deleteItemsFail({ error }))),
      ),
    ),
  ));

  public deleteItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteItemsSuccess),
    switchMap(({ items }: { items: FileItem[] }) => [
      ToastActions.addToastInfo({
        message: items.length > 1 ? 'file.delete.items' : 'file.delete.item',
        params: { name: items.map(i => i.name).join(', ') },
        extra: {
          duration: 10000,
          action: 'file.delete.undo',
          actionCallback: () => this.store.dispatch(FileActions.restoreItems({ items, undo: true })),
        },
      }),
    ]),
  ));

  public deleteItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public deleteAllTrash$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteAllTrash),
    switchMap(() =>
      this.fileService.deleteAllTrash().pipe(
        map(({ totalDeletedFiles, totalDeletedDirectories }) => FileActions.deleteAllTrashSuccess({ totalDeletedFiles, totalDeletedDirectories })),
        catchError((error: BackendError) => of(FileActions.deleteAllTrashFail({ error }))),
      ),
    ),
  ));

  public deleteAllTrashSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteAllTrashSuccess),
    switchMap(({ totalDeletedFiles, totalDeletedDirectories }) => {
      const totalDeleted: number = totalDeletedFiles + totalDeletedDirectories;

      return [
        ToastActions.addToastInfo({
          message: totalDeleted === 1 ? 'trash.deleteAll.item' : 'trash.deleteAll.items',
          params: { count: totalDeleted },
        }),
      ];
    }),
  ));

  public deleteAllTrashFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.deleteAllTrashFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(() => [
      ToastActions.addToastError({
        message: 'trash.error.global',
      }),
    ]),
  ));

  public restoreItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.restoreItems),
    withLatestFrom(this.store.pipe(select(getRouterData))),
    map(([{ items, undo }, data]: [{ items: FileItem[], undo?: boolean }, { id: string }]) => ({
      items,
      undo,
      location: data.id,
    })),
    switchMap(({ items, undo, location }: { items: FileItem[], undo?: boolean, location?: string }) =>
      this.fileService.restoreItems(items).pipe(
        map((response: { file: FileItem, recoveryDirectoryChangedToRoot: boolean }[]) =>
          response.filter(i => i.recoveryDirectoryChangedToRoot).map(i => i.file),
        ),
        map((toNotify: FileItem[]) => FileActions.restoreItemsSuccess({ items, undo, toNotify, location })),
        catchError((error: BackendError) => of(FileActions.restoreItemsFail({ error }))),
      ),
    ),
  ));

  public restoreItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.restoreItemsSuccess),
    switchMap(({ items, undo, toNotify }: { items: FileItem[], undo?: boolean, toNotify?: FileItem[] }) => {
      const successMessage: string = this.translator.instant(undo
        ? 'file.delete.cancelled'
        : (items.length > 1 ? 'file.delete.restoreMany' : 'file.delete.restore'),
      { name: items.map(i => i.name).join(', ') },
      );

      const notifyMessage: string = toNotify?.length
        ? this.translator.instant(
          toNotify.length > 1 ? 'file.delete.restoreManyRoot' : 'file.delete.restoreRoot',
          { name: toNotify.map(i => i.name).join(', ') },
        )
        : '';

      return [
        ToastActions.addToastInfo({
          message: `${successMessage} ${notifyMessage}`,
          extra: {
            duration: 5000 + (toNotify?.length || 0) * 500,
          },
        }),
      ];
    }),
  ));

  public restoreItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.restoreItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public copyItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.copyItems),
    tap(({ items }: { items: FileItem[], parentId?: string }) => {
      const folders: FileItem[] = items.filter(i => i.type === DataEnum.FOLDER);

      if (folders.length) {
        this.store.dispatch(ToastActions.addToastWarning({ message: 'Folder copy not supported yet' }));
      }
    }),
    map(({ items, parentId }: { items: FileItem[], parentId?: string }) => ({ items: items.filter(i => i.type !== DataEnum.FOLDER), parentId })),
    filter(({ items }: { items: FileItem[] }) => !!items.length),
    withLatestFrom(this.store.pipe(select(getRouterParams))),
    map(([payload, params]: [{ items: FileItem[], parentId?: string }, Params]) => ({ items: payload.items, parentId: payload.parentId ?? params.id })),
    switchMap(({ items, parentId }: { items: FileItem[], parentId: string }) =>
      this.fileService.copyItems(items, parentId).pipe(
        map((returnedItems) => FileActions.copyItemsSuccess({ items: returnedItems, parentId })),
        catchError((error: BackendError) => of(FileActions.copyItemsFail({ error }))),
      ),
    ),
  ));

  public copyItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.copyItemsSuccess),
    switchMap(({ items }: { items: FileItem[] }) => [
      ToastActions.addToastInfo({
        message: items.length > 1 ? 'file.copy.items' : 'file.copy.item',
      }),
    ]),
  ));

  public copyItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.copyItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public moveItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.moveItems),
    withLatestFrom(this.store.pipe(select(getRouterParams))),
    map(([payload, params]: [{ items: FileItem[], parentId?: string }, Params]) => ({ items: payload.items, parentId: payload.parentId ?? params.id })),
    switchMap(({ items, parentId }: { items: FileItem[], parentId: string }) =>
      this.fileService.moveItems(items, parentId).pipe(
        map((returnedItems) => FileActions.moveItemsSuccess({ items: returnedItems })),
        catchError((error: BackendError) => of(FileActions.moveItemsFail({ error }))),
      ),
    ),
  ));

  public moveItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.moveItemsSuccess),
    switchMap(({ items }: { items: FileItem[] }) => [
      ToastActions.addToastInfo({
        message: items.length > 1 ? 'file.move.items' : 'file.move.item',
      }),
    ]),
  ));

  public moveItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.moveItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public lockItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.lockItems),
    switchMap(({ items }: { items: FileItem[] }) =>
      this.fileService.lockItems(items).pipe(
        map((returnedItems) => FileActions.lockItemsSuccess({ items: returnedItems })),
        catchError((error: BackendError) => of(FileActions.lockItemsFail({ error }))),
      ),
    ),
  ));

  public lockItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.lockItemsSuccess),
    switchMap(({ items }: { items: FileItem[] }) => [
      ToastActions.addToastInfo({
        message: items.length > 1 ? 'file.lock.items' : 'file.lock.item',
      }),
    ]),
  ));

  public lockItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.lockItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public unlockItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.unlockItems),
    switchMap(({ items }: { items: FileItem[] }) =>
      this.fileService.unlockItems(items).pipe(
        map(() => FileActions.unlockItemsSuccess({ items })),
        catchError((error: BackendError) => of(FileActions.unlockItemsFail({ error }))),
      ),
    ),
  ));

  public unlockItemsSuccess$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.unlockItemsSuccess),
    switchMap(({ items }: { items: FileItem[] }) => [
      ToastActions.addToastInfo({
        message: items.length > 1 ? 'file.unlock.items' : 'file.unlock.item',
      }),
    ]),
  ));

  public unlockItemsFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.unlockItemsFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public loadItemMetadata$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadItemMetadata),
    switchMap(({ item }: { item: FileItem }) => {
      const fileContentType: string = item.latestVersion?.contentType;
      const canItemHaveMetadata: boolean = item.type.toLowerCase() === 'file' && fileContentType === ndeType;
      if (canItemHaveMetadata) {
        return this.fileService.loadItemMetadata(item.id).pipe(
          map((metadata: any) => FileActions.loadItemMetadataSuccess({ metadata })),
          catchError((error: BackendError) => of(FileActions.loadItemMetadataFail({ error }))),
        );
      }

      return [FileActions.clearMetadata()];
    }),
  ));

  public loadItemMetadataFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.loadItemMetadataFail),
    map((action: { error: BackendError, metadata: Metadata }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
      FileActions.clearMetadata(),
    ]),
  ));

  public openFile$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.openFile),
    switchMap(({ file, app }: { file: FileItem, app: Application }) => app.code.toLowerCase() === ApplicationEnum.ZIP
      ? [FileActions.downloadItems({ items: [file] })]
      : [FileActions.openInApp({ file, app })],
    ),
  ));

  public openInApp$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.openInApp),
    switchMap(({ file, app }: { file: FileItem, app: Application }) => this.transferService.downloadUrl(file.id).pipe(
      tap(({ downloadUrl }: { downloadUrl: string }) => this.fileService.openFile(downloadUrl, app, file)),
      map(() => DefaultActions.noAction()),
      catchError((error: BackendError) => of(FileActions.openInAppFail({ error }))),
    )),
  ));

  public openInAppFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.openInAppFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'file.error.global',
      }),
    ]),
  ));

  public downloadItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.downloadItems),
    switchMap(({ items }: { items: FileItem[] }) =>
      this.transferService.downloadItems(items).pipe(
        map((response: { item: FileItem, percentage: number, done: boolean }) => {
          if (!response) {
            return DefaultActions.noAction();
          }

          const { done, item, percentage } = response;

          return done
            ? FileActions.downloadItemSuccess({ item })
            : percentage
              ? FileActions.downloadItemProgress({ item, percentage })
              : DefaultActions.noAction();
        }),
        // TODO: BackendError in the middle of error management
        catchError(error => of(FileActions.downloadItemFail({ error: 'download.failed.file', item: error.item, params: { name: error.item?.name } }))),
      ),
    ),
  ));

  public downloadItemFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.downloadItemFail),
    switchMap(({ error, params }: { error: string, params: { [key: string]: string } }) => [
      ToastActions.addToastError({ message: error, extra: { duration: 0 }, params }),
    ]),
  ));

  public downloadItemsByUrl$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.downloadItemsByUrl),
    switchMap(({ items }: { items: FileItem[] }) =>
      this.transferService.downloadItemsByUrl(items).pipe(
        map(() => FileActions.downloadItemsByUrlSuccess({ items })),
        catchError((error: BackendError) => of(FileActions.downloadItemsByUrlFail({ error }))),
      ),
    ),
  ));

  public downloadItemsByUrlFail$: any = createEffect((): any => this.actions$.pipe(
    ofType(FileActions.downloadItemsByUrlFail),
    map((action: { error: BackendError }) => action.error),
    switchMap(({ errorType }: BackendError) => [
      ToastActions.addToastError({
        message: errorType ? `file.error.${errorType}` : 'download.failed.global',
      }),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly fileService: FileService,
    private readonly transferService: TransferService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly translator: TranslateService,
  ) { }
}
