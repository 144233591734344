<span
  evcTooltipVisible="visible"
  evcTooltipPlacement="top"
  [evcTooltipHover]="name"
>
  <evc-button
    type="icon"
    size="sm"
    variant="semi"
  >
    <evc-svg-icon [icon]="icon" size="md"></evc-svg-icon>
  </evc-button>
</span>
