<div
  class="banner"
  [ngClass]="classes"
>
  <div class="banner__content">
    <ng-content></ng-content>
  </div>

  <button
    *ngIf="clearable"
    mat-icon-button
    class="banner__clear"
    (click)="onClear()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>
