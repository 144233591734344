import { Params } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

import * as AuthReducer from './auth/auth.reducer';
import * as BreadcrumbReducer from './breadcrumb/breadcrumb.reducer';
import * as BrowseTargetReducer from './browse-target/browse-target.reducer';
import * as CellarReducer from './cellar/cellar.reducer';
import * as ClipboardReducer from './clipboard/clipboard.reducer';
import * as FileReducer from './file/file.reducer';
import * as StorageReducer from './storage/storage.reducer';
import * as UploadReducer from './upload/upload.reducer';
import * as UserReducer from './user/user.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: { [key: string]: any };
}

export interface NdtFileManagerState {
  auth: AuthReducer.AuthState;
  router: RouterReducerState<RouterStateUrl>;
  file: FileReducer.FileState;
  user: UserReducer.UserState;
  clipboard: ClipboardReducer.ClipboardState;
  breadcrumb: BreadcrumbReducer.BreadcrumbState;
  upload: UploadReducer.UploadState;
  browseTarget: BrowseTargetReducer.BrowseTargetState;
  cellar: CellarReducer.CellarState;
  storage: StorageReducer.StorageState;
}

export const ndtFileManagerStoreReducers: any = {
  auth: AuthReducer.authReducer,
  router: routerReducer,
  file: FileReducer.fileReducer,
  user: UserReducer.userReducer,
  clipboard: ClipboardReducer.clipboardReducer,
  breadcrumb: BreadcrumbReducer.breadcrumbReducer,
  upload: UploadReducer.uploadReducer,
  browseTarget: BrowseTargetReducer.browseTargetReducer,
  cellar: CellarReducer.cellarReducer,
  storage: StorageReducer.storageReducer,
};

export const getFileManagerState: any = createFeatureSelector<NdtFileManagerState>('ndtFileManager');

export * from './auth/auth.reducer';
export * from './file/file.reducer';
export * from './user/user.reducer';
export * from './clipboard/clipboard.reducer';
export * from './breadcrumb/breadcrumb.reducer';
export * from './upload/upload.reducer';
export * from './browse-target/browse-target.reducer';
export * from './cellar/cellar.reducer';
export * from './storage/storage.reducer';
