<h1
  mat-dialog-title
  class="file-form__title"
>{{ title | translate }}</h1>

<div
  mat-dialog-content
  class="file-form__content"
>
  <mat-form-field appearance="outline">
    <input
      #input
      matInput
      cdkFocusInitial
      [(ngModel)]="name"
      (focus)="!initialFocused && selectText(input)"
      (input)="!touched && onInput()"
    >
  </mat-form-field>
</div>

<div
  mat-dialog-actions
  class="file-form__actions"
>
  <button
    mat-button
    (click)="onCancelClick()"
  >{{ 'file.cancel' | translate }}</button>

  <button
    mat-button
    [mat-dialog-close]="name"
    color="primary"
  >{{ action | translate }}</button>
</div>
