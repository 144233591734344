import { createSelector } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';
import { getFileManagerState, NdtFileManagerState } from '@core/store/reducers';
import { FileState, SelectedItemMetadataState } from '@core/store/reducers/file/file.reducer';
import { isBreadcrumbLoading } from '@core/store/selectors';

/**
 * General File State selector
 */
export const getFileState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.file,
);

export const areDataLoading: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.loading,
);

export const areDataLoaded: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.loaded,
);

export const areDataSaving: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.saving,
);

export const areDataSaved: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.saved,
);

export const getDataError: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.error,
);

export const getDataSearch: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.search,
);

export const getIdToFind: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.newIdToFind,
);

export const getDataList: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.list,
);

export const getSelectedItems: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.selectedItems,
);

export const getCurrentFolder: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.currentFolder,
);

export const getCurrentPage: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.page,
);

export const getCurrentSort: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.sort,
);

export const getSelectedItemMetadataState: any = createSelector(
  getFileState,
  (state: FileState) => !!state && state.selectedItemMetadata,
);

export const getSelectedItemMetadata: any = createSelector(
  getSelectedItemMetadataState,
  (state: SelectedItemMetadataState) => !!state && state.metadata,
);

export const getSelectedItemMetadataLoading: any = createSelector(
  getSelectedItemMetadataState,
  (state: SelectedItemMetadataState) => !!state && state.loading,
);

export const getSelectedItemMetadataError: any = createSelector(
  getSelectedItemMetadataState,
  (state: SelectedItemMetadataState) => !!state && state.error,
);

export const isPageLoading: any = createSelector(
  areDataLoading,
  areDataLoaded,
  isBreadcrumbLoading,
  (dataLoading: boolean, dataLoaded: boolean, breadcrumbLoading: boolean) => (!!dataLoading && !dataLoaded) || !!breadcrumbLoading,
);

export const isPageChanging: any = createSelector(
  areDataLoading,
  areDataSaving,
  isBreadcrumbLoading,
  (dataLoading: boolean, dataSaving: boolean, breadcrumbLoading: boolean) => !!dataLoading || !!dataSaving || !!breadcrumbLoading,
);

export const isListEmpty: any = createSelector(
  areDataLoading,
  areDataLoaded,
  getDataList,
  (dataLoading: boolean, dataLoaded: boolean, list: FileItem[]) => !dataLoading && !!dataLoaded && !list.length,
);
