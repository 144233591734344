import { createSelector } from '@ngrx/store';

import { CellarData } from '@core/models/cellar-data.type';
import { CellarState, getFileManagerState, NdtFileManagerState } from '@core/store/reducers';

/**
 * General Cellar State selector
 */
export const getCellarState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.cellar,
);

export const getWineByKey: any = (key: string) => createSelector(
  getCellarState,
  (state: CellarState) => !!state && state[key],
);

export const triggerWhenNotExists: any = (key: string) => createSelector(
  getWineByKey(key),
  (wine: CellarData) => !wine,
);
