import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Application } from '@core/models/application.type';
import { Profile } from '@core/models/profile.type';
import { Tenant } from '@core/models/tenant.type';
import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method.enum';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly appsPrefix: string = `${this.configService.apiUrl}/license`;
  private readonly profilePrefix: string = `${this.configService.oscUrl}/user`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public loadProfile(): Observable<Profile> {
    // TODO: need full profile but blocked by cors
    // return this.httpService.perform(HttpMethod.get, `${this.configService.oscUrl}/myaccount/api/users/currentUserProfile`);
    return this.httpService.perform(HttpMethod.get, `${this.profilePrefix}/profile`);
  }

  public loadTenant(): Observable<Tenant> {
    return this.httpService.perform(HttpMethod.get, `${this.profilePrefix}/tenant`);
  }

  public loadApplications(): Observable<Application[]> {
    return this.httpService.perform(HttpMethod.get, `${this.appsPrefix}`);
  }
}
