import { Injectable } from '@angular/core';

import { FileItem } from '@core/models/file-item.type';

@Injectable({
  providedIn: 'root',
})
export class FileTypeService {
  constructor() { }

  public getFileType(file: FileItem): string {
    const contentTypeMap: { [key: string]: string } = {
      'data-nde': 'nde',
      pdf: 'pdf',
      zip: 'zip',
      csv: 'csv',
      'vnd.ms-excel': 'xls',
      'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
      'vnd.msword': 'doc',
      'vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
      'vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
      'vnd.ms-powerpoint': 'ppt',
      jpeg: 'jpg',
      jpg: 'jpg',
      png: 'png',
      mov: 'mov',
      mp4: 'mp4',
      'svg+xml': 'svg',
      'x-set': 'set',
    };

    const fileContentType: string = file.latestVersion?.contentType?.split('/').pop() ?? '';

    return contentTypeMap[fileContentType] || '';
  }
}
