import { createAction, props } from '@ngrx/store';

import { ExtraParams } from '@core/models/toast/toast.model';

export const ADD_TOAST_SUCCESS: string = '[Toast] Add toast success';
export const ADD_TOAST_ERROR: string = '[Toast] Add toast error';
export const ADD_TOAST_WARNING: string = '[Toast] Add toast warning';
export const ADD_TOAST_INFO: string = '[Toast] Add toast info';

export const addToastSuccess: any = createAction(
  ADD_TOAST_SUCCESS,
  props<{ message: string, params?: { [key: string]: string }, extra?: ExtraParams }>(),
);

export const addToastError: any = createAction(
  ADD_TOAST_ERROR,
  props<{ message: string, params?: { [key: string]: string }, extra?: ExtraParams }>(),
);

export const addToastWarning: any = createAction(
  ADD_TOAST_WARNING,
  props<{ message: string, params?: { [key: string]: string }, extra?: ExtraParams }>(),
);

export const addToastInfo: any = createAction(
  ADD_TOAST_INFO,
  props<{ message: string, params?: { [key: string]: string }, extra?: ExtraParams }>(),
);
