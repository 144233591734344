import { Component, Input } from '@angular/core';
import { IconType } from '@evc/web-components';

@Component({
  selector: 'ndt-action-icon',
  templateUrl: './action-icon.component.html',
  styleUrls: ['./action-icon.component.scss'],
})
export class ActionIconComponent {
  @Input() public icon: IconType;
  @Input() public name: string;
}
