import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'ndt-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Output() public readonly clear: EventEmitter<void> = new EventEmitter<void>();

  @Input() public clearable: boolean = true;
  @Input() public color: ThemePalette;

  public get classes(): { [key: string]: boolean } {
    return {
      [`banner--${this.color}`]: !!this.color,
    };
  }

  public onClear(): void {
    this.clear.emit();
  }
}
