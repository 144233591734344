export enum FileErrorEnum {
  MissingId = 'missingId',
  NotFound = 'notFound',
  ParentNotFound = 'parentNotFound',
  ParentMustBeADirectory = 'parentMustBeADirectory',
  DestinationConflict = 'destinationConflict',
  MoveInItselfNotAllowed = 'moveInItselfNotAllowed',
  NameAlreadyExists = 'nameAlreadyExists',
  InvalidName = 'invalidName',
  InvalidDirectoryName = 'invalidDirectoryName',
  FileHasBeenRemoved = 'fileHasBeenRemoved',
  BadParameters = 'badParameters',
  ParentIsNotADirectory = 'parentIsNotADirectory',
  FileExists = 'fileExists',
  NoReturnedValue = 'noReturnedValue',
  NoReturnValue = 'noReturnValue',
}
