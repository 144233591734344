import { createSelector } from '@ngrx/store';

import { ClipboardState, getFileManagerState, NdtFileManagerState } from '@core/store/reducers';

/**
 * General Clipboard State selector
 */
export const getClipboardState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.clipboard,
);

export const getClipboardValue: any = createSelector(
  getClipboardState,
  (state: ClipboardState) => !!state && state.value,
);
