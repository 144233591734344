import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PlatformComponent } from '@evc/platform';
import {
  BreadcrumbComponent,
  CloseOnClickOutsideDirective,
  DropdownMenuComponent,
  LoadingBarComponent,
  ModalComponent,
  ModalService,
  NavButtonComponent,
  StatusIndicatorComponent,
  SvgIconComponent,
  TooltipHoverDirective,
} from '@evc/web-components';

import { sharedComponents } from './components';
import { sharedDirectives } from './directives';
import { sharedGuards } from './guards';
import { sharedHelpers } from './helpers';
import { sharedModals } from './modals';
import { sharedModules } from './modules';
import { sharedPipes } from './pipes';
import { sharedServices } from './services';
import { sharedValidators } from './validators';

export const appModules: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  // Add other modules here
];

export const materialModules: any[] = [
  MatSnackBarModule,
  MatSidenavModule,
  MatButtonModule,
  MatCheckboxModule,
  MatIconModule,
  MatToolbarModule,
  MatMenuModule,
  MatDividerModule,
  MatListModule,
  MatTableModule,
  MatSortModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatBadgeModule,
  // Add material modules here
  ...sharedModules,
];

export const evcPlatformComponents: any[] = [
  BreadcrumbComponent,
  DropdownMenuComponent,
  ModalComponent,
  NavButtonComponent,
  PlatformComponent,
  SvgIconComponent,
  StatusIndicatorComponent,
  LoadingBarComponent,
];

export const evcPlatformDirectives: any[] = [
  CloseOnClickOutsideDirective,
  TooltipHoverDirective,
];

export const evcPlatformServices: any[] = [
  ModalService,
];

export const appComponents: any[] = [
  ...sharedModals,
  ...sharedComponents,
  // Add other components here
];

export const appValidators: any[] = [
  ...sharedValidators,
  // Add other validators here
];

export const appDialogs: any[] = [
  ...sharedModals,
  // Add other dialog here
];

export const appServices: any[] = [
  ...sharedServices,
  // Add other services here
];

export const appGuards: any[] = [
  ...sharedGuards,
  // Add other guards here
];

export const appDirectives: any[] = [
  ...sharedDirectives,
  // Add other directives here
];

export const appPipes: any[] = [
  ...sharedPipes,
  // Add other pipes here
];

export const appHelpers: any[] = [
  ...sharedHelpers,
];
