import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { NavigationConfigService } from '@shared/services/navigation-config/navigation-config.service';

export const navigationResolver: ResolveFn<void | Promise<boolean>> = (
  route: ActivatedRouteSnapshot,
): void | Promise<boolean> => {
  const navigationService: NavigationConfigService = inject(NavigationConfigService);

  return navigationService.updateSelectedTab(route.url[0].path);
};
