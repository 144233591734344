import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';
import { BreadcrumbActions, FileActions } from '@core/store/actions';

export interface BreadcrumbState {
  value: FileItem[];
  loading: boolean;
}

export const initialBreadcrumbState: BreadcrumbState = {
  value: [],
  loading: false,
};

const reducer: ActionReducer<BreadcrumbState> = createReducer(
  initialBreadcrumbState,
  on(BreadcrumbActions.loadBreadcrumb, (state: BreadcrumbState) => ({ ...state, loading: true })),
  on(BreadcrumbActions.addItem, (state: BreadcrumbState, { folder }: { folder: FileItem }) => {
    const folderIndex: number = state.value.findIndex((f) => f.id === folder.id);
    const value: FileItem[] = folderIndex === -1
      ? [...state.value, folder] // S'il n'existe pas dans la liste, on l'ajoute à la fin
      : state.value.slice(0, folderIndex + 1); // Sinon, on cut tout ce qu'il y a apres l'index trouvé (on a navigué par le breadcrumb)

    return { ...state, value };
  }),
  on(BreadcrumbActions.addParent, (state: BreadcrumbState, { folder, last }: { folder: FileItem, last: boolean }) => {
    const value: FileItem[] = state.value.find(i => i.id === folder.id)
      ? state.value
      : [folder, ...state.value];

    return { ...state, value, loading: !last };
  }),

  on(BreadcrumbActions.resetBreadcrumbState, () => ({ ...initialBreadcrumbState })),

  // Other actions
  on(FileActions.renameItemSuccess, (state: BreadcrumbState, { item }: { item: FileItem }) => {
    const list: FileItem[] = state.value.map((f) => f.id === item.id ? item : f);

    return ({ ...state, value: list });
  }),
);

export function breadcrumbReducer(state: BreadcrumbState | undefined, action: Action): BreadcrumbState {
  return reducer(state, action);
}
