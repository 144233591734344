import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';

import { ClipboardActions, ToastActions } from '@core/store/actions';

@Injectable()
export class ClipboardEffects {
  public addItems$: any = createEffect((): any => this.actions$.pipe(
    ofType(ClipboardActions.addItems),
    switchMap(({ value }: { value: { items: any, action: string } }) => [
      ToastActions.addToastInfo({
        message: value.items.length > 1 ? `clipboard.${value.action}.saveAll` : `clipboard.${value.action}.saveOne`,
      }),
    ]),
  ));

  constructor(
    private readonly actions$: Actions,
  ) { }
}
