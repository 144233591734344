import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { Datatype } from '@core/models/datatype.enum';
import { getRouterData } from '@core/store/selectors';

@Component({
  selector: 'ndt-main-panel',
  templateUrl: './main-panel.component.html',
  styleUrl: './main-panel.component.scss',
})
export class MainPanelComponent {
  public isTrash$: Observable<boolean> = this.store.pipe(
    select(getRouterData),
    map((data: { id: string }) =>
      data?.id === Datatype.TRASH,
    ),
  );

  constructor(
    private readonly store: Store,
  ) { }
}
