import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ctrl-click]',
})
export class CtrlClickDirective implements OnInit, OnDestroy {
  @Output('ctrl-click') public readonly ctrlClickEvent: EventEmitter<void> = new EventEmitter<void>();

  private unlisten: any;

  constructor(
    private readonly renderer: Renderer2,
    private readonly element: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.unlisten = this.renderer.listen(this.element.nativeElement, 'click', event => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        event.stopPropagation();

        // unselect accidentally selected text (browser default behaviour)
        document.getSelection().removeAllRanges();

        this.ctrlClickEvent.emit(event);
      }
    });
  }

  public ngOnDestroy(): void {
    this.unlisten();
  }
}
