import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CurrentUploadState } from '@core/store/reducers';

@Component({
  selector: 'ndt-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Output() public readonly cancel: EventEmitter<{ uploadId: string }> = new EventEmitter<{ uploadId: string }>();
  @Input() public current: CurrentUploadState;

  public get pending(): boolean {
    return this.current.started && !this.current.progress;
  }

  public get progress(): boolean {
    return this.current.started && !!this.current.progress;
  }

  public get cancellable(): boolean {
    return !this.current.failed && !this.current.complete && !this.current.cancelled;
  }

  public get cancelled(): boolean {
    return this.current.cancelled && !this.current.failed;
  }

  public cancelFile(uploadId: string): void {
    this.cancel.emit({ uploadId });
  }
}
