import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import {
  appComponents,
  appDirectives,
  appGuards,
  appModules,
  appPipes,
  appServices,
  evcPlatformComponents,
  evcPlatformDirectives,
  evcPlatformServices,
  materialModules,
} from './index';

@NgModule({
  imports: [
    RouterModule,
    TranslateModule.forChild(),
    ...appModules,
    ...materialModules,
    ...evcPlatformComponents,
    ...evcPlatformDirectives,
  ],
  declarations: [
    ...appComponents,
    ...appPipes,
    ...appDirectives,
  ],
  exports: [
    TranslateModule,
    ...materialModules,
    ...evcPlatformComponents,
    ...evcPlatformDirectives,
    ...appComponents,
    ...appPipes,
    ...appDirectives,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...appServices,
        ...appGuards,
        ...evcPlatformServices,
      ],
    };
  }
}
