<div class="right-panel">
  <ng-container *ngIf="(selectedItems$ | async) as items">
    <div class="right-panel__header">
      <ng-container *ngIf="items.length === 1; else multipleOrEmptySelection">
        <div class="right-panel__title-container">
          <mat-icon class=" right-panel__item-icon" [ngClass]="{ 'right-panel--disabled': items[0].deletedAt }">
            {{ getItemIcon(items[0].type) }}
          </mat-icon>

          <span #itemName class="right-panel__title"
            evcTooltipPlacement="bottom"
            [evcTooltipVisible]="isTooltipVisible(itemName) ? 'visible' : 'hidden'"
            [evcTooltipHover]="items[0].name"
            [ngClass]="{ 'right-panel--disabled': items[0].deletedAt }"
          >
            {{ items[0].name }}
          </span>

          <evc-status-indicator
            *ngIf="items[0].isLocked"
            [text]="'list.menu.inUse' | translate"
            status="warn"
          ></evc-status-indicator>

          <evc-status-indicator
            *ngIf="items[0].deletedAt"
            [text]="'list.menu.deleted' | translate"
            status="warn"
          ></evc-status-indicator>
        </div>
      </ng-container>

      <ng-template #multipleOrEmptySelection>
        <div *ngIf="items.length > 1; else emptySelection" class="right-panel__title-container">
          <span class="right-panel__title">{{ items.length + ' ' + getDataName('selectedItems') }}</span>
        </div>

        <ng-template #emptySelection>
          <div class="right-panel__title-container">
            <span class="right-panel__title"> {{ 'list.menu.SelectToSeeDetails' | translate }}</span>
          </div>
        </ng-template>
      </ng-template>

      <evc-button type="icon" size="sm" variant="semi" class="right-panel__close-btn" (click)="handleCloseRightPanel()">
        <evc-svg-icon size="md" icon="xmark"></evc-svg-icon>
      </evc-button>
    </div>

    <ng-container *ngIf="items.length === 1; else multipleOrEmptyContent">
      <div class="right-panel__infos-container">
        <ng-container *ngIf="(selectedItemMetaData$ | async) as itemMetadata">
          <div *ngIf="loading$ | async; else metadataContent">
            <span>loading...</span>
          </div>

          <ng-template #metadataContent>
            <div class="right-panel__item-infos">
              <div *ngFor="let data of itemData; trackBy:trackByName">
                <div *ngIf="data.value" class="right-panel__section" [ngClass]="{ 'right-panel--disabled': items[0].deletedAt }">
                  <span class="right-panel__section-title"> {{ getDataName(data.name) }} </span>
                  <span>{{ data.value }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #multipleOrEmptyContent>
      <ng-container *ngIf="items.length > 1; else emptyContent">
        <div class="right-panel__img-container">
          <img class="right-panel__image" src="assets/images/books.webp" [alt]="'error.noImageAltText.tooManyItemsSelected' | translate" />
        </div>
      </ng-container>

      <ng-template #emptyContent>
        <div class="right-panel__img-container">
          <img class="right-panel__image" src="assets/images/empty-box.webp" [alt]="'error.noImageAltText.noItemSelected' | translate" />
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
</div>
