import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { TOTAL_STORAGE_MOCK, USED_STORAGE_MOCK } from '@test/mocks/storage.mock';
import { Storage } from '@core/models/storage.type';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public loadStorage(): Observable<Storage> {
    return of({ total: TOTAL_STORAGE_MOCK, used: USED_STORAGE_MOCK });
  }
}
