import { createAction, props } from '@ngrx/store';

import { FileItem } from '@core/models/file-item.type';

export const NAVIGATE_TO_ITEM: string = '[Browse Target] Navigate to item';
export const CLEAR_NAVIGATION: string = '[Browse Target] Clear navigation';
export const LOAD_FOLDERS: string = '[Browse Target] Load folders';
export const LOAD_FOLDERS_SUCCESS: string = '[Browse Target] Load folders success';
export const LOAD_FOLDERS_FAIL: string = '[Browse Target] Load folders fail';

export const RESET_STATE: string = '[Browse Target] Reset state';

export const navigateToItem: any = createAction(
  NAVIGATE_TO_ITEM,
  props<{ folder: FileItem }>(),
);

export const clearNavigation: any = createAction(
  CLEAR_NAVIGATION,
);

export const loadFolders: any = createAction(
  LOAD_FOLDERS,
  props<{ parent: FileItem }>(),
);

export const loadFoldersSuccess: any = createAction(
  LOAD_FOLDERS_SUCCESS,
  props<{ list: FileItem[] }>(),
);

export const loadFoldersFail: any = createAction(
  LOAD_FOLDERS_FAIL,
  props<{ error: string }>(),
);

export const resetBrowseTargetState: any = createAction(
  RESET_STATE,
);
