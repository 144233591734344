import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Datatype } from '@core/models/datatype.enum';
import { FileItem } from '@core/models/file-item.type';
import { resetBreadcrumbState } from '@core/store/actions/breadcrumb/breadcrumb.action';
import {
  copyItems,
  deleteItems,
  downloadItemsByUrl,
  moveItems,
  restoreItems,
  setCurrentFolder,
  setSelectedItems,
} from '@core/store/actions/file/file.action';
import {
  getBreadcrumb,
  getIsAdmin,
  getRouterData,
  getSelectedItems,
  isPageLoading,
} from '@core/store/selectors';
import { DialogService, PanelService } from '@shared/services';
import { BrowseTargetComponent } from '../browse-target/browse-target.component';

@Component({
  selector: 'ndt-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public selectedItems$: Observable<FileItem[]> = this.store.pipe(select(getSelectedItems));
  public loading$: Observable<boolean> = this.store.pipe(select(isPageLoading));
  public folders$: Observable<FileItem[]> = this.store.pipe(select(getBreadcrumb));
  public isUserAdmin$: Observable<boolean> = this.store.pipe(select(getIsAdmin));
  public baseTitle$: Observable<{ name: string; locked: boolean }> = this.store.pipe(
    select(getRouterData),
    map((data: { id: string; base: string; locked: boolean }) => ({
      id: data?.id,
      name: data?.base,
      locked: data?.locked,
    })),
    tap(({ id, name, locked }: { id: string; name: string; locked: boolean }) => {
      this.base = name ? { name } : null;
      this.locked = locked;
      this.routeId = id;
    }),
  );

  public base: Partial<FileItem>;
  public locked: boolean = false;
  public routeId: string;

  public get isTrash(): boolean {
    return this.routeId === Datatype.TRASH;
  }

  constructor(
    private readonly store: Store,
    private readonly panelService: PanelService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
  ) {}

  public onInfoClicked(): void {
    this.panelService.toggleRightPanelVisibility();
  }

  public onBaseSelected(): void {
    this.router.navigate([`/${this.getBase(this.router.url)}`]).then(() => {
      this.store.dispatch(setCurrentFolder({ folder: null }));
      this.store.dispatch(setSelectedItems({ items: [] }));
      this.store.dispatch(resetBreadcrumbState());
    });
  }

  public onFolderSelected(folder: Partial<FileItem>): void {
    this.router.navigate([`/${this.getBase(this.router.url)}`, folder.id]).then(() => {
      this.store.dispatch(setCurrentFolder({ folder }));
      this.store.dispatch(setSelectedItems({ items: [] }));
    });
  }

  public onItemsCopied(items: FileItem[]): void {
    this.dialogService.openMediumDialog(
      BrowseTargetComponent,
      result => result && this.store.dispatch(copyItems({ items, parentId: result.selected.id })),
      { items, action: 'browseTarget.actions.copy' },
    );
  }

  public onItemsMovedTo(items: FileItem[]): void {
    this.dialogService.openMediumDialog(
      BrowseTargetComponent,
      result => result && this.store.dispatch(moveItems({ items, parentId: result.selected.id })),
      { items, action: 'browseTarget.actions.move' },
    );
  }

  public onItemsDownloaded(items: FileItem[]): void {
    this.store.dispatch(downloadItemsByUrl({ items }));
  }

  public onItemsShared(items: FileItem[]): void {
    // TODO store
  }

  public onItemsDeleted(items: FileItem[]): void {
    this.store.dispatch(deleteItems({ items }));
  }

  public onItemsRestored(items: FileItem[]): void {
    this.store.dispatch(restoreItems({ items }));
  }

  public onItemsPermanentlyDeleted(items: FileItem[]): void {
    // TODO store
  }

  private getBase(url: string): string[] {
    const split: string[] = url.split('/').filter(p => !!p);
    split.pop();

    return split;
  }
}
