import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Breakpoint } from '@core/models/breakpoint.enum';

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  public rightPanelVisibility$!: Observable<boolean>;

  private _rightPanelVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private mobileQuery: MediaQueryList;

  constructor(private readonly media: MediaMatcher) {
    this.rightPanelVisibility$ = this._rightPanelVisibility.asObservable();

    this.mobileQuery = media.matchMedia(`(max-width: ${Breakpoint.medium})`);
  }

  public toggleRightPanelVisibility(visible?: boolean): void {
    this._rightPanelVisibility.next(visible ?? !this._rightPanelVisibility.value);
  }
}
