import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ndt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
  @Output() public readonly searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public readonly searchCleared: EventEmitter<void> = new EventEmitter<void>();
  @Input() public search: string;
  @Input() public loading: boolean;

  public currentSearch: string = '';

  private debounce: any;
  private submitted: boolean = true;
  private emitted: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.search && !changes.search.firstChange && changes.search.previousValue !== changes.search.currentValue) {
      this.currentSearch = changes.search.currentValue;

      if (this.currentSearch !== this.emitted) {
        this.searchChanged.emit(this.currentSearch);
        this.emitted = this.currentSearch;
      }
    }
  }

  public onClearSearch(): void {
    this.currentSearch = null;
    this.searchChanged.emit(null);
    this.searchCleared.emit();
    this.emitted = null;
    this.submitted = true;
  }

  public onSearchChanged(event: KeyboardEvent): void {
    this.debounce = setTimeout(() => {
      if (event.key !== 'Enter') {
        this.triggerChanges();

        return;
      }

      if (this.submitted) {
        return;
      }

      const cleanedSearch: string = this.currentSearch
        ? this.currentSearch.trim().toLowerCase()
        : null;

      this.searchChanged.emit(cleanedSearch);
      this.emitted = cleanedSearch;
      this.submitted = true;

      this.clearDebounce();
    }, 300);
  }

  private triggerChanges(): void {
    this.submitted = false;

    if (!this.currentSearch) {
      this.onClearSearch();
      this.clearDebounce();
    }
  }

  private clearDebounce(): void {
    clearTimeout(this.debounce);
    this.debounce = null;
  }
}
