import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { ClipboardActions } from '@core/store/actions';

export interface ClipboardState {
  value: any;
}

export const initialClipboardState: ClipboardState = {
  value: null,
};

const reducer: ActionReducer<ClipboardState> = createReducer(
  initialClipboardState,
  on(ClipboardActions.addItems, (state: ClipboardState, { value }: { value: any }) => ({ ...state, value })),
  on(ClipboardActions.resetClipboardState, (state: ClipboardState, { toDelete }: { toDelete: string[] }) => {
    if (!toDelete) {
      return { ...initialClipboardState };
    }

    const value: any = { ...state.value };

    toDelete.forEach(prop => {
      delete value[prop];
    });

    return { ...initialClipboardState, value };
  }),
);

export function clipboardReducer(state: ClipboardState | undefined, action: Action): ClipboardState {
  return reducer(state, action);
}
