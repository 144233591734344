import { createSelector } from '@ngrx/store';

import { AuthState, getFileManagerState, NdtFileManagerState } from '@core/store/reducers';

/**
 * General Auth State selector
 */
export const getAuthState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.auth,
);

export const isAuthLoading: any = createSelector(
  getAuthState,
  (state: AuthState) => !!state && state.loading,
);

export const isAuthenticated: any = createSelector(
  getAuthState,
  (state: AuthState) => !!state && state.loggedIn,
);
