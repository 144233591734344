<mat-drawer-container class="container">
  <mat-drawer-content class="container__main-panel">
    <ndt-main-panel>
      <ng-content></ng-content>
    </ndt-main-panel>
  </mat-drawer-content>

  <mat-drawer
    class="container__right-panel"
    [opened]="rightPanelVisibility$ | async"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    (closed)="closeRightVisibility()"
    position="end"
  >
    <ndt-right-panel></ndt-right-panel>
  </mat-drawer>
</mat-drawer-container>
