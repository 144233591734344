<ng-container *ngIf="dataSearch$ | async; else noSearch">
  <div class="no-item">
    <img class="no-item__icon" src="assets/images/no-item-found.webp" [alt]="'search.empty.main' | translate">
  
    <p class="no-item__text">
      <span class="no-item__text--main">{{ 'search.empty.main' | translate }}</span>
      <span class="no-item__text--secondary">
      {{ 'search.empty.hint1' | translate }}
      <br>
      {{ 'search.empty.hint2' | translate }}
      </span>
    </p>
  </div>
</ng-container>

<ng-template #noSearch>
  <div *ngIf="emptyType === 'trash'" class="no-item">
    <img class="no-item__icon" src="assets/images/empty-folder-trash.svg" [alt]="'trash.empty.main' | translate"/>
  
    <p class="no-item__text">
      <span class="no-item__text--main">{{ 'trash.empty.main' | translate }}</span>
      <span class="no-item__text--secondary">
      {{ 'trash.empty.hint1' | translate }}
      <br>
      {{ 'trash.empty.hint2' | translate }}
      </span>
    </p>
  </div>
  
  <div *ngIf="emptyType === 'drive'" class="no-item">
    <img class="no-item__icon" src="assets/images/empty-folder-drive.svg" [alt]="'drive.empty.main' | translate"/>
  
    <p class="no-item__text">
      <span class="no-item__text--main">{{ 'list.empty.main' | translate }}</span>
      <span class="no-item__text--secondary">
        {{ 'list.empty.hint1' | translate }}
        <br>
        {{ 'list.empty.hint2' | translate }}
      </span>
    </p>
  </div>
</ng-template>