import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NgxFileUploadService } from '@exalif/ngx-file-upload';
import { select, Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { clearAlert, resetUpload } from '@core/store/actions/upload/upload.action';
import { CurrentUploadState } from '@core/store/reducers';
import { getUploadAlerts, getUploadFiles, hasUploadError, isUploadDone } from '@core/store/selectors';

@Component({
  selector: 'ndt-upload-list',
  templateUrl: 'upload-list.component.html',
  styleUrls: ['upload-list.component.scss'],
})
export class UploadListComponent {
  public files$: Observable<CurrentUploadState[]> = this.store.pipe(select(getUploadFiles));
  public isUploadDone$: Observable<boolean> = this.store.pipe(select(isUploadDone));
  public isUploading$: Observable<boolean> = this.isUploadDone$.pipe(map(done => !done));
  public hasError$: Observable<boolean> = this.store.pipe(select(hasUploadError));
  public hasNoAlert$: Observable<boolean> = this.store.pipe(
    select(getUploadAlerts),
    map((alerts: number) => !!alerts),
    tap(hasAlert => {
      if (hasAlert && !this.minified) {
        this.store.dispatch(clearAlert());
      }
    }),
    map((hasAlert: boolean) => !hasAlert),
  );

  public minified: boolean = false;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<UploadListComponent>,
    private readonly store: Store,
    private readonly ngxUploadService: NgxFileUploadService,
  ) {}

  public closePanel(): void {
    this.bottomSheetRef.afterDismissed().subscribe(() => {
      this.store.dispatch(resetUpload());
    });
    this.bottomSheetRef.dismiss();
  }

  public toggleMinified(extra: { clearAlert: boolean } = { clearAlert: false }): void {
    this.minified = !this.minified;

    if (extra.clearAlert) {
      this.store.dispatch(clearAlert());
    }
  }

  public cancelFile({ uploadId }: { uploadId: string }): void {
    this.ngxUploadService.control({ action: 'cancel', uploadId });
  }

  public trackByName(index: number, item: { file: { name: string } }): string {
    return `${item.file.name}-${index}`;
  }
}
