import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileItem } from '@core/models/file-item.type';
import { HttpService } from '@core/services/http/http.service';
import { HttpMethod } from '@core/services/http/http-method.enum';
import { ConfigService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public readonly uploadPrefix: string = `${this.configService.apiUrl}/transfer/upload`;

  constructor(
    private readonly httpService: HttpService,
    private readonly configService: ConfigService,
  ) {}

  public completeUpload(id: string, size: number): Observable<{ fileItem: FileItem }> {
    return this.httpService.perform(HttpMethod.get, `${this.uploadPrefix}/complete/${id}?numberOfChunks=${size}`);
  }

  public deleteUpload(id: string, error?: string): Observable<void> {
    return this.httpService.perform(HttpMethod.delete, `${this.uploadPrefix}/${id}?failed=${!!error}`);
  }
}
