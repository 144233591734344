import { createSelector } from '@ngrx/store';

import { getFileManagerState, NdtFileManagerState } from '@core/store/reducers';
import { StorageState } from '@core/store/reducers/storage/storage.reducer';

/**
 * General Storage State selector
 */
export const getStorageState: any = createSelector(
  getFileManagerState,
  (state: NdtFileManagerState) => !!state && state.storage,
);

export const isStorageLoading: any = createSelector(
  getStorageState,
  (state: StorageState) => !!state && state.loading,
);

export const isStorageLoaded: any = createSelector(
  getStorageState,
  (state: StorageState) => !!state && state.loaded,
);

export const getStorageError: any = createSelector(
  getStorageState,
  (state: StorageState) => !!state && state.error,
);

export const getStorageUsed: any = createSelector(
  getStorageState,
  (state: StorageState) => !!state && state.used,
);

export const getStorageTotal: any = createSelector(
  getStorageState,
  (state: StorageState) => !!state && state.total,
);
